import React, { useState } from 'react';
import { User } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useStorage } from '../../../Utils/Utils';

const SystemUserDetails = ({ editMode, user, editUser }) => {
	const [roleOptions, setRoleOptions] = useState([]);
	const storage = useStorage();

	useState(() => {
		storage.getOrFetch("/roles/all").then((res) => {
			const options = res
				.sort((a, b) => { return a.displayName.localeCompare(b.displayName)} )
				.map((role) => { return { label: role.displayName, value: role.id } });
			setRoleOptions(options)
		})
	}, []);

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="General Details" icon={<User />} />
			</PageGridItem>
			<PageGridItem col="1 / span 6">
				<DetailField bold editMode={false} autoFocus label="Name" value={user && user.name && user.name} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				{/* <DetailField bold editMode={false} autoFocus label="Last Name" value={user && user.lastName && user.lastName} /> */}
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField editMode={false} autoFocus label="Email" value={user && user.email && user.email} />
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField editMode={false} autoFocus label="Phone" value={user && user.phone && user.phone.number && user.phone.number} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<SelectDetailField editMode={false} autoFocus label="Title" value={user && user.title && user.title}/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<SelectDetailField
					editMode={editMode}
					label="Role"
					placeholder="Role"
					value={user && user.roleId && roleOptions ? roleOptions.find((option) => option.value === user.roleId) : null}
					options={roleOptions}
					onChange={(option) => user && editUser({ ...user, roleId: option.value })}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<SelectDetailField editMode={false} autoFocus label="Author" value={user && user.isAuthor ? 'Yes' : 'No'} />
			</PageGridItem>
		</>
	);
}

export default SystemUserDetails;