import React, { useState, useEffect } from "react";
import { Search } from "react-feather";
import _ from 'lodash'
import TextInput from './TextInput'

const SearchTermMuiltiTableFilter = ({ data, path, hasSearchTerm, placeholder, size, width, isClearable }) => {

    const [searchTerm, setSearchTerm] = useState("")

    const data1 = data[0].dataSet
    const data2 = data[1].dataSet
    const data3 = (data[2] && data[2].dataSet) || null
    const data4 = (data[3] && data[3].dataSet) || null
    const data5 = (data[4] && data[4].dataSet) || null

    useEffect(() => {
        handleSearch(searchTerm)
    }, [data1, data2.dataSet, data3 && data3.dataSet, data4 && data4.dataSet, data5 && data5.dataSet])

    useEffect(() => {
        handleSearch(searchTerm)
        hasSearchTerm(searchTerm ? true : false)
    }, [searchTerm])

    const setPath = (datum) => {
        let pathType = typeof path
        switch (pathType) {
            case "string":
                return datum[path]

            case "object":
                let searchableString = ""
                path.forEach((locater) => {
                    let stringToAdd = _.get(datum, locater)
                    searchableString = searchableString + ' ' + stringToAdd
                })
                return searchableString
            default:
                break;
        }
    }

    const handleSearch = (searchTerm) => {
        let searchTermArray = searchTerm.toLowerCase().split(" ")

        if (!data) {
            return
        }

        data.map((d, index) => {
            let newFilteredData = []
            const setFilteredData = d.setFilteredData

            newFilteredData = d.dataSet.filter((datum) => {
                setPath(datum)
                let formattedDatum = setPath(datum).toLowerCase()
                let verify = searchTermArray.map((term) => {
                    if (formattedDatum.includes(term)) {
                        return true
                    } else {
                        return false
                    }
                })
                if (verify.includes(false) === false) {
                    return datum
                }
            })
            setFilteredData(newFilteredData)
        })
    }

    return (
        <TextInput
            type="search"
            placeholder={placeholder}
            onChange={e => setSearchTerm(e.target.value)}
            size={size}
            width={width}
            icon={<Search />}
            clearText={() => setSearchTerm("")}
            isClearable={isClearable}
            value={searchTerm}
        >

        </TextInput>
    );
}

export default SearchTermMuiltiTableFilter;
