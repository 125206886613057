import { format, compareAsc, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { AlertCircle, Mail, Send, CheckCircle, ChevronUp, ChevronDown } from "react-feather";
import { useHistory, useParams } from "react-router"
import SearchTermFilter from "../../components/Input/SearchTermFilter";
import LoadingIcon from "../../components/LoadingIcon";
import Table from "../../components/Table/Table";
import useWindowDimensions, { useRemote } from "../../Utils/Utils";
import FlexWrapper from "../FlexWrapper";
import Page from "../Shared/Page/Page"
import { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridItem from "../Shared/Page/PageGridItem";
import { RawCraigsLibraryTable } from "./CraigsLibraryTable";
import PageContentToggle from '../../components/Menus/PageContentToggle'
import Divider from "../../components/Divider";
import PdfIcon from "../../images/icons/PdfIcon"
import XlsIcon from "../../images/icons/XlsIcon";
import SuggestionMessage from "../../components/EmptyMessage/SuggestionMessage";
import withRouteChange from "@threeskye/route-change";
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import "./DeliveryDetails.scss";
import XMLIcon from "../../images/icons/XMLIcon";


const DeliveryDetails = ({ changeRoute }) => {

	const { deliveryId } = useParams();
	const { width } = useWindowDimensions();
	const remote = useRemote();
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [craigsDocs, setCraigsDocs] = useState([])
	const [delivery, setDelivery] = useState([])
	const [auditLogs, setAuditLogs] = useState([])

	const [currentView, setCurrentView] = useState("audit")
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [currentFilter, setCurrentFilter] = useState("")
	const [filteredDeliveryList, setFilterDeliveryList] = useState([])
	const [filteredDeliveryData, setFilteredDeliveryData] = useState([])
	const [deliveryDataDisplaying, setDeliveryDataDisplaying] = useState([])
	const [filteredAuditData, setFilteredAuditData] = useState([])
	const [auditDataDisplaying, setAuditDataDisplaying] = useState([])
	const [sortingColumn, setSortingColumn] = useState({ column: "Date", ascending: false })
	const [deliveryExpanded, setDeliveryExpanded] = useState({})
	const [auditLogExpanded, setAuditLogExpanded] = useState({})

	const [showDelivered, setShowDelivered] = useState(true);
	const [showErrored, setShowErrored] = useState(true);
	const [showOpened, setShowOpened] = useState(true);

	const xl = width > 2250;
	const l = width > 1000;


	useEffect(() => {
		if (!deliveryId) {
			return;
		}
		remote.get(`/documents/document/${deliveryId}/details`).then(details => {
			setCraigsDocs([details.summary])
			const distributions = details.distribution;
			setLoading(false);
			if (distributions && distributions.campaignId) {
				setDelivery(distributions.distributions);
				return;
			}
		});
		remote.get(`/documents/document/${deliveryId}/audit`).then(setAuditLogs);

	}, [deliveryId])

	// Filters
	const filter = (doc, which) => {
		if (which === 'sent') {
			setShowDelivered(!showDelivered)
		};
		if (which === 'failed') {
			setShowErrored(!showErrored)
		};
		if (which === 'opened') {
			setShowOpened(!showOpened)
		};
	}

	function filterOnclick(value) {
		return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
	}

	useEffect(() => {
		if (currentView === "delivery") {
			let newList = delivery

			if (currentFilter === "delivered") newList = delivery.filter(d => {
				const foundEvent = d.events.find(e => e.event === 'delivered')
				if (foundEvent) return d
				else return null
			})
			if (currentFilter === "failed") newList = delivery.filter(d => {
				const foundEvent = d.events.find(e => e.event === 'dropped' || e.event === 'bounce')
				if (foundEvent) return d
				else return null
			})
			if (currentFilter === "read") newList = delivery.filter(d => {
				const foundEvent = d.events.find(e => e.event === 'open')
				if (foundEvent) return d
				else return null
			})

			setFilterDeliveryList(newList)
		}
	}, [currentView, currentFilter, delivery])

	// Sorting
	useEffect(() => {
		sortTable(null)
	}, [currentView, currentFilter, filteredDeliveryList, filteredDeliveryData, filteredAuditData, hasSearchTerm])

	const sortTable = (column) => {
		if (column === null) {
			if (currentView === "audit") setAuditDataDisplaying(hasSearchTerm ? filteredAuditData : auditLogs)
			if (currentView === "delivery") setDeliveryDataDisplaying(hasSearchTerm ? filteredDeliveryData : filteredDeliveryList)
			return
		}

		let tableData = currentView === "delivery" && hasSearchTerm ?
			filteredDeliveryData : currentView === "delivery" && !hasSearchTerm ?
				filteredDeliveryList : currentView === "audit" && hasSearchTerm ?
					filteredAuditData : auditLogs

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Date":
				tableData.sort((a, b) => ascending ? a.actionTime.localeCompare(b.actionTime) : b.actionTime.localeCompare(a.actionTime))
				break;
			case "User/System":
				tableData.sort((a, b) => ascending ? (a.who == null ? -1 : a.who.localeCompare(b.who)) : (b.who == null ? -1 : b.who.localeCompare(a.who)))
				break;
			case "Action":
				tableData.sort((a, b) => ascending ? a.action.localeCompare(b.action) : b.action.localeCompare(a.action))
				break;
			case "Name":
				tableData.sort((a, b) => ascending ? a.contact.firstName.localeCompare(b.contact.firstName) : b.contact.firstName.localeCompare(a.contact.firstName))
				break;
			case "Delivered":
				tableData.sort((a, b) => {
					const user1Events = a.events.filter(e => e.event === 'delivered')
					const user2Events = b.events.filter(e => e.event === 'delivered')
					const user1 = getEventDate(user1Events) || "-"
					const user2 = getEventDate(user2Events) || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Failed":
				tableData.sort((a, b) => {
					const user1Events = a.events.filter(e => e.event === 'dropped' || e.event === 'bounce')
					const user2Events = b.events.filter(e => e.event === 'dropped' || e.event === 'bounce')
					const user1 = getEventDate(user1Events) || "-"
					const user2 = getEventDate(user2Events) || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			case "Read":
				tableData.sort((a, b) => {
					const user1Events = a.events.filter(e => e.event === 'open')
					const user2Events = b.events.filter(e => e.event === 'open')
					const user1 = getEventDate(user1Events) || "-"
					const user2 = getEventDate(user2Events) || "-"
					return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		currentView === "audit" ? setAuditDataDisplaying(tableData) : setDeliveryDataDisplaying(tableData)
	}

	// Formats
	const getEventDate = (event) => {
		event.forEach(e => {
			e.parsedDate = parseISO(e.eventTime);
		});
		const sorted = event.map(e => e.parsedDate).sort(compareAsc);
		return sorted && sorted.length > 0 ? format(sorted[0], 'HH:mmaaa dd/MM/yyyy') : "-";
	}

	const formatDate = (date) => {
		return format(parseISO(date), 'HH:mm dd/MM/yyyy');
	}

	// expanded mode
	const showHide = (id) => {
		const newExpanded = { ...deliveryExpanded };
		newExpanded[id] = !deliveryExpanded[id];
		setDeliveryExpanded(newExpanded);
	}

	const showHideAudit = (id) => {
		const newExpanded = { ...auditLogExpanded };
		newExpanded[id] = !auditLogExpanded[id];
		setAuditLogExpanded(newExpanded);
	}

	// tabs
	function changeTab(string) {
		if (string === "audit") {
			setCurrentView("audit")
			setSortingColumn({ column: "Date", ascending: false })
		}
		if (string === "delivery") {
			setCurrentView("delivery")
			setSortingColumn({ column: "Name", ascending: true })
		}
	}

	// Delivery table data
	const colDeliveryWidths = [40 + "%", 20 + "%", 20 + "%", 20 + "%"];
	const deliveryHeaders = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => sortTable("Delivered")}>
					<Send className="colour-text-positive" size={16} />
					<div className={sortingColumn.column === "Delivered" ? "font-weight-semi-bold" : ""}>Delivered</div>
					{sortingColumn && sortingColumn.column === "Delivered" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Delivered" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => sortTable("Read")}>
					<Mail className="colour-text-purple" size={16} />
					<div className={sortingColumn.column === "Read" ? "font-weight-semi-bold" : ""}>Read</div>
					{sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header extra-space' onClick={() => sortTable("Failed")}>
					<AlertCircle className="colour-text-danger" size={16} />
					<div className={sortingColumn.column === "Failed" ? "font-weight-semi-bold" : ""}>Failed</div>
					{sortingColumn && sortingColumn.column === "Failed" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Failed" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		}
	]
	const deliveryDataMap = (data) => data && data.map((distribution, idx) => {
		const user = distribution.contact;
		const delivered = distribution.events.filter(e => e.event === 'delivered');
		const dropped = distribution.events.filter(e => e.event === 'dropped' || e.event === 'bounce');
		const read = distribution.events.filter(e => e.event === 'open');

		return (
			<React.Fragment key={distribution.distributionId}>
				<tr key={idx} className={"cursor-pointer"} onClick={() => showHide(distribution.distributionId)}>
					<td className={deliveryExpanded[distribution.distributionId] ? "expanded-table-row" : ""} style={deliveryExpanded[distribution.distributionId] ? { borderBottom: "none" } : null}>
						<div className="truncate">
							{user.firstName + " " + user.lastName} <span className="extension-label ml-s">{user.email}</span>{" "}
						</div>
					</td>
					<td className={deliveryExpanded[distribution.distributionId] ? "expanded-table-row" : ""} style={deliveryExpanded[distribution.distributionId] ? { borderBottom: "none" } : null}>{delivered && delivered.length > 0 ? getEventDate(delivered) : "-"}</td>
					<td className={deliveryExpanded[distribution.distributionId] ? "expanded-table-row" : ""} style={deliveryExpanded[distribution.distributionId] ? { borderBottom: "none" } : null}>{read && read.length > 0 ? getEventDate(read) : "-"}</td>
					<td className={deliveryExpanded[distribution.distributionId] ? "expanded-table-row" : ""} style={deliveryExpanded[distribution.distributionId] ? { borderBottom: "none" } : null}>{dropped && dropped.length > 0 ? getEventDate(dropped) : "-"}</td>
				</tr>
				{deliveryExpanded[distribution.distributionId] ?
					<>
						<tr>
							<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top left">Event Time</div></td>
							<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top" >Event Type</div></td>
							<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top">&nbsp;</div></td>
							<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top right">&nbsp;</div></td>
						</tr>
						{distribution.events.map((e, idx) => (
							<tr key={distribution.distributionId + "deliveryExpanded" + e.eventTime + idx}>
								<td className="nested-table-empty-td"><div className="nested-table-div-in-td left">{formatDate(e.eventTime)}</div></td>
								<td className="nested-table-empty-td"><div className="nested-table-div-in-td">{e.event}</div></td>
								<td className="nested-table-empty-td"><div className="nested-table-div-in-td">&nbsp;</div></td>
								<td className="nested-table-empty-td"><div className="nested-table-div-in-td right">&nbsp;</div></td>
							</tr>))}
						<tr className="empty-row">
							<td className="nested-table-empty-td">&nbsp;</td>
							<td className="nested-table-empty-td">&nbsp;</td>
							<td className="nested-table-empty-td">&nbsp;</td>
							<td className="nested-table-empty-td">&nbsp;</td>
						</tr>
					</> : ""
				}
			</React.Fragment>
		);
	});

	// Audit Log table data
	const colAuditLogWidths = [15 + "%", 35 + "%", 40 + "%", 10 + "%"];
	const auditHeaders = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Date")} >
					Date
					{sortingColumn && sortingColumn.column === "Date" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Date" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("User/System")} >
					User/System
					{sortingColumn && sortingColumn.column === "User/System" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "User/System" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Action")} >
					Action
					{sortingColumn && sortingColumn.column === "Action" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Action" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		""
	]
	const handleDocumentClick = (doc, event) => {

		if (event) {
			event.stopPropagation()
		}

		if (doc) {
			remote.get(`/documents/document/${doc}`).then((response) => {
				if (response) {
					const newWindow = window.open(response.url, '_top', 'noopener,noreferrer')
					if (newWindow) newWindow.opener = null
				}
			});
		}
	}

	const auditDataMap = (data) => data && data.map((audit, idx) => {
		const { actionTime, who, action, errored, additionalInformation, deliveredFiles, auditId } = audit
		const parsed = additionalInformation && JSON.parse(additionalInformation)
		const hasDisplayableData = parsed && (parsed.files && parsed.files.length > 0) || (parsed.distribution && parsed.distribution.length > 0) || (parsed.receivers && parsed.receivers.length > 0)
		return (
			<React.Fragment key={actionTime + "-" + idx}>
				<tr key={idx}
					className={additionalInformation && hasDisplayableData ? `cursor-pointer` : `default`}
					onClick={() => { additionalInformation && hasDisplayableData && showHideAudit(auditId) }}>
					<td className={auditLogExpanded[auditId] ? "expanded-table-row" : ""} style={auditLogExpanded[auditId] ? { borderBottom: "none" } : null}>{format(parseISO(actionTime), 'dd/MM/yyyy HH:mm')}</td>
					<td className={auditLogExpanded[auditId] ? "expanded-table-row" : ""} style={auditLogExpanded[auditId] ? { borderBottom: "none" } : null}>{who || "System"}</td>
					<td className={auditLogExpanded[auditId] ? "expanded-table-row" : ""} style={auditLogExpanded[auditId] ? { borderBottom: "none" } : null}>{action}</td>
					<td className={`text-align-right ${auditLogExpanded[auditId] ? "expanded-table-row" : ""}`} style={auditLogExpanded[auditId] ? { borderBottom: "none", paddingRight: "1rem" } : { paddingRight: "1rem" }}>
						{errored ? <AlertCircle className="colour-text-danger" /> : <CheckCircle className="colour-text-positive" />}
					</td>
				</tr>
				{auditLogExpanded[auditId] && <AuditDetail detail={additionalInformation} auditId={auditId} handleDocumentClick={handleDocumentClick} changeRoute={changeRoute} />}
			</React.Fragment>
		);
	});

	if (loading) {
		return (
			<Page noBanner>
				<PageContentBodyGrid rowGap="xl">
					<PageGridItem colSpan="12">
						<LoadingIcon centered />
					</PageGridItem>
				</PageContentBodyGrid>
			</Page>
		)
	}

	const noData = (currentView === "audit" && auditLogs.length === 0) || (setCurrentView === 'delivery' && filteredDeliveryList.length === 0);

	return (
		<Page noBanner>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem fullWidth showScrollbar>
					<RawCraigsLibraryTable
						craigsDocs={craigsDocs}
						onDeliveryClick={filter}
						filterOnclick={filterOnclick}
						onCraigsDocClick={handleDocumentClick} />
				</PageGridItem>
				<PageGridItem colSpan="12">
					<FlexWrapper gap="m">
						<PageContentToggle labels={[
							// hidden while Audit log contains dummy data
							{ label: "Audit Log", active: currentView === "audit", onClick: () => changeTab("audit") /*setCurrentView("research") */ },
							{ label: "Recipients/Delivery", active: currentView === "delivery", onClick: () => changeTab("delivery") /*setCurrentView("watching")*/ },
						]} />
						<Divider vertical height={24} />
						<SearchTermFilter
							size="small"
							width={xl ? "l" : l ? "m" : "s"}
							placeholder={`Search`}
							dataSet={currentView === "audit" ? auditLogs : filteredDeliveryList}
							setFilteredData={currentView === "audit" ? setFilteredAuditData : setFilteredDeliveryData}
							path={currentView === "audit" ? ["user", "action"] : ["contact.firstName", "contact.lastName"]}
							hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						/>
					</FlexWrapper>
				</PageGridItem>
				<PageGridItem colSpan="12">
					{noData ?
						<SuggestionMessage message={"No data to display."} /> :
						<Table
							minWidth={1000}
							tableStyle={{ maxWidth: 1200 }}
							colWidths={currentView === "audit" ? colAuditLogWidths : colDeliveryWidths}
							headers={currentView === "audit" ? auditHeaders : deliveryHeaders}
							dataMap={currentView === "audit" ? auditDataMap(auditDataDisplaying) : deliveryDataMap(deliveryDataDisplaying)}
						/>}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default withRouteChange(DeliveryDetails);


const AuditDetail = ({ detail, auditId, handleDocumentClick, changeRoute }) => {
	if (!detail) {
		return null;
	}

	detail = JSON.parse(detail)

	if (!detail) {
		return null;
	}

	const getFileIconAndName = (name, extension) => {

		if (extension === 'xlsx') {
			return <div style={{ display: "flex", gap: "0.5rem" }}><XlsIcon noHover size={16} />{name}</div>
		}
		if (extension === 'pdf') {
			return <div style={{ display: "flex", gap: "0.5rem" }}><PdfIcon noHover size={16} />{name}</div>
		}
		if (extension === 'xml') {
			return <div style={{ display: "flex", gap: "0.5rem" }}><XMLIcon noHover size={16} />{name}</div>
		}
		return name
	}


	const formatBytes = (bytes) => {
		if (!bytes) {
			return "-"
		}
		if (bytes < 1024) {
			return `${bytes} b`;
		}
		bytes /= 1024;
		if (bytes < 1024) {
			return `${bytes.toFixed(0)} kb`;
		}
		bytes /= 1024;
		return `${bytes.toFixed(0)} Mb`
	}


	return (
		<>
			{detail.files && <>
				<tr >
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top left">Files Delivered</div></td>
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top">&nbsp;</div></td>
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top">&nbsp;</div></td>
					<td className="nested-table-empty-td text-align-right"><div className="nested-table-div-in-td no-top right" >File Size</div></td>
				</tr>
				{detail.files.map((file, idx) => {
					if (!file || !file.filename) {
						return <></>
					}
					return (
						<tr key={auditId + "file-expanded" + idx} className={`${file && file.docId && "audit-log-file-row"}`} onClick={(e) => { file && file.docId && handleDocumentClick(file.docId, e) }}>
							<td colSpan={3} className="nested-table-empty-td"><div style={{ width: "400%" }} className="nested-table-div-in-td left">{file && (file.filename) ? getFileIconAndName(file.filename, file.extension) : "-"}</div></td>
							<td className="nested-table-empty-td text-align-right"><div className="nested-table-div-in-td right">{file && file.bytes ? formatBytes(file.bytes) : "-"}</div></td>
						</tr>
					)
				})}
			</>}
			{detail.distribution && <>
				<tr>
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top left">Lists sent to</div></td>
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top">&nbsp;</div></td>
					<td className="nested-table-empty-td"><div className="nested-table-div-in-td no-top">&nbsp;</div></td>
					<td className="nested-table-empty-td text-align-right"><div className="nested-table-div-in-td no-top right" ></div></td>
				</tr>
				{detail.distribution.map((list, idx) => (
					<tr key={auditId + "list-expanded" + idx} className={`${list && list.id && "audit-log-file-row"}`} onClick={(e) => { list && list.id && changeRoute(ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/" + list.id) }}>
						<td colSpan={4} className="nested-table-empty-td"><div style={{ width: "400%" }} className="nested-table-div-in-td left">{list ? list.name ? list.name : list : "-"}</div></td>
					</tr>))}


			</>}
			{detail.receivers && <>
				<tr>
					<td colSpan={3} className="nested-table-empty-td"><div className="nested-table-div-in-td no-top left">Emails sent to</div></td>
					<td className="nested-table-empty-td text-align-right"><div className="nested-table-div-in-td no-top right" ></div></td>
				</tr>
				{detail.receivers.map((receiver, idx) => (
					<tr key={auditId + "receivers-expanded" + idx}>
						<td colspan={4} className="nested-table-empty-td"><div style={{ width: "400%" }} className="nested-table-div-in-td left">{receiver ? receiver : "-"}</div></td>
					</tr>))}

			</>}
		</>
	)

}