import React, { Component } from 'react';
import "./Icons.scss";

class XlsIcon extends Component {
	render() {
		let colour = this.props.colour? this.props.colour : "#b4b4b4"
		const { size, noHover, className } = this.props;

		return (
			<svg className={`file-icon xls${noHover ? " no-hover" : ""}${className ? ' ' + className : ''}`} style={{width: size ? size : 24}} width={size ? size : "24"} height={size ? size : "24"} viewBox="0 0 24 24">
				<g id="Group_6108" data-name="Group 6108" clipPath="url(#clip-path)">
					<path
						id="Path_2561"
						data-name="Path 2561"
						d="M24,4.74a1.634,1.634,0,0,0-.465-1.166,1.573,1.573,0,0,0-1.129-.461h-.013c-1.775.013-3.58.011-5.326.009l-1.95,0q0-.207,0-.413c0-.388,0-.789,0-1.184A1.559,1.559,0,0,0,14.563.346,1.457,1.457,0,0,0,13.37.023c-1.127.2-2.273.405-3.381.6L8.2.947,4.428,1.62l-1.976.353-.244.043c-.194.034-.4.07-.594.107A1.958,1.958,0,0,0,.438,2.8,2.02,2.02,0,0,0,0,4.094q0,6.668,0,13.335V19.91c0,.072,0,.128,0,.181a1.914,1.914,0,0,0,1.566,1.783l4.449.8.837.15,2.233.4,4.2.752A1.733,1.733,0,0,0,13.6,24a1.492,1.492,0,0,0,.976-.351,1.56,1.56,0,0,0,.54-1.209c0-.387,0-.78,0-1.16q0-.194,0-.388h7.267a1.517,1.517,0,0,0,1.61-1.628q0-1.816,0-3.631c0-3.57,0-7.262.007-10.893M15.426,17.617c.2,0,.4,0,.6,0a.7.7,0,1,0-.013-1.408c-.24,0-.483,0-.718,0h-.168v-1.04h.8a.84.84,0,0,0,.618-.228.672.672,0,0,0,.188-.488.716.716,0,0,0-.813-.7h-.787v-1.04h.25c.209,0,.425,0,.638,0a.7.7,0,0,0,.017-1.409c-.24-.005-.482,0-.716,0h-.192V10.254h.651c.087,0,.178,0,.27,0a.7.7,0,0,0,.682-.7.7.7,0,0,0-.691-.7c-.279,0-.552,0-.84,0h-.068V7.805h.183c.226,0,.46,0,.691,0a.742.742,0,0,0,.535-.223.7.7,0,0,0,.191-.5A.708.708,0,0,0,16,6.389c-.271,0-.535,0-.815,0h-.059V4.529h7.259c.148,0,.181.03.182.03a.346.346,0,0,1,.03.184q0,7.262,0,14.525c0,.194-.013.207-.2.207H15.124V17.617h.3M13.718,9.738q0,1.144,0,2.287V22.343c0,.036,0,.072,0,.108a.161.161,0,0,1-.04.119.155.155,0,0,1-.122.016l-2.08-.373-1.909-.342-3.391-.606-2.385-.426-.394-.07c-.517-.092-1.052-.188-1.578-.284a.521.521,0,0,1-.428-.509c0-.031,0-.063,0-.1V4.17c0-.437.13-.593.555-.67l3.772-.675,6.022-1.077.471-.083c.419-.073.852-.149,1.277-.235a.453.453,0,0,1,.089-.01.145.145,0,0,1,.092.024.272.272,0,0,1,.055.194c-.007,2.7-.006,5.445-.006,8.1"
						transform="translate(0 0)"
						fill={colour}
					/>
					<path
						id="Path_2562"
						data-name="Path 2562"
						d="M144.075,244.727c.162-.251.329-.511.491-.769a.7.7,0,0,0-.016-.8.676.676,0,0,0-.705-.278.762.762,0,0,0-.5.388q-.873,1.364-1.748,2.726l-.237.369-.656-1.023-.859-1.34-.129-.2c-.13-.2-.265-.415-.4-.621a.684.684,0,0,0-.775-.283.669.669,0,0,0-.489.661,1.041,1.041,0,0,0,.162.484c.676,1.063,1.414,2.215,2.32,3.621-.142.215-.281.434-.417.646l-.156.244-.381.594c-.453.706-.922,1.436-1.379,2.157a.963.963,0,0,0-.15.483.667.667,0,0,0,.516.65.679.679,0,0,0,.762-.308c.2-.311.406-.629.6-.937l.245-.382,1.187-1.851.869,1.354.446.7.2.311c.17.266.345.54.521.809a.693.693,0,0,0,1.268-.506,1.1,1.1,0,0,0-.157-.349c-.908-1.42-1.645-2.569-2.317-3.611.453-.7.911-1.414,1.353-2.1Z"
						transform="translate(-134.009 -235.653)"
						fill={colour}
					/>
					<path
						id="Path_2563"
						data-name="Path 2563"
						d="M579.448,464.676c.285,0,.574,0,.854,0h1.041c.164,0,.329,0,.492,0a.723.723,0,0,0,.519-.219.708.708,0,0,0-.517-1.2h-.062c-.767,0-1.559-.007-2.34.006a.844.844,0,0,0-.522.24.647.647,0,0,0-.117.753.69.69,0,0,0,.652.416"
						transform="translate(-561.782 -449.511)"
						fill={colour}
					/>
					<path
						id="Path_2564"
						data-name="Path 2564"
						d="M579.651,216.646c.277,0,.558,0,.829,0h1.1c.154,0,.309,0,.463,0a.7.7,0,0,0,.707-.7.716.716,0,0,0-.2-.5.678.678,0,0,0-.487-.209c-.776,0-1.576-.005-2.444,0a.687.687,0,0,0-.681.633.7.7,0,0,0,.167.539.727.727,0,0,0,.547.242"
						transform="translate(-561.982 -208.843)"
						fill={colour}
					/>
					<path
						id="Path_2565"
						data-name="Path 2565"
						d="M579.638,382.058H582a.7.7,0,0,0,.725-.7.7.7,0,0,0-.2-.5.727.727,0,0,0-.524-.215c-.757,0-1.533,0-2.374,0a.707.707,0,0,0-.528,1.195.745.745,0,0,0,.537.216"
						transform="translate(-561.956 -369.349)"
						fill={colour}
					/>
					<path
						id="Path_2566"
						data-name="Path 2566"
						d="M582.037,298.072c-.728,0-1.5,0-2.358,0a.791.791,0,0,0-.247.036.7.7,0,0,0-.468.764.683.683,0,0,0,.677.606c.171,0,.343,0,.514,0h1.876a.7.7,0,0,0,.74-.7.69.69,0,0,0-.194-.492.747.747,0,0,0-.541-.216"
						transform="translate(-562.005 -289.226)"
						fill={colour}
					/>
					<path
						id="Path_2567"
						data-name="Path 2567"
						d="M581.965,546.13h-2.313a.7.7,0,0,0-.753.7.689.689,0,0,0,.191.491.768.768,0,0,0,.555.219h2.315a.71.71,0,0,0,.754-.7.687.687,0,0,0-.193-.49.769.769,0,0,0-.557-.219"
						transform="translate(-561.948 -529.923)"
						fill={colour}
					/>
				</g>
			</svg>
		);
	}
}
export default XlsIcon;
