import energy from "../images/sectors/Energy.png";
import industrials from "../images/sectors/Metals & Mining.png";
import consumerDiscretionary from "../images/sectors/Consumer Discretionary.png";
import consumerStaples from "../images/sectors/Consumer Staples.png";
import healthCare from "../images/sectors/Aged Care & Retirement.png";
import financials from "../images/sectors/Equity Research.png";
import informationTechnology from "../images/sectors/Information Technology.png";
import communicationServices from "../images/sectors/Telecommunications.png";
import utilities from "../images/sectors/Utilities.png";
import realEstate from "../images/sectors/Real Estate.png";


export const files = [
	{
		id: 636,
		publishedDoc: 0,
		companyId: 0,
		filename: "testing123.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/test.pdf"
	},
	{
		id: 637,
		publishedDoc: 0,
		companyId: 0,
		filename: "MEL.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 631,
		publishedDoc: 0,
		companyId: 0,
		filename: "MEL.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 632,
		publishedDoc: 0,
		companyId: 0,
		filename: "MEL.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 609,
		publishedDoc: 0,
		companyId: 0,
		filename: "MCY.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 610,
		publishedDoc: 0,
		companyId: 0,
		filename: "MCY.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 620,
		publishedDoc: 0,
		companyId: 0,
		filename: "FWL.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 621,
		publishedDoc: 0,
		companyId: 0,
		filename: "FWL.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 604,
		publishedDoc: 0,
		companyId: 0,
		filename: "ERD.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 605,
		publishedDoc: 0,
		companyId: 0,
		filename: "ERD.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 582,
		publishedDoc: 0,
		companyId: 0,
		filename: "AIR.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 583,
		publishedDoc: 0,
		companyId: 0,
		filename: "AIR.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 593,
		publishedDoc: 0,
		companyId: 0,
		filename: "EBO.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 594,
		publishedDoc: 0,
		companyId: 0,
		filename: "EBO.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 577,
		publishedDoc: 0,
		companyId: 0,
		filename: "IKE.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 578,
		publishedDoc: 0,
		companyId: 0,
		filename: "IKE.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 555,
		publishedDoc: 0,
		companyId: 0,
		filename: "Energy.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 556,
		publishedDoc: 0,
		companyId: 0,
		filename: "Energy.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 566,
		publishedDoc: 0,
		companyId: 0,
		filename: "Utilities.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 566,
		publishedDoc: 0,
		companyId: 0,
		filename: "Utilities.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 550,
		publishedDoc: 0,
		companyId: 0,
		filename: "RealEstate.pdf",
		pdf: true,
		defaultPdf: true,
		data: false,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},
	{
		id: 551,
		publishedDoc: 0,
		companyId: 0,
		filename: "RealEstate.xlsx",
		pdf: false,
		defaultPdf: false,
		data: true,
		s3Link: "dev/craigs/test/MEL.xlsx"
	},

]

export const dummyUsers = [
	{
		id: 123456, firstName: "Coraline", lastName: "Jones", email: "Coraline.jones@gmail.com",
		readDocs: [
			{researchId: 1, viewingDate: "12.14pm 20/12/2022", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: false,},
			{researchId: 2, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true, },
			{researchId: 3, viewingDate: "09.11am 22/12/2022", viewedIn: "portal", device: "desktop", download: "modal", sent: true, delivered: false, },
			{researchId: 4, viewingDate: "09.37am 09/01/2023", viewedIn: "email", device: "desktop", download: "modal", sent: true, delivered: true, },
			{researchId: 5, viewingDate: "10.05am 10/01/2023", viewedIn: "email", device: "mobile", download: "pdf", sent: true, delivered: true, },
			{researchId: 13, viewingDate: "11.11am 11/01/2023", viewedIn: "portal", device: "mobile", download: "pdf", sent: true, delivered: true, },
			{researchId: 29, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "", download: "", sent: true, delivered: true, },
		]
	},
	{
		id: 134567, firstName: "Gary", lastName: "Oak", email: "Gary.oak@acme.com",
		readDocs: [
			{researchId: 1, viewingDate: "12.14pm 20/12/2022", viewedIn: "", device: "", download: "", sent: true, delivered: false },
			{researchId: 6, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 24, viewingDate: "09.11am 22/12/2022", viewedIn: "email", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 27, viewingDate: "09.37am 09/01/2023", viewedIn: "", device: "", download: "", sent: true, delivered: true },
			{researchId: 5, viewingDate: "10.05am 10/01/2023", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 16, viewingDate: "11.11am 11/01/2023", viewedIn: "email", device: "mobile", download: "modal", sent: true, delivered: true},
			{researchId: 23, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "mobile", download: "pdf", sent: true, delivered: true },
		]
	},
	{
		id: 145678, firstName: "Juanito", lastName: "Arcoiris", email: "rainbow.johnny@acme.com",
		readDocs: [
			{researchId: 2, viewingDate: "12.14pm 20/12/2022", viewedIn: "", device: "", download: "", sent: true, delivered: true },
			{researchId: 10, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 18, viewingDate: "09.11am 22/12/2022", viewedIn: "email", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 26, viewingDate: "09.37am 09/01/2023", viewedIn: "", device: "", download: "", sent: true, delivered: true },
			{researchId: 31, viewingDate: "10.05am 10/01/2023", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 29, viewingDate: "11.11am 11/01/2023", viewedIn: "email", device: "mobile", download: "modal", sent: true, delivered: true },
			{researchId: 32, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "", download: "", sent: true, delivered: false },
		]
	},
	{
		id: 156789, firstName: "Rosa", lastName: "Espinosa", email: "prickly.rose@acme.com",
		readDocs: [
			{researchId: 3, viewingDate: "12.14pm 20/12/2022", viewedIn: "", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 9, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 14, viewingDate: "09.11am 22/12/2022", viewedIn: "email", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 21, viewingDate: "09.37am 09/01/2023", viewedIn: "", device: "mobile", download: "pdf", sent: true, delivered: true },
			{researchId: 15, viewingDate: "10.05am 10/01/2023", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 32, viewingDate: "11.11am 11/01/2023", viewedIn: "email", device: "mobile", download: "modal", sent: true, delivered: true },
			{researchId: 30, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "mobile", download: "pdf", sent: true, delivered: true },
		]
	},
	{
		id: 345678, firstName: "Fifi", lastName: "Morado", email: "fifi.morado@acme.com",
		readDocs: [
			{researchId: 4, viewingDate: "12.14pm 20/12/2022", viewedIn: "", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 11, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 17, viewingDate: "09.11am 22/12/2022", viewedIn: "email", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 28, viewingDate: "09.37am 09/01/2023", viewedIn: "", device: "mobile", download: "pdf", sent: true, delivered: true },
			{researchId: 34, viewingDate: "10.05am 10/01/2023", viewedIn: "portal", device: "desktop", download: "pdf",  sent: true, delivered: true },
			{researchId: 7, viewingDate: "11.11am 11/01/2023", viewedIn: "email", device: "mobile", download: "modal",  sent: true, delivered: true },
			{researchId: 20, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "", download: "",  sent: true, delivered: true },
		]
	},
	{
		id: 345678, firstName: "Polito", lastName: "Ciceron", email: "polito.ciceron@acme.com",
		readDocs: [
			{researchId: 5, viewingDate: "12.14pm 20/12/2022", viewedIn: "", device: "desktop", download: "modal",  sent: true, delivered: true},
			{researchId: 8, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "modal",  sent: true, delivered: true },
			{researchId: 19, viewingDate: "09.11am 22/12/2022", viewedIn: "email", device: "desktop", download: "pdf",  sent: true, delivered: true },
			{researchId: 25, viewingDate: "09.37am 09/01/2023", viewedIn: "", device: "mobile", download: "pdf",  sent: true, delivered: true },
			{researchId: 33, viewingDate: "10.05am 10/01/2023", viewedIn: "portal", device: "desktop", download: "pdf",  sent: true, delivered: true },
			{researchId: 16, viewingDate: "11.11am 11/01/2023", viewedIn: "email", device: "mobile", download: "modal",  sent: true, delivered: true },
			{researchId: 23, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "", download: "",  sent: true, delivered: true },
		]
	},
	{
		id: 345678, firstName: "Pescado", lastName: "Frito", email: "fried.fish@acme.com",
		readDocs: [
			{researchId: 2, viewingDate: "10.10am 21/12/2022", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 4, viewingDate: "09.37am 09/01/2023", viewedIn: "email", device: "desktop", download: "modal", sent: true, delivered: true },
			{researchId: 5, viewingDate: "10.05am 10/01/2023", viewedIn: "email", device: "mobile", download: "pdf", sent: true, delivered: true },
			{researchId: 13, viewingDate: "11.11am 11/01/2023", viewedIn: "portal", device: "mobile", download: "pdf", sent: true, delivered: true },
		]
	},
	{
		id: 345678, firstName: "Florinda", lastName: "Mesa", email: "florinda.table@acme.com",
		readDocs: [
			{researchId: 1, viewingDate: "12.14pm 20/12/2022", viewedIn: "portal", device: "desktop", download: "pdf", sent: true, delivered: true },
			{researchId: 3, viewingDate: "09.11am 22/12/2022", viewedIn: "portal", device: "desktop", download: "modal",  sent: true, delivered: true },
			{researchId: 5, viewingDate: "10.05am 10/01/2023", viewedIn: "email", device: "mobile", download: "pdf",  sent: true, delivered: true },
			{researchId: 29, viewingDate: "02.22pm 12/01/2023", viewedIn: "", device: "", download: "",  sent: true, delivered: true },
		]
	},
]

export const dummyResearch = [
	{
		date: "2023-01-30",
		ticker: "SPK.NZ",
		title: "Spark New Zealand - Test Friday 23/09/234",
		author: "William Goldsbury",
		type: "Company Note",
		docId: 653,
		campaignId: "985efe04-ba90-42d6-96aa-ce50d2f3f7a5",
		sent: 0,
		delivered: 0,
		opened: 0,
		files: [
			{
				id: 796,
				publishedDoc: 0,
				companyId: 0,
				filename: "Spark New Zealand - Test Friday 23/09/234 - Private Wealth.pdf",
				pdf: true,
				defaultPdf: false,
				data: false,
				s3Link: "test/craigs/653/Spark New Zealand - Test Friday 23/09/234 - Private Wealth.pdf",
			},
			{
				id: 797,
				publishedDoc: 0,
				companyId: 0,
				filename: "Spark New Zealand - Test Friday 23/09/234.pdf",
				pdf: true,
				defaultPdf: true,
				data: false,
				s3Link: "test/craigs/653/Spark New Zealand - Test Friday 23/09/234.pdf",
			},
			{
				id: 798,
				publishedDoc: 0,
				companyId: 0,
				filename: "SPK.NZ.xlsx",
				pdf: false,
				defaultPdf: false,
				data: true,
				s3Link: "test/craigs/653/SPK.NZ.xlsx",
			},
		],
		read: false,
		views: 6,
	},
	{ researchId: 1, type: "Company Note", ticker: "AIA.NZ", files: [files[0], files[1]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 2, type: "Company Note", ticker: "MEL.NZ", files: [files[2], files[3]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 3, type: "Company Note", ticker: "MCY.NZ", files: [files[4], files[5]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 4, type: "Company Note", ticker: "FWL.NZ", files: [files[6], files[7]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 5, type: "Company Note", ticker: "ERD.NZ", files: [files[8], files[9]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 6, type: "Company Note", ticker: "AIA.NZ", files: [files[0], files[1]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 7, type: "Sector Note", ticker: "Utilities", files: [files[18], files[19]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 8, type: "Company Note", ticker: "ERD.NZ", files: [files[8], files[9]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 9, type: "Company Note", ticker: "MCY.NZ", files: [files[4], files[5]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 10, type: "Company Note", ticker: "MEL.NZ", files: [files[2], files[3]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 11, type: "Company Note", ticker: "FWL.NZ", files: [files[6], files[7]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 12, type: "Sector Note", ticker: "Real Estate", files: [files[20], files[1]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 13, type: "Company Note", ticker: "AIR.NZ", files: [files[10], files[11]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 14, type: "Company Note", ticker: "MCY.NZ", files: [files[4], files[5]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 15, type: "Sector Note", ticker: "Energy", files: [files[16], files[17]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 16, type: "Company Note", ticker: "EBO.NZ", files: [files[12], files[13]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 17, type: "Company Note", ticker: "FWL.NZ", files: [files[6], files[7]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 18, type: "Company Note", ticker: "MEL.NZ", files: [files[2], files[3]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 19, type: "Company Note", ticker: "ERD.NZ", files: [files[8], files[9]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 20, type: "Sector Note", ticker: "Utilities", files: [files[18], files[19]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 21, type: "Company Note", ticker: "MCY.NZ", files: [files[4], files[5]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 22, type: "Sector Note", ticker: "Real Estate", files: [files[20], files[1]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 23, type: "Company Note", ticker: "EBO.NZ", files: [files[12], files[13]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 24, type: "Company Note", ticker: "AIA.NZ", files: [files[0], files[1]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 25, type: "Company Note", ticker: "ERD.NZ", files: [files[8], files[9]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 26, type: "Company Note", ticker: "MEL.NZ", files: [files[2], files[3]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 27, type: "Company Note", ticker: "AIA.NZ", files: [files[0], files[1]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 28, type: "Company Note", ticker: "FWL.NZ", files: [files[6], files[7]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 29, type: "Company Note", ticker: "IKE.NZ", files: [files[14], files[15]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 30, type: "Company Note", ticker: "MCY.NZ", files: [files[4], files[5]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 31, type: "Company Note", ticker: "MEL.NZ", files: [files[2], files[3]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
	{ researchId: 32, type: "Sector Note", ticker: "Energy", files: [files[16], files[17]], publishedDate: "22/01/2022", title: "KEEP CALM and GENERATE", author: "Musashi Miyamoto" },
	{ researchId: 33, type: "Company Note", ticker: "ERD.NZ", files: [files[8], files[9]], publishedDate: "22/01/2022", title: "Investor Day Key Takeaways", author: "Jonathan Joestar" },
	{ researchId: 34, type: "Company Note", ticker: "FWL.NZ", files: [files[6], files[7]], publishedDate: "22/01/2022", title: "Core strong, market volatile", author: "Carmen Sandiego" },
];

export const analyticsTickersDummyData = [
	{
		tickerId: 1,
		ric: "AIA.NZ",
		name: "Auckland International Airport Ltd",
		sector: "Industrials",
		industries: "Transportation Infrastructure",
		headquartersLocation: "Auckland, New Zealand",
		website: "aucklandairport.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/AIA.NZ.png",
	},
	{
		tickerId: 4,
		ric: "MEL.NZ",
		name: "Meridian Energy Ltd",
		sector: "Utilities",
		industries: "Independent Power and Renewable Electricity Producers",
		headquartersLocation: "Wellington, New Zealand",
		website: "meridianenergy.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/MEL.NZ.png",
	},
	{
		tickerId: 14,
		ric: "MCY.NZ",
		name: "Mercury NZ Ltd",
		sector: "Utilities",
		industries: "Electric Utilities",
		headquartersLocation: "Auckland, New Zealand",
		website: "www.mercury.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/MCY.NZ.png",
	},
	{
		tickerId: 110,
		ric: "FWL.NZ",
		name: "Foley Wines Ltd",
		sector: "Consumer Staples",
		industries: "Beverages",
		headquartersLocation: "Blenheim, New Zealand",
		website: "foleywines.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/FWL.NZ.png",
	},
	{
		tickerId: 64,
		ric: "ERD.NZ",
		name: "EROAD Ltd",
		sector: "Information Technology",
		industries: "Electronic Equipment, Instruments & Components",
		headquartersLocation: "Auckland, New Zealand",
		website: "eroad.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/ERD.NZ.png",
	},
	{
		tickerId: 17,
		ric: "AIR.NZ",
		name: "Air New Zealand Ltd",
		sector: "Industrials",
		industries: "Airlines",
		headquartersLocation: "Auckland, New Zealand",
		website: "airnewzealand.co.nz",
		logo: "https://research-portal-test.3skye.com/icons/tickers/AIR.NZ.png",
	},
	{
		tickerId: 88,
		ric: "IKE.NZ",
		name: "Ikegps Group Ltd",
		sector: "Information Technology",
		industries: "Electronic Equipment, Instruments & Components",
		headquartersLocation: "Wellington, New Zealand",
		website: "ikegps.com",
		logo: "https://research-portal-test.3skye.com/icons/tickers/IKE.NZ.png",
	},
	{
		tickerId: 8,
		ric: "EBO.NZ",
		name: "EBOS Group Ltd",
		sector: "Health Care",
		industries: "Health Care Providers & Services",
		headquartersLocation: "Christchurch, New Zealand",
		website: "ebosgroup.com",
		logo: "https://research-portal-test.3skye.com/icons/tickers/EBO.NZ.png",
	}
]

export const dummyTickers = [
	{
		name: "Auckland International Airport Limited",
		code: "AIA.NZ",
		ric: "AIA.NZ",
		sector: "Tourism",
		industries: "Hospitality",
		headquartersLocation: "Auckland, New Zealand",
		website: "aucklandairport.co.nz",
		dateAdded: "21/01/2022",
		lastPublished: "08/01/2022",
		disclosure: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
		research: dummyResearch
	},
	{
		name: "Auckland International Airport Limited",
		code: "AIA.NZ",
		ric: "AIA.NZ",
		sector: "Tourism",
		industries: "Hospitality",
		headquartersLocation: "Auckland, New Zealand",
		website: "aucklandairport.co.nz",
		dateAdded: "21/01/2022",
		lastPublished: "08/01/2022",
		disclosure: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
		research: dummyResearch
	},
];

export const gicsSectors = [
	{
		name: "Energy",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: energy
	},
	{
		name: "Industrials",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: industrials
	},
	{
		name: "Consumer Discretionary",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: consumerDiscretionary
	},
	{
		name: "Consumer Staples",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: consumerStaples
	},
	{
		name: "Health Care",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: healthCare
	},
	{
		name: "Financials",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: financials
	},
	{
		name: "Information Technology",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: informationTechnology
	},
	{
		name: "CommunicationServices",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: communicationServices
	},
	{
		name: "Utilities",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: utilities
	},
	{
		name: "Real Estate",
		region: "New Zealand",
		tickers: dummyTickers,
		research: dummyResearch,
		lastPublished: "08/01/2022",
		dateAdded: "21/01/2022",
		createdBy: "System",
		image: realEstate
	}
];

export const mockComments = [
	{
		id: 1,
		message: "This is a comment",
		author: "Coraline Jones",
		authorRole: "Compliance",
		authorId: 123456,
		authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
		date: "2024-02-16T00:38:22",
		replies: [
			{
				id: 2,
				message: "Are you sure?",
				author: "Gary Oak",
				authorRole: "Analyst",
				authorId: 134567,
				authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
				date: "2024-02-17T00:38:22",
			},
			{
				id: 3,
				message: "Yes, I am.",
				author: "Coraline Jones",
				authorRole: "Compliance",
				authorId: 123456,
				authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
				date: "2024-02-18T00:38:22",
			}
		]

	},
	{
		id: 4,
		message: "Yeah, them Egyptian pharaohs came down from the clouds to watch us groove with snakes up on the hill.",
		author: "Coraline Jones",
		authorRole: "Compliance",
		authorId: 123456,
		authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
		date: "2024-02-16T00:38:22",
		replies: [
			{
				id: 5,
				message: "Did you know the human eye can see more shades of green than any other colour? It's unusual, but that's life.",
				author: "Gary Oak",
				authorRole: "Analyst",
				authorId: 134567,
				authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
				date: "2024-02-17T00:38:22",
			},
			{
				id: 6,
				message: "We all live and die by the principle that the big bad wolf of the world one day will lose all sense of time.",
				author: "Coraline Jones",
				authorRole: "Compliance",
				authorId: 123456,
				authorImg: "https://images.pexels.com/photos/1851164/pexels-photo-1851164.jpeg",
				date: "2024-02-18T23:23:54Z",
			}
		]

	}
]
