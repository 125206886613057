import React from 'react';
import { Plus } from 'react-feather';
import Tooltip from './popups/Tooltip';
import placeholderImage from "../images/sectors/Research 2.png";
import "./TickerSectorImage.scss";

const TickerSectorImage = ({image, imageLoading, hoverUpload, hoverIcon, onClick, size, imageSize, selected, galleryImage, className, country, filename, removeOverlay}) => {
	const imageWidth = imageSize ? imageSize : ""

	return (
		<Tooltip disabled={!hoverUpload} label={removeOverlay ? "" : "Upload new image"} delay={700}>
			<div
				className={`ts-image-container${hoverUpload && !removeOverlay ? " ts-image-hover-upload" : ""}${size ? " ts-image-size-" + size : ""}${selected ? " selected-image" : ""}${
					className ? " " + className : ""
				}${galleryImage ? " gallery-image" : ""}`}
				onClick={onClick}
				style={{ width: imageWidth, height: imageWidth }}
			>
				{/* <CompanyImage country={country} filename={filename} />  */}
				{!imageLoading && <img src={image ? image : placeholderImage} alt="ticker/sector logo"/>}
				{removeOverlay ? "" : <div className="ts-image-overlay">{hoverIcon ? hoverIcon : <Plus />}</div>}
			</div>
		</Tooltip>
	);
}

export default TickerSectorImage;