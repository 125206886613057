import React, { useEffect, useState } from "react";
import SidebarExtensionLink from "./SidebarExtensionLink";
import { withRouter } from "react-router";
import { useStorage } from "../../../Utils/Utils";
import "./SidebarExtensionLinkList.scss";

const SidebarExtensionLinkList = ({ links, location }) => {
	const [me, setMe] = useState(null)
	const filteredLinks = me && links ? links.filter(link => !link.requires || me && me.perms.includes(link.requires)) : []

	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("/crm/me").then((me) => {
			setMe(me)
		});
	});

	return (
		<div className="sidebar-extension-link-list">
			{filteredLinks && filteredLinks.length > 0 && filteredLinks.map((link, idx) => (
				<SidebarExtensionLink link={link.path} active={location.pathname.includes(link.path)} key={idx}>{link.name}</SidebarExtensionLink>
			))}
		</div>
	);
} 

export default withRouter(SidebarExtensionLinkList);