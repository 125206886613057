import React from 'react';
import DetailField from '../../layouts/Shared/DetailSection/DetailField';
import IconCounter from '../IconCounter';
import "./AnalyticsMetricFilter.scss";

const AnalyticsMetricFilter = ({ inactive, label, icon, status, count, active, onClick, style, pointer }) => {
	return (
		<DetailField
			onClick={onClick}
			className={pointer ? "analytics-metric" : `analytics-metric-filter${inactive ? " inactive-filter" : ""}${active ? " active-filter" : ""}`}
			style={style}
			label={label}
			value={<IconCounter dataAvailable status={status} count={count || count === 0 ? count : "-"} icon={icon} pointer={pointer || style && style.cursor} />}
		/>
	);
}

export default AnalyticsMetricFilter;