import React, { useState } from "react";
import useWindowDimensions from "../../Utils/Utils";
import Page, { PageContentBodyGrid } from "../Shared/Page/Page";
import PageGridDivider from "../Shared/Page/PageGridDivider";
import PageGridItem from "../Shared/Page/PageGridItem";
import "./AnalyticsPage.scss";
import MapMetrics from "./Components/MapMetrics";
import TopResearchTable from "./Components/TopResearchTable";
import TopUsersTable from "./Components/TopUsersTable";
import TopTickers from "./Components/TopTickers";
import TopAnalystsTable from "./Components/TopAnalystsTable";
import TotalMetrics from "./Components/TotalMetrics";
import UsageMetrics from "./Components/UsageMetrics";
import BrowserChart from "./Charts/BrowserChart";
import TopSearches from "./Components/TopSearches";
import AnalyticsToggleHeader from "./Components/AnalyticsToggleHeader"
import AnalyticsHeader from "./Components/AnalyticsHeader"
import "./AnalyticsPage.scss";


const AnalyticsPage = (props) => {
	const { width } = useWindowDimensions();
	const dropdownOneOptions = ["Usage", "Readers by Country"]
	const dropdownTwoOptions = ["Device Usage", "Browser Usage"]
	const dateRanges = [{ months: 6 }, { months: 12 }, "2020-01-01"]

	const [dropdownOneOption, setDropdownOneOption] = useState(dropdownOneOptions[0])
	const [dropdownTwoOption, setDropdownTwoOption] = useState(dropdownTwoOptions[0])
	const [selectedDateRange, setSelectedDateRange] = useState(dateRanges[0]);
	const [researchDateRange, setResearchDateRange] = useState(dateRanges[0]);
	const [tickersDateRange, setTickersDateRange] = useState(dateRanges[0]);
	const [authorsDateRange, setAuthorsDateRange] = useState(dateRanges[0]);
	const [deviceDateRange, setDeviceDateRange] = useState(dateRanges[0]);

	return (
		<Page id="analytics-page" style={{ paddingBottom: 0 }} noBanner>
			{/* LEFT SIDE GRID */}
			<PageContentBodyGrid id="analytics-page-grid" paddingTop="0px" rowGap="xl" gridTemplateRows="1fr" splitScroll >
				<PageGridItem container col="1 / span 7" style={{ paddingTop: "1.5rem" }}>
					<PageContentBodyGrid style={{ rowGap: "1.5rem" }} paddingBottom={"3rem"} gridColCount="6" subGrid>
						<PageGridItem col="1 / span 6">
							<TotalMetrics />
						</PageGridItem>
						<PageGridDivider col="1 / span 6" />
						<PageGridItem col="1 / span 6">
							<AnalyticsToggleHeader value={dropdownOneOption} values={dropdownOneOptions} setValue={setDropdownOneOption} dateRanges={dateRanges} selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange} />
							{dropdownOneOption === dropdownOneOptions[0] ? <UsageMetrics timeFrame={selectedDateRange} /> : <MapMetrics timeFrame={selectedDateRange} />}
							{/* <AnalyticsToggleBlock header="Usage" dateRanges={dateRanges} values={dropdownOneOptions}>
								{(value, dateRange) => {
									return value === "Usage" ? <UsageMetrics timeFrame={dateRange} />  : <MapMetrics timeFrame={dateRange} />
								}}
							</AnalyticsToggleBlock> */}
						</PageGridItem>
						<PageGridDivider col="1 / span 6" />
						<PageGridItem col="1 / span 6">
							<AnalyticsHeader dateRanges={dateRanges}  timeFrame={researchDateRange} setTimeFrame={setResearchDateRange}>Research - Most Viewed</AnalyticsHeader>
							<TopResearchTable dateRange={researchDateRange} />
						</PageGridItem>
						<PageGridItem col="1 / span 6" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1.5rem" }} subGrid>
							<div col="1 / span 3">
								<AnalyticsHeader dateRanges={dateRanges}  timeFrame={tickersDateRange} setTimeFrame={setTickersDateRange}>Tickers - Most Viewed</AnalyticsHeader>
								<TopTickers dateRange={tickersDateRange} />

							</div>
							<div col="3 / span 3">
								<AnalyticsHeader dateRanges={dateRanges}  timeFrame={authorsDateRange} setTimeFrame={setAuthorsDateRange}>Authors - Most Viewed</AnalyticsHeader>
								<TopAnalystsTable dateRange={authorsDateRange} />
							</div>
						</PageGridItem>
					</PageContentBodyGrid>
				</PageGridItem>
				{/* RIGHT SIDE GRID */}
				<PageGridItem container col="8 / span 5" style={{ paddingTop: "1.5rem", paddingBottom: 0 }}>
					<PageContentBodyGrid style={{ rowGap: 48 }} paddingBottom={48} gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"}>
						<PageGridItem col="1 / span 6">
							<TopUsersTable dateRanges={dateRanges} />
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<AnalyticsToggleHeader value={dropdownTwoOption} values={dropdownTwoOptions} setValue={setDropdownTwoOption} dateRanges={dateRanges} selectedDateRange={deviceDateRange} setSelectedDateRange={setDeviceDateRange} />
							<BrowserChart value={dropdownTwoOption} dateRange={deviceDateRange} />
						</PageGridItem>
						<PageGridItem col="1 / span 6">
							<TopSearches dateRanges={dateRanges} />
						</PageGridItem>
					</PageContentBodyGrid>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
};

export default AnalyticsPage;
