import React from 'react';
import "./OverlayWrapper.scss";

const OverlayWrapper = ({ children, alignItems, onClick }) => {
	return (
		<div onClick={onClick} className="overlay-wrapper" style={{ alignItems: alignItems === "top" ? "start" : alignItems }}>
			{children}
		</div>
	);
}

export default OverlayWrapper;