import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview, useDebounceEffect } from '../../Utils/Utils';
import { ZoomIn, ZoomOut } from 'react-feather';
import FlexWrapper from '../../layouts/FlexWrapper';
import Button from '../Buttons/Button';
import Modal from './Modal';
import 'react-image-crop/dist/ReactCrop.css';
import './ImageEditModal.scss'
import Divider from '../Divider';
import { toastDanger } from '../popups/Toast';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: 'px',
				width: 200,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

const ImageEditModal = ({ handleClose, cancelButton, confirmButton, initialImg, onConfirm }) => {
	const [completedCrop, setCompletedCrop] = useState();
	const [imgSrc, setImgSrc] = useState('');
	const [rotate, setRotate] = useState(0);
	const [aspect, setAspect] = useState(1);
	const [scale, setScale] = useState(1);
	const [crop, setCrop] = useState();
	const [height, setHeight] = useState(600)
	const [width, setWidth] = useState(600)
	const hiddenFileInputRef = useRef(null);
	const previewCanvasRef = useRef(null);
	const imgRef = useRef(null);
	const drawImg = useRef()

	useEffect(() => {
		initialImg && onSelectFile(initialImg, true)
	}, [initialImg])

	useEffect(() => {
		if (imgRef && imgRef.current) {
			const ctx = imgRef.current.getContext('2d');
			let imageById = document.getElementById("unique-img-id");

			if (!imageById) {
				console.error("Image element not found!");
				return;
			}

			// Check if the image is loaded.
			if (!imageById.complete || !imageById.naturalHeight) {
				console.error("Image not fully loaded!");
				return;
			}

			// Clear canvas
			ctx.clearRect(0, 0, imgRef.current.width, imgRef.current.height);

			// Fill canvas with white background
			ctx.fillStyle = "white";
			ctx.fillRect(0, 0, imgRef.current.width, imgRef.current.height);

			// If the image's width and height are both smaller than the canvas dimensions, 
			// draw the image without scaling.
			if (imageById.naturalWidth <= imgRef.current.width && imageById.naturalHeight <= imgRef.current.height) {
				const offsetX = (imgRef.current.width - imageById.naturalWidth) / 2;
				const offsetY = (imgRef.current.height - imageById.naturalHeight) / 2;
				ctx.drawImage(imageById, offsetX, offsetY);
			} else {
				// Calculate scaling factor
				const scaleX = (imgRef.current.width / imageById.naturalWidth) * 0.8;
				const scaleY = (imgRef.current.height / imageById.naturalHeight) * 0.8;
				const scale = Math.min(scaleX, scaleY);

				// Calculate the dimensions of the scaled image
				const scaledWidth = imageById.naturalWidth * scale;
				const scaledHeight = imageById.naturalHeight * scale;

				// Calculate the offsets to center the scaled image on the canvas
				const offsetWidth = (imgRef.current.width - scaledWidth) / 2;
				const offsetHeight = (imgRef.current.height - scaledHeight) / 2;

				ctx.drawImage(imageById, 0, 0, imageById.naturalWidth, imageById.naturalHeight, offsetWidth, offsetHeight, scaledWidth, scaledHeight);
			}

			setCrop(centerAspectCrop(width, height, aspect));
		}
	}, [imgRef.current, imgSrc]);

	function onSelectFile(e, initialImg) {
		let files = initialImg ? e : e.target.files
		if (files.length > 0) {
			let file = files[0];
			if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/webp") {
				toastDanger("Invalid file type - please upload a .png, .jpeg, or .webp file");
				console.log(file, "invalid type");
				handleClose()
				return;
			}
			setCrop(undefined);
			const reader = new FileReader();
			reader.addEventListener('load', () => setImgSrc(reader.result || ''));
			reader.readAsDataURL(files[0]);
		}
	}

	function onDownloadCropClick() {
		if (!previewCanvasRef.current) {
			throw new Error('Crop canvas does not exist');
		}

		previewCanvasRef.current.toBlob((blob) => {
			if (!blob) {
				throw new Error('Failed to create blob');
			}
			onConfirm([blob])
		});
	}

	useDebounceEffect(
		async () => {
			let dataUrl = imgRef.current.toDataURL()
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current &&
				dataUrl
			) {
				let img = new Image();
				img.src = dataUrl;
				img.onload = () => {
					canvasPreview(
						img,
						previewCanvasRef.current,
						completedCrop,
						scale,
						rotate,
					);
				}
			}
		},
		100,
		[completedCrop, scale, rotate],
	);

	return (
		<Modal
			className="edit-image-modal"
			handleClose={handleClose}
			// cancelButton={{ ...cancelButton }}
			// confirmButton={{ label: "Confirm", onClick: () => onDownloadCropClick(), ...confirmButton }}
			// footer={<ButtonGroupWrapper className="pt-xxl" justifyContent="space-between">
			// 	<Button onClick={() => hiddenFileInputRef && hiddenFileInputRef.current && hiddenFileInputRef.current.click()}>Change Image</Button>
			// </ButtonGroupWrapper>}
		>
			<FlexWrapper className="ImageEditModal" gap="l" align="stretch">
				<div>
					<div className="Crop-Controls">
						<input ref={hiddenFileInputRef} type="file" accept="image/*" onChange={onSelectFile} style={{ position: "absolute", top: "0", visibility: "hidden" }} />
						<FlexWrapper justify="space-between">
							<Button onClick={() => hiddenFileInputRef && hiddenFileInputRef.current && hiddenFileInputRef.current.click()}>Change Image</Button>
							<FlexWrapper gap="m" justify="space-between" style={{ width: 200 }}>
								<FlexWrapper justify="center" className="zoom-icon-wrapper">
									<ZoomOut className="image-edit-zoom-button" onClick={() => scale > 0.3 && setScale(scale - 0.1)} />
								</FlexWrapper>
								<div className="image-edit-zoom-text">Zoom: {Number.parseFloat(scale * 100).toFixed(0)}%</div>
								<FlexWrapper justify="center" className="zoom-icon-wrapper">
									<ZoomIn className="image-edit-zoom-button" onClick={() => scale < 2 && setScale(scale + 0.1)} />
								</FlexWrapper>
								{/* <EyeDropper onChange={onColourPick} customProps={'salam'} />; */}
								{/* <Aperture className='image-edit-zoom-button' style={{ color: pickingColour ? "#0E8EFD" : "#ddd" }} onClick={() => pickColor()} /> */}
							</FlexWrapper>
						</FlexWrapper>
					</div>
					<div className="image-container mt-l">
						{!!imgSrc && (
							<div style={{ position: "relative" }}>
								<div>
									<img
										ref={drawImg}
										id="unique-img-id"
										src={imgSrc}
										style={{ position: "absolute", left: "0px", top: "0px", transform: "translateY(-200vh)", maxWidth: "600px", maxHeight: "600px" }}
									/>
								</div>
								<ReactCrop
									crop={crop}
									onChange={(pxCrop, percentCrop) => {
										setCrop(pxCrop);
									}}
									onComplete={(c) => {
										setCompletedCrop(c);
									}}
									aspect={aspect}
									minWidth={200}
									minHeight={200}
									className="react-crop-element"
									keepSelection
								>
									<canvas
										id={"cropper-canvas"}
										ref={imgRef}
										height={height}
										width={width}
										style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, maxWidth: "1000px", maxHeight: "1000px" }}
									/>
								</ReactCrop>

								{/* {pickingColour && <div className='colour-picker-active' onClick={(e) => {e.stopPropagation()}}></div>} */}
							</div>
						)}
					</div>
				</div>
				<Divider vertical height="auto" />
				<FlexWrapper direction="column" gap="l" align="flex-start">
					<p className="image-edit-preview-header mt-s">Preview</p>
					<canvas className="image-edit-preview" ref={previewCanvasRef} style={{ objectFit: "contain", width: 200, height: 200 }} />
					<FlexWrapper gap="s" align="flex-start">
						<Button onClick={handleClose} variant="secondary">Cancel</Button>
						<Button onClick={() => onDownloadCropClick()} variant="positive">Confirm</Button>
					</FlexWrapper>
				</FlexWrapper>
			</FlexWrapper>
		</Modal>
	);
}


export default ImageEditModal;