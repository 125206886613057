import React, { useEffect, useState } from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import { useRemote, useStorage, minutesIntoReadableFormat, minutesIntoReadableFormatWithSeconds } from '../../../Utils/Utils';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { Eye, Mail, Smartphone, Monitor, Clock, BookOpen, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { format } from 'date-fns'
import LoadingIcon from '../../Shared/LoadingIcon';
import PageContentToggle from '../../../components/Menus/PageContentToggle'
import Tooltip from '../../../components/popups/Tooltip';


/**
 * Displays non-aggregated analytics for a single note
 */
const AnalyticsTickerResearchViews = ({ datatita }) => {

    const remote = useRemote()
    const storage = useStorage()

    // state
    const [loading, setLoading] = useState(true);
    const [currentView, setCurrentView] = useState("user");
    const [currentFilter, setCurrentFilter] = useState("");
    const [sortingColumn, setSortingColumn] = useState({ column: "User", ascending: true })

    const [ticker, setTicker] = useState(null)
    const [group, setGroup] = useState(null)
    const [groupingName, setGroupingName] = useState(null)
    const [summary, setSummary] = useState(null)

    // per user tab states
    const [perUserList, setPerUserList] = useState([])
    const [perUserFilteredList, setPerUserFilteredList] = useState([])
    const [perUserFilteredData, setPerUserFilteredData] = useState([])
    const [perUserDataDisplaying, setPerUserDataDisplaying] = useState([])
    const [hasUserSearchTerm, setHasUserSearchTerm] = useState(false);

    // per page tab states
    const [perPageList, setPerPageList] = useState([])
    const [perPageDataDisplaying, setPerPageDataDisplaying] = useState([])
    const [perPageFilteredData, setPerPageFilteredData] = useState([])
    const [hasPageSearchTerm, setHasPageSearchTerm] = useState(false);

    // per downloads tab states
    const [perDownloadsList, setPerDownloadsList] = useState([])
    const [perDownloadsFilteredList, setPerDownloadsFilteredList] = useState([])
    const [perDownloadsFilteredData, setPerDownloadsFilteredData] = useState([])
    const [perDownloadsDataDisplaying, setPerDownloadsDataDisplaying] = useState([])
    const [hasDownloadSearchTerm, setHasDownloadSearchTerm] = useState(false);

    // total metrics
    const [averageDuration, setAverageDuration] = useState("-") //  getAvgPageDuration()
    const [topPageDuration, setTopPageDuration] = useState("-") // getTopPageDuration()
    const pdfDownloads = summary && (summary.emailPdfDownloads + summary.portalPdfDownloads)
    const modelDownloads = summary && summary.dataDownloads
    const emailRead = summary && summary.emailsRead
    const portalViews = summary && summary.portalViews

    // banner data
    const type = datatita.match.params.tickerId ? "ticker" : "group"
    const typeId = datatita.match.params.tickerId || datatita.match.params.groupId
    const documentId = datatita.match.params.researchId


    useEffect(() => {
        // get publication's summary
        const getPublications = (path) => {
            storage.getOrFetch(path).then((fetchedPublications) => {
                if (fetchedPublications) {
                    const publication = fetchedPublications.find(p => p.noteId === +documentId)
                    if (publication) setSummary(publication)
                }
            })
        }

        if (type === 'group') {
            remote.get("/crm/groupings/-1/" + datatita.match.params.groupId).then((fetchedGroup) => {
                const path = `/analytics/group/${typeId}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}`
                getPublications(path)
                setGroup(fetchedGroup)
                setGroupingName(fetchedGroup.groupingDetails.groupName)

            });

        } else {
            storage.getOrFetch(`/crm/tickers`).then((fetchedTickers) => {
                const selectedTicker = fetchedTickers && fetchedTickers.length > 0 && fetchedTickers.find(t => t.id === +typeId)
                if (selectedTicker) setTicker(selectedTicker)
                const path = `/analytics/ticker/${typeId}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}`
                getPublications(path)
            })
        }

        Promise.all([
            remote.get(`/analytics/ticker/${typeId}/research/${documentId}/per-user`),
            remote.get(`/analytics/ticker/${typeId}/research/${documentId}/per-page`),
            remote.get(`/analytics/ticker/${typeId}/research/${documentId}/downloads`)

        ]).then(([fetchedByUsers, fetchedByPage, fetchedByDownloads]) => {
            if (fetchedByUsers && fetchedByUsers.length > 0) {
                setAverageDuration(getAvgPageDuration(fetchedByUsers))
                setPerUserList(fetchedByUsers)
            } else setPerUserList([])

            if (fetchedByPage && fetchedByPage.length > 0) {
                setTopPageDuration(getTopPageDuration(fetchedByPage))
                setPerPageList(fetchedByPage)
            } else setPerPageList([])

            setPerDownloadsList(fetchedByDownloads && fetchedByDownloads.length > 0 ? fetchedByDownloads : [])
            setLoading(false)

        }).catch((error) => {
            console.log("Error received", error)
            setPerUserList([])
            setPerPageList([])
            setPerDownloadsList([])
            setLoading(false)
        })

    }, [])


    // data filters
    function filterOnclick(value) {
        return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
    }

    useEffect(() => {
        if (currentView === "user") {
            let newList = perUserList
            if (currentFilter === "read") newList = perUserList.filter(p => p.viewedIn === "email")
            if (currentFilter === "portal") newList = perUserList.filter(p => p.viewedIn === "portal")
            setPerUserFilteredList(newList)

        } else if (currentView === "downloads") {
            let newList = perDownloadsList
            if (currentFilter === "pdf") newList = perDownloadsList.filter(p => p.file === "pdf")
            if (currentFilter === "model") newList = perDownloadsList.filter(p => p.file === "xls")
            setPerDownloadsFilteredList(newList)
        }

    }, [currentView, currentFilter, perUserList, perDownloadsList])


    function getTopPageDuration(list) {
        const sortedList = list
        return "Page " + sortedList.sort((a, b) => b.totalDuration - a.totalDuration)[0].page
    }

    function getAvgPageDuration(list) {
        const totalTime = list.reduce((a, c) => a + c.minutes, 0)
        const avgTime = totalTime / list.length
        // console.log("totalTime", totalTime, "length", list.length, "avgTime", avgTime)
        return minutesIntoReadableFormatWithSeconds(avgTime)
    }

    // Sorting
    useEffect(() => {
        sortTable(null)
    }, [currentView, perUserFilteredList, perDownloadsFilteredList, perUserFilteredData, perPageFilteredData, perDownloadsFilteredData])

    const sortTable = (column) => {
        if (column === null) {
            if (currentView === "user") setPerUserDataDisplaying(hasUserSearchTerm ? perUserFilteredData : perUserFilteredList)
            if (currentView === "page") setPerPageDataDisplaying(hasPageSearchTerm ? perPageFilteredData : perPageList)
            if (currentView === "downloads") setPerDownloadsDataDisplaying(hasDownloadSearchTerm ? perDownloadsFilteredData : perDownloadsFilteredList)
            return
        }

        let tableData = currentView === "user" ? hasUserSearchTerm ? perUserFilteredData : perUserFilteredList :
            currentView === "page" ? hasPageSearchTerm ? perPageFilteredData : perPageList :
                currentView === "downloads" && hasDownloadSearchTerm ? perDownloadsFilteredData : perDownloadsFilteredList

        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true

        switch (column) {
            case "User":
                tableData.sort((a, b) => {
                    const user1 = a.preferredName ? a.preferredName : a.firstName
                    const user2 = b.preferredName ? b.preferredName : b.firstName
                    return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
                })
                break;
            case "Email":
                tableData.sort((a, b) => ascending ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email))
                break;
            case "Minutes":
                tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
                break;
            case "ViewedIn":
                tableData.sort((a, b) => ascending ? a.viewedIn.localeCompare(b.viewedIn) : b.viewedIn.localeCompare(a.viewedIn))
            case "Device":
                tableData.sort((a, b) => {
                    const user1 = a.device ? a.device : "-"
                    const user2 = b.device ? b.device : "-"
                    return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
                })
                break;
            case "Page":
                tableData.sort((a, b) => ascending ? a.page - b.page : b.page - a.page)
                break;
            case "AvgDuration":
                tableData.sort((a, b) => ascending ? a.avgDuration - b.avgDuration : b.avgDuration - a.avgDuration)
                break;
            case "TotalDuration":
                tableData.sort((a, b) => ascending ? a.totalDuration - b.totalDuration : b.totalDuration - a.totalDuration)
                break;
            case "TopViewer":
                tableData.sort((a, b) => ascending ? a.topViewer.localeCompare(b.topViewer) : b.topViewer.localeCompare(a.topViewer))
                break;
            case "Time/Date":
                tableData.sort((a, b) => {
                    const user1 = a.timeDate ? format(new Date(a.timeDate), "yyyy/MM/dd hh:mmaaa") : '-'
                    const user2 = b.timeDate ? format(new Date(b.timeDate), "yyyy/MM/dd hh:mmaaa") : '-'
                    return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
                })
                break;
            case "File":
                tableData.sort((a, b) => ascending ? a.file.localeCompare(b.file) : b.file.localeCompare(a.file))
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        currentView === "user" ? setPerUserDataDisplaying(tableData) : currentView === "page" ? setPerPageDataDisplaying(tableData) : setPerDownloadsDataDisplaying(tableData)
    }

    // Tab selecting
    function changeTab(string) {
        setCurrentFilter("")
        if (string === "user") {
            setCurrentView("user")
            setSortingColumn({ column: "User", ascending: true })
        }
        if (string === "page") {
            setCurrentView("page")
            setSortingColumn({ column: "TotalDuration", ascending: false })
        }
        if (string === "downloads") {
            setCurrentView("downloads")
            setSortingColumn({ column: "Time/Date", ascending: false })
        }
    }

    // Per User table data
    const perUserColWidths = ["300px", "280px", "280px", "100px", "100px", "100px"];
    const perUserHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Time/Date")} >
                    <div className={sortingColumn.column === "Time/Date" ? "font-weight-semi-bold" : ""}>Time/Date</div>
                    {sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("User")} >
                    <div className={sortingColumn.column === "User" ? "font-weight-semi-bold" : ""}>User</div>
                    {sortingColumn && sortingColumn.column === "User" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "User" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Email")} >
                    <div className={sortingColumn.column === "Email" ? "font-weight-semi-bold" : ""}>Email</div>
                    {sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <Tooltip label="Minutes">
                    <div className='sorting-header right' style={{ paddingRight: 36 }} onClick={() => sortTable("Minutes")}>
                        <Clock className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-right"
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("ViewedIn")} >
                    <div className={sortingColumn.column === "ViewedIn" ? "font-weight-semi-bold" : ""}>Viewed In</div>
                    {sortingColumn && sortingColumn.column === "ViewedIn" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "ViewedIn" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("Device")} >
                    <div className={sortingColumn.column === "Device" ? "font-weight-semi-bold" : ""}>Device</div>
                    {sortingColumn && sortingColumn.column === "Device" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Device" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            ),
            className: "text-align-center"
        }
    ];

    const perUserDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { firstName, lastName, preferredName, email, minutes, viewedIn, device, timeDate } = datum       // change once the endpoint is implemented
        const user = (preferredName || firstName) + " " + lastName
        return (
            <tr key={idx}>
                <td>{timeDate ? format(new Date(timeDate), "hh:mmaaa dd/MM/yyyy") : "-"}</td>
                <td>{user || "-"}</td>
                <td>{email || "-"}</td>
                <td className="text-align-right" style={{ paddingRight: 45 }}>{minutesIntoReadableFormat(minutes) || "-"}</td>
                <td className="text-align-center">{viewedIn === "email" ?
                    <Mail size={24} style={{ transform: "translateY(-1px)" }} color="#6C7EE0" /> : viewedIn === "portal" ?
                        <Eye size={24} style={{ transform: "translateY(-1px)" }} color="#0E8EFD" /> : "-"
                }</td>
                <td className="text-align-center">{device === "mobile" ?
                    <Smartphone size={24} style={{ transform: "translateY(-1px)" }} color="#0A70C9" /> : device === "desktop" ?
                        <Monitor size={24} style={{ transform: "translateY(-1px)" }} color="#0E8EFD" /> : "-"
                }</td>
            </tr>
        );
    });

    // Per Page table data
    const perPageColWidths = ["140px", "260px", "260px", "460px"];
    const perPageHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Page")} >
                    <div className={sortingColumn.column === "Page" ? "font-weight-semi-bold" : ""}>Page</div>
                    {sortingColumn && sortingColumn.column === "Page" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "User" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header right' style={{ paddingRight: 11 }} onClick={() => sortTable("AvgDuration")}>
                    <Clock className="colour-text-primary" size={16} />
                    <div className={sortingColumn.column === "AvgDuration" ? "font-weight-semi-bold" : ""}>Avg Duration</div>
                    {sortingColumn && sortingColumn.column === "AvgDuration" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "AvgDuration" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header right' style={{ paddingRight: 51 }} onClick={() => sortTable("TotalDuration")}>
                    <Clock className="colour-text-primary" size={16} />
                    <div className={sortingColumn.column === "TotalDuration" ? "font-weight-semi-bold" : ""}>Total Duration</div>
                    {sortingColumn && sortingColumn.column === "TotalDuration" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "TotalDuration" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' style={{ paddingLeft: 56 }} onClick={() => sortTable("TopViewer")}>
                    <Eye className="colour-text-primary" size={16} />
                    <div className={sortingColumn.column === "TopViewer" ? "font-weight-semi-bold" : ""}>Top Viewer</div>
                    {sortingColumn && sortingColumn.column === "TopViewer" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "TopViewer" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ];

    const perPageDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { page, avgDuration, totalDuration, topViewer } = datum       // change once the endpoint is implemented

        return (
            <tr key={idx}>
                <td>{page ? <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}><BookOpen size={16} className="colour-text-primary" />{"Page " + page}</div> : "-"}</td>
                <td className="text-align-right" style={{ paddingRight: 20 }}>{minutesIntoReadableFormatWithSeconds(avgDuration) || "-"}</td>
                <td className="text-align-right" style={{ paddingRight: 60 }}>{minutesIntoReadableFormat(totalDuration) || "-"}</td>
                <td style={{ paddingLeft: 60 }}>{topViewer || "-"}</td>
            </tr>
        );
    });

    // Per Downlods table data
    const perDownlodsColWidths = ["400px", "300px", "200px", "200px"];
    const perDownloadsHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("User")} >
                    <div className={sortingColumn.column === "User" ? "font-weight-semi-bold" : ""}>User</div>
                    {sortingColumn && sortingColumn.column === "User" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "User" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Time/Date")} >
                    <div className={sortingColumn.column === "Time/Date" ? "font-weight-semi-bold" : ""}>Time/Date</div>
                    {sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("Device")} >
                    <div className={sortingColumn.column === "Device" ? "font-weight-semi-bold" : ""}>Device</div>
                    {sortingColumn && sortingColumn.column === "Device" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Device" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("File")} >
                    <div className={sortingColumn.column === "File" ? "font-weight-semi-bold" : ""}>File</div>
                    {sortingColumn && sortingColumn.column === "File" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "File" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ];

    const perDownloadsDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { firstName, lastName, preferredName, timeDate, device, file } = datum       // change once the endpoint is implemented
        const user = (preferredName || firstName) + " " + lastName
        return (
            <tr key={idx}>
                <td>{user || "-"}</td>
                <td>{timeDate ? format(new Date(timeDate), "hh:mmaaa dd/MM/yyyy") : "-"}</td>
                <td className="text-align-center">{device === "mobile" ?
                    <Smartphone size={24} style={{ transform: "translateY(-1px)" }} color="#0A70C9" /> : device === "desktop" ?
                        <Monitor size={24} style={{ transform: "translateY(-1px)" }} color="#0E8EFD" /> : "-"
                }</td>
                <td className="text-align-center">{file === "pdf" ?
                    <PdfIcon size={24} style={{ transform: "translateY(-1px)" }} noHover /> : file === "xls" ?
                        <XlsIcon size={24} style={{ transform: "translateY(-1px)" }} noHover /> : "-"
                }</td>
            </tr>
        );
    });

    return (
        <Page noBanner showScrollbar>
            {/* Modal */}
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="xl">
                        <FlexWrapper gap="s" direction="row">
                            {type === "ticker" ?
                                ticker && <TickerSectorItem image={`/api/public/images/ticker/${ticker.id}.png`} imageSize={40} country={ticker.country} filename={ticker.ric} /> :
                                group && groupingName && <TickerSectorItem image={`/api/public/images/group/${group.id}.png`} imageSize={40} />
                            }
                            {summary ?
                                <FlexWrapper direction="column" align="flex-start" gap="s">
                                    <Tooltip label={summary.title}>
                                        <div style={{ maxWidth: 200, fontSize: 20 }} className="font-weight-medium truncate">{summary.title}</div>
                                    </Tooltip>
                                    <FlexWrapper gap="s" direction="row" style={{ margin: 0, padding: 0 }}>
                                        {type === "ticker" && ticker && <div className='font-size-body-s colour-text-neutral-dark-40'>{ticker.ric}</div>}
                                        {type === "ticker" && ticker && <Divider vertical height={14} />}
                                        <div className='font-size-body-s colour-text-neutral-dark-40'>{/*format(new Date(distribution.date), "dd/MM/yyyy") + " " +*/ summary.analystName}</div>
                                    </FlexWrapper>
                                </FlexWrapper> :
                                <LoadingIcon size={32} />
                            }
                        </FlexWrapper>
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            onClick={() => setCurrentFilter("")}
                            active={currentFilter === "minutes"}
                            inactive={currentFilter !== "" && currentFilter !== "minutes"}
                            label="Avg Duration"
                            status="minutes"
                            icon={<Clock className='colour-text-primary' />}
                            count={averageDuration}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => setCurrentFilter("")}
                            active={currentFilter === "bookOpen"}
                            inactive={currentFilter !== "" && currentFilter !== "bookOpen"}
                            label="Top Page Duration"
                            status="bookOpen"
                            icon={<BookOpen color='#0D8EFD' />}
                            count={topPageDuration}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => currentView !== "user" ? setCurrentFilter("") : filterOnclick("read")}
                            active={currentFilter === "read"}
                            inactive={currentFilter !== "" && currentFilter !== "read"}
                            label="Email Views"
                            status="read"
                            icon={<Mail />}
                            count={emailRead}
                            style={{ cursor: currentView !== "user" ? "default" : "pointer" }}
                        />
                        <Tooltip label="Unique Views">
                            <AnalyticsMetricFilter
                                onClick={() => currentView !== "user" ? setCurrentFilter("") : filterOnclick("portal")}
                                active={currentFilter === "portal"}
                                inactive={currentFilter !== "" && currentFilter !== "portal"}
                                label="Portal Views"
                                icon={<Eye />}
                                count={portalViews}
                                style={{ cursor: currentView !== "user" ? "default" : "pointer" }}
                            />
                        </Tooltip>
                        <Tooltip label="Unique PDF Downloads">
                            <AnalyticsMetricFilter
                                onClick={() => currentView !== "downloads" ? setCurrentFilter("") : filterOnclick("pdf")}
                                active={currentFilter === "pdf"}
                                inactive={currentFilter !== "" && currentFilter !== "pdf"}
                                label="PDF Downloads"
                                icon={<PdfIcon noHover />}
                                count={pdfDownloads}
                                style={{ cursor: currentView !== "downloads" ? "default" : "pointer" }}
                            />
                        </Tooltip>
                        <Tooltip label="Unique Model Downloads">
                            <AnalyticsMetricFilter
                                onClick={() => currentView !== "downloads" ? setCurrentFilter("") : filterOnclick("model")}
                                active={currentFilter === "model"}
                                inactive={currentFilter !== "" && currentFilter !== "model"}
                                label="Model Downloads"
                                icon={<XlsIcon noHover />}
                                count={modelDownloads}
                                style={{ cursor: currentView !== "downloads" ? "default" : "pointer" }}
                            />
                        </Tooltip>
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="m">
                        <PageContentToggle labels={[
                            { label: "Per User", active: currentView === "user", onClick: () => changeTab("user") /*setCurrentView("research") */ },
                            { label: "Per Page", active: currentView === "page", onClick: () => changeTab("page") /*setCurrentView("watching")*/ },
                            { label: "Downloads", active: currentView === "downloads", onClick: () => changeTab("downloads") /*setCurrentView("watching")*/ },
                        ]} />
                        <Divider vertical height={24} />
                        <SearchTermFilter
                            size="small"
                            width="l"
                            placeholder={`Search`}
                            isClearable
                            dataSet={currentView === "user" ? perUserFilteredList : currentView === "page" ? perPageList : perDownloadsFilteredList}
                            setFilteredData={currentView === "user" ? setPerUserFilteredData : currentView === "page" ? setPerPageFilteredData : setPerDownloadsFilteredData}
                            path={currentView === "user" ? ["firstName", "lastName", "preferredName", "email"] : currentView === "page" ? ["topViewer", "page"] : ["firstName", "lastName", "preferredName"]}
                            hasSearchTerm={(boolean) => currentView === "user" ? setHasUserSearchTerm(boolean) : currentView === "page" ? setHasPageSearchTerm(boolean) : setHasDownloadSearchTerm(boolean)}
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={currentView === "user" ? perUserColWidths : currentView === "page" ? perPageColWidths : perDownlodsColWidths}
                        headers={currentView === "user" ? perUserHeaders : currentView === "page" ? perPageHeaders : perDownloadsHeaders}
                        dataMap={currentView === "user" ? perUserDataMap(perUserDataDisplaying) : currentView === "page" ? perPageDataMap(perPageDataDisplaying) : perDownloadsDataMap(perDownloadsDataDisplaying)}
                        noResults={{ message: "No users found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(AnalyticsTickerResearchViews);