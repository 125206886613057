import React from 'react';
import { User } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import PhoneNumberDetailField from '../../Shared/DetailSection/PhoneNumberDetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Checkbox from '../../../components/Checkbox';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';


const UserGeneralDetails = ({ editMode, user, editUser, setValid, countries, userFirstNameTouched, setUserFirstNameTouched, userLastNameTouched, setUserLastNameTouched }) => {

	const countryOptions = countries.map(country => { return { label: country.name, value: country.name } })

	const getPhoneNumber = (phone) => {
		return `${phone.idd ? phone.idd : ""} ${phone.number ? phone.number : ""}`
	}

	return (
		<React.Fragment>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="General Details" icon={<User />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					bold
					editMode={editMode} autoFocus
					onChange={(e) => {
						setUserFirstNameTouched(true)
						editUser({ ...user, firstName: e.target.value })
					}}
					onClick={() => setUserFirstNameTouched(true)}
					label={editMode ? "First Name*" : "First Name"}
					value={user.firstName ? user.firstName : ""}
					invalid={userFirstNameTouched && (user.firstName === "" || !user.firstName) ? true : false}
					helperText={userFirstNameTouched && (user.firstName === "" || !user.firstName) ? "User first name is required" : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					bold
					editMode={editMode}
					onClick={() => setUserLastNameTouched(true)}
					onChange={(e) => {
						setUserLastNameTouched(true)
						editUser({ ...user, lastName: e.target.value })
					}}
					label={editMode ? "Last Name*" : "Last Name"}
					value={user.lastName ? user.lastName : ""}
					invalid={userLastNameTouched && (user.lastName === "" || !user.lastName) ? true : false}
					helperText={userLastNameTouched && (user.lastName === "" || !user.lastName) ? "User last name is required" : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, preferredName: e.target.value })}

					label="Preferred Name"
					value={user.preferredName ? user.preferredName : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, title: e.target.value })}

					label="Title"
					value={user.title ? user.title : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, role: e.target.value })}

					label="Role"
					value={user.role ? user.role : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, salutation: e.target.value })}

					label="Salutation"
					value={user.salutation ? user.salutation : ""}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					onChange={(e) => editUser({ ...user, email: e.target.value })}
					label="Email"
					value={user.email ? user.email : ""}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				{editMode
					? <PhoneNumberDetailField
						onChange={(e) => editUser({ ...user, phone: e })}
						value={user.phone}
						label="Mobile"
						setValid={setValid}
					/>
					: <DetailField
						editMode={editMode}
						onChange={(e) => editUser({ ...user, phone: e.target.value })}
						label="Mobile"
						value={user.phone ? getPhoneNumber(user.phone) : ""}
					/>
				}
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<SelectDetailField
					editMode={editMode}
					label="Country of Residence"
					placeholder="Country of Residence"
					value={user.location ? { value: user.location, label: user.location } : null}
					options={countryOptions}
					onChange={(option) => editUser({ ...user, location: option.value })}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 6" alignItems="end">
				<Checkbox checked={user.emailSuppressed} onChange={(e) => editUser({ ...user, electronicDelivery: e.currentTarget.checked })} disabled={!editMode} label="User has opted out from all correspondence" tooltip={user.emailSuppressed ? "User has clicked the unsubscribe link" : ""} />
			</PageGridItem>
		</React.Fragment>
	);
}

export default UserGeneralDetails;