import React, { useEffect, useRef, useState } from 'react';
import { useRemote, useStorage } from '../../../Utils/Utils';
import { Plus } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Checkbox from '../../../components/Checkbox';
import CheckboxGroup, { CheckboxGroupWrapper } from '../../../components/Input/CheckboxGroup';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import TextInput from '../../../components/Input/TextInput'
import "./RolePermissionsList.scss";
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import ConfirmActionModal from '../../../components/Modals/ConfirmActionModal';

const RolePermissionsList = () => {

	const [summaryRows, setSummaryRows] = useState([])
	const [originalSummaryRows, setOriginalSummaryRows] = useState([])
	const [roleBeingEdited, setRoleBeingEdited] = useState(null)
	const [rowsExpanded, setRowsExpanded] = useState([])
	const [users, setUsers] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(null);

	const remote = useRemote();
	const storage = useStorage();

	const NewRoleRef = useRef(null)
	const NewRoleButtonRef = useRef(null)

	useEffect(() => {
		remote.get("/roles/list-summary")
			.then((res) => {
				setSummaryRows(res);
				setOriginalSummaryRows(res);
				setRowsExpanded(res.map((r) => { return { roleId: r.id, expanded: false } }))
			});

		storage.getOrFetch("/crm/users").then((res) => {
			setUsers(res);
		});
	}, [])



	// Table widths and headers
	const colWidths = [60 + "%", 20 + "%", 20 + "%"];
	const headers = ["Role", "Users", ""];

	// Handle feature checkbox click
	const handleFeatureClick = (featureId, role, permitted) => {
		let newSummaryRows = summaryRows.map((row) => {
			if (row.id === role) {
				return {
					...row, featureGroups: row.featureGroups.map((group) => {
						return {
							...group, features: group.features.map((feature) => {
								if (feature.featureId === featureId) {
									return { ...feature, permitted: permitted };
								}
								return feature;
							})
						};
					})
				};
			}
			return row;
		});
		setSummaryRows(newSummaryRows);
	}

	const handleFeatureGroupMasterClick = (groupId, role, permitted) => {
		let newSummaryRows = summaryRows.map((row) => {
			if (row.id === role) {
				return {
					...row, featureGroups: row.featureGroups.map((group) => {
						if (group.id === groupId) {
							return {
								...group, features: group.features.map((feature) => {
									return {
										...feature, permitted: permitted
									};
								})
							};
						}
						return group;
					})
				};
			}
			return row;
		});
		setSummaryRows(newSummaryRows);
	}

	const scrollToNewRoleButton = () => {
		setTimeout(() => {
			NewRoleButtonRef.current && NewRoleButtonRef.current.focus()
			NewRoleButtonRef.current && NewRoleButtonRef.current.scrollIntoView({ behavior: "smooth", block: "center" })

		}, 500)
	}

	const handleSaveChanges = (role) => {
		remote.put("/roles/" + role.id, role).then((res) => {
			toastSuccess("Permissions Updated");
			setRoleBeingEdited(null);
			setSummaryRows(res);
			setOriginalSummaryRows(res);
		});
		scrollToNewRoleButton()
	}

	const expandContent = (featureGroups, roleId) => {
		const isBeingEdited = roleBeingEdited === roleId;
		return <CheckboxGroupWrapper align="start" gap="xxl">
			{featureGroups && featureGroups.map((group, idx) => {
				const hasFullFeatureAccess = group.features.every((feature) => feature.permitted);
				let checkBoxMappablefeatures = group.features.map((feature, idx) => {
					return {
						label: feature.featureName,
						checked: feature.permitted,
						onClick: () => handleFeatureClick(feature.featureId, roleId, !feature.permitted),
						disabled: !isBeingEdited
					}
				})
				return <CheckboxGroup key={group.id + idx + roleId} list={checkBoxMappablefeatures} masterCheckbox={<Checkbox disabled={!isBeingEdited} label={group.name} checked={hasFullFeatureAccess} onChange={() => isBeingEdited && handleFeatureGroupMasterClick(group.id, roleId, !hasFullFeatureAccess)} />} />
			})}
		</CheckboxGroupWrapper>
	};

	const handleExpandableRowClick = (role, expanded) => {
		let newRowsExpanded = rowsExpanded.map((row) => {
			if (row.roleId === role) {
				if (expanded === true || expanded === false) {
					row.expanded = expanded;
				} else {
					row.expanded = !row.expanded;
				}
			}
			return row;
		});

		setRowsExpanded(newRowsExpanded);
	}

	const cancelRoleEdit = (role) => {
		let newSummaryRows = summaryRows.map((row) => {
			if (row.id === role) {
				if (originalSummaryRows.find((r) => r.id === role)) {
					row.featureGroups = originalSummaryRows.find((r) => r.id === role).featureGroups;
				}
			}
			return row;
		});
		setSummaryRows(newSummaryRows);
		setRoleBeingEdited(null);
		scrollToNewRoleButton()
	}

	const editRoleName = (role, value) => {
		let newSummaryRows = summaryRows.map((row) => {
			if (row.id === role.id) {
				row.role = value;
			}
			return row;
		});
		setSummaryRows(newSummaryRows);
	}

	const handleDeleteRoleClick = (e, role) => {
		e.stopPropagation()
		setConfirmDelete(role)
	}

	const deleteRole = (role) => {
		remote.delete(`/roles/${role.id}`).then((updatedSummary) => {
			toastDanger("Role deleted " + role.role)
			setSummaryRows(updatedSummary)
		});
	}

	const duplicateRole = (e, role) => {
		e.stopPropagation()
		remote.post(`/roles/${role.id}/duplicate`).then((updatedSummary) => {
			toastSuccess("Role duplicated " + role.role)
			setSummaryRows(updatedSummary)
			setOriginalSummaryRows(updatedSummary)
		});
	}
	const handleCreateNewRole = () => {
		remote.post("/roles/").then((updatedSummary) => {
			let newRow = updatedSummary.find((r) => r.added === true)
			toastSuccess("Role created " + newRow.role)
			setSummaryRows(updatedSummary)
			setOriginalSummaryRows(updatedSummary)

			setRowsExpanded([...rowsExpanded, { roleId: newRow.id, expanded: true }])
			setRoleBeingEdited(newRow.id)
			scrollToNewRoleButton()
			setTimeout(() => {
				NewRoleRef.current && NewRoleRef.current.focus()
				NewRoleRef.current && NewRoleRef.current.scrollIntoView({ behavior: "smooth", block: "center" })

			}, 500)

		});
	}

	// Table map
	const dataMap = () => summaryRows && summaryRows.map((role, idx) => {
		let permissionCount = role.featureGroups.reduce((acc, group) => {
			return acc + group.features.reduce((acc, feature) => {
				return parseInt(acc) + (feature.permitted ? 1 : 0);
			}, 0);
		}, 0);

		let userCount = users && users.length > 0 ? users.filter((user) => user.roleId === role.id).length : 0

		const isBeingEdited = roleBeingEdited === role.id;
		const hasDuplicateName = summaryRows.filter((r) => r.role === role.role).length > 1;
		const isNewRole = role.added;

		return (
			<ExpandableTableRow key={idx} expandRowClassname="role-permission-row" expandContent={expandContent(role.featureGroups, role.id)} manuallySetExpandActive={isBeingEdited ? true : rowsExpanded && rowsExpanded[idx] && rowsExpanded[idx].expanded} onClick={() => handleExpandableRowClick(role.id)}>
				<td className='roles-permissions-title'>{(isBeingEdited)
					? <TextInput ref={isNewRole ? NewRoleRef : null} value={role.role} onChange={(e) => { editRoleName(role, e.target.value) }} />
					: `${role.role} ${permissionCount ? "(" + permissionCount + ")" : "(0)"}`
				}</td>
				<td>{userCount}</td>
				{/* <td>{permissionCount}</td> */}
				<TableActionIconGroup
					download={{ hide: true }}
					edit={{ hide: isBeingEdited, onClick: (e) => { e.stopPropagation(); handleExpandableRowClick(role.id, true); setRoleBeingEdited(role.id) } }}
					duplicate={{ hide: isBeingEdited, onClick: (e) => duplicateRole(e, role) }}
					remove={{ hide: isBeingEdited, onClick: (e) => handleDeleteRoleClick(e, role) }}
					save={{ hide: !isBeingEdited, disabled: hasDuplicateName, onClick: (e) => { e.stopPropagation(); handleSaveChanges(role) } }}
					cancel={{ hide: !isBeingEdited, onClick: (e) => { e.stopPropagation(); cancelRoleEdit(role.id) } }}
				/>
			</ExpandableTableRow>
		);
	});

	return (
		<Page fixedBanner>
			{confirmDelete && <ConfirmActionModal
				header="Are you sure you want to delete this role?"
				message="This action cannot be undone"
				handleClose={() => setConfirmDelete(null)}
				cancelButton={{ onClick: () => setConfirmDelete(null) }}
				confirmButton={{ variant: "danger", label: "Delete Role", onClick: () => { deleteRole(confirmDelete); setConfirmDelete(null) } }}
			/>}
			<PageContentBanner divider>
				<Button buttonRef={NewRoleButtonRef} onClick={handleCreateNewRole} disabled={!!roleBeingEdited} icon={<Plus />}>New Role</Button>
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12" >
					<Table minWidth={600} colWidths={colWidths} headers={headers} dataMap={dataMap()} />
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	)
}

export default RolePermissionsList;