import React from 'react';
import { AlertTriangle } from 'react-feather';
import Page, { PageContentBodyGrid } from './Shared/Page/Page';
import PageGridItem from './Shared/Page/PageGridItem';
import "./NoPermissionsPage.scss";

const NoPermissionsPage = ({errorHeader, errorMessage}) => {
	return (
		<Page noBanner>
			<PageContentBodyGrid id="error-message">
				<PageGridItem colSpan="12">
					<div className="error-message-wrapper">
						<div className="error-header">
							{/* <AlertTriangle /> */}
							<h1>{errorHeader ? errorHeader : "Welcome"}</h1>
						</div>
						<div className="error-message">
							<p>{errorMessage ? errorMessage : "You currently have no permissions to view this site. Please contact your software support."}</p>
						</div>
					</div>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}
export default NoPermissionsPage;