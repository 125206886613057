import React, { useEffect, useState } from 'react';
import IconLink from '../Menus/IconLink';
import withRouteChange from '@threeskye/route-change';
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import { Edit, Settings, LogOut, BookOpen, User, BarChart2, Edit3, UserCheck } from 'react-feather';
import SideBarWrapper, { SideBarIconGroup } from './SideBarWrapper';
import { withRemote } from '@threeskye/global';
import { useStorage, getSidebarRouteByPermissions } from '../../Utils/Utils';

const SideBarLeft = ({ location, changeRoute, remote }) => {
	const [draftsCounter, setDraftsCounter] = useState(0)
	const [peerCounter, setPeerCounter] = useState(0)
	const [requestsCounter, setRequestsCounter] = useState(0)
	const [me, setMe] = useState(null);

	const checkRoute = (route) => {
		return route === "/" && location.pathname.includes("delivery") ? true :
			route === "/" ? location.pathname === route :
				location.pathname.startsWith(route) && location.pathname.includes("delivery") ? false :
					location.pathname.startsWith(route)
	}

	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("/crm/me").then((me) => {
			setMe(me)
			hasPermissionFor("DRAFTS_COMPLIANCE") && storage.getOrFetch("/documents/requests").then((res) => setRequestsCounter(res && res.length ? res.length : 0));
			hasPermissionFor(["DRAFTS_ANALYST", "DRAFTS_PEER"]) && storage.getOrFetch("/documents/drafts").then(countDraftsAndPeerRequests);
			//setMe(me)
			hasPermissionFor("DRAFTS_COMPLIANCE") && storage.watch("/documents/requests", (res) => setRequestsCounter(res && res.length ? res.length : 0));
			hasPermissionFor(["DRAFTS_ANALYST", "DRAFTS_PEER"]) && storage.watch("/documents/drafts", countDraftsAndPeerRequests);
		});

		return () => {
			hasPermissionFor("DRAFTS_COMPLIANCE") && storage.unwatch("/documents/requests")
			hasPermissionFor(["DRAFTS_ANALYST", "DRAFTS_PEER"]) && storage.unwatch("/documents/drafts")
		}
	}, [])

	const countDraftsAndPeerRequests = (list) => {
		let drafts = 0
		let peers = 0
		list.forEach((doc) => {
			if (doc.peerReview !== "pending") {
				peers++
			} else {
				drafts++
			}
		})
		setDraftsCounter(drafts)
		setPeerCounter(peers)
	}

	const hasPermissionFor = (permission) => {
		if (Array.isArray(permission)) return permission.some(p => me && me.perms.includes(p));
		return me && me.perms.includes(permission);
	}

	let draftsNum = 0
	if (hasPermissionFor("DRAFTS_ANALYST")) draftsNum = draftsNum + draftsCounter
	if (hasPermissionFor("DRAFTS_PEER")) draftsNum = draftsNum + peerCounter

	return (
		<SideBarWrapper borderRight left>
			<SideBarIconGroup>
				{hasPermissionFor("EDIT") && <IconLink
					icon={<Edit />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.EDITOR])}
					active={checkRoute(ROUTE_PATHS[PAGES.EDITOR])}
					label="Editor"
				/>}
				{hasPermissionFor("LIBRARY_VIEW") && <IconLink
					icon={<BookOpen />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.LIBRARY])}
					active={checkRoute(ROUTE_PATHS[PAGES.LIBRARY])}
					label="Library"
				/>}
				{hasPermissionFor("DRAFTS_COMPLIANCE") && <IconLink
					icon={<UserCheck />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.REQUESTS])}
					active={checkRoute(ROUTE_PATHS[PAGES.REQUESTS])}
					label="Requests"
					counter={requestsCounter}
				/>}
				{hasPermissionFor(["DRAFTS_ANALYST", "DRAFTS_PEER"]) && <IconLink
					icon={<Edit3 />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.DRAFTS])}
					active={checkRoute(ROUTE_PATHS[PAGES.DRAFTS])}
					label="Drafts"
					counter={draftsNum}
				/>}
				{hasPermissionFor("ANALYTICS_VIEW") && <IconLink
					icon={<BarChart2 />}
					onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/dashboard")}
					active={checkRoute(ROUTE_PATHS[PAGES.ANALYTICS])}
					label="Analytics"
				/>}
				{hasPermissionFor(["USERS", "COMPANIES", "DISTRIBUTION", "SUPPRESSED_EMAILS"]) && <IconLink
					icon={<User />}
					onClick={() => changeRoute(getSidebarRouteByPermissions("clientManagement", me && me.perms))}
					active={checkRoute(ROUTE_PATHS[PAGES.CRM])}
					label="CRM"
				/>}
				{hasPermissionFor(["TICKERS", "MODEL_PORTFOLIOS", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES", "IMPERSONATE"]) && <IconLink
					icon={<Settings />}
					onClick={() => changeRoute(getSidebarRouteByPermissions("admin", me && me.perms))}
					active={checkRoute(ROUTE_PATHS[PAGES.ADMIN])}
					label="Admin"
				/>}
			</SideBarIconGroup>
			<SideBarIconGroup className="mt-auto">
				<IconLink
					icon={<LogOut />}
					onClick={() => { remote.logout() }}
					active={false}
					label="Logout"
				/>
			</SideBarIconGroup>
		</SideBarWrapper>
	)
};

export default withRemote(withRouteChange(SideBarLeft));