import React, { Component, Suspense } from "react";
import "./BuilderPage.scss";
import { withRemote, withStorage } from "@threeskye/global";

import Page from "../Page";
import { withData } from "../DataController";
import Modal from "../components/Modals/Modal";
import EditorSideBarExtension from "../components/SideBar/SideBarExtension/EditorSideBarExtension";
import AlertBlock from "./Shared/Prompts/AlertBlock";
import { CloudOff } from "react-feather";
import AlertBanner from "./Shared/Prompts/AlertBanner";
import LoadingOverlay from "./LoadingOverlay";
import SideBarRight from "../components/SideBar/SideBarRight";
import CommentsSidebarExtension from "../components/SideBar/SideBarExtension/CommentsSidebarExtension";
import OptionsSidebarExtension from "../components/SideBar/SideBarExtension/OptionsSidebarExtension";
import DataSidebarExtension from "../components/SideBar/SideBarExtension/DataSidebarExtension";
import VersionsSidebarExtension from "../components/SideBar/SideBarExtension/VersionsSidebarExtension";
import ModelSidebarExtension from "../components/SideBar/SideBarExtension/ModelSidebarExtension";

class BuilderPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pdf: null,
			errors: {},
			tickerPreviewed: false,
			isLoading: false,
			showingPdf: 2,
			pdf1: null,
			pdf2: null,
			template: null,
			//Do not camel case these, they are derived
			showuploadFailure: false,
			badProforma: false,
			showanalystChecklist: false,
			saveError: false,
			sideBarExtRight: "",
			commentsSlideIn: true,
			commentsSlideOut: false,
			versionsSlideIn: true,
			versionsSlideOut: false,
			compliance: {
				requested: false,
				approved: false,
				denied: false,
				comment: false
			},
			peerReview: {
				requested: false,
				approved: false,
				denied: false,
				comment: false
			},
			optionalPages: [],
			loadingLocalData: false,
		};
		this.onPreview = this.onPreview.bind(this);
		this.tickerPreview = this.tickerPreview.bind(this);
		this.downloadPDF = this.downloadPDF.bind(this);
		this.onTemplateChange = this.onTemplateChange.bind(this);
		this.showModal = this.showModal.bind(this);
		this.cancelModal = this.cancelModal.bind(this);
		this.confirmModal = this.confirmModal.bind(this);
		this.modalConfirmCallbacks = {};
		this.modalCancelCallbacks = {};
		this.modalData = {};
		this.showSaveError = this.showSaveError.bind(this);
		this.hideSaveError = this.hideSaveError.bind(this);
		this.setSideBarExtRight = this.setSideBarExtRight.bind(this)

	}


	showModal(name, confirmCallback, cancelCallback, data) {
		this.modalConfirmCallbacks[name] = confirmCallback;
		this.modalCancelCallbacks[name] = cancelCallback;
		this.modalData[name] = data;
		this.setState({ ["show" + name]: true });
	}

	showSaveError() {
		// this.setState({ saveError: true });
		// this.props.storage.put("research-master-grid", "alertBannerActive");
	}

	hideSaveError() {
		// this.setState({ saveError: false });
		// this.props.storage.put("research-master-grid", "alertBannerInactive");
	}

	cancelModal(name) {
		this.setState({ ["show" + name]: false });
		if (this.modalCancelCallbacks[name])
			this.modalCancelCallbacks[name]();

		this.modalCancelCallbacks[name] = null;
		this.modalConfirmCallbacks[name] = null;
	}

	confirmModal(name) {
		this.setState({ ["show" + name]: false });
		if (this.modalConfirmCallbacks[name])
			this.modalConfirmCallbacks[name]();

		this.modalCancelCallbacks[name] = null;
		this.modalConfirmCallbacks[name] = null;
	}

	onPreview(dto) {
		this.setState({ isLoading: true }, () =>
			this.props.remote
				.post("/preview", dto)
				.then(previewResponse => {
					this.setState({ errors: {} }, () => {
						//TODO
						/*let {pdf, ...others} = previewResponse;
						let newState = {
							...others,
							tickerPreviewed: true
						};
						if (this.state.showingPdf === 2)
						newState.pdf1 = pdf;
						else
						newState.pdf2 = pdf;
						this.setState(newState, () => setTimeout(() => this.setState({ isLoading: false, showingPdf: this.state.showingPdf === 1 ? 2 : 1 }), 1000))
						*/
					});
				})
		);
	}

	tickerPreview(dto, callback) {
		this.setState({ isLoading: true });
		this.props.remote.post("/ticker", dto)
			.then(response => {
				//const previewResponse = response.pdf;
				this.setState({ errors: {} }, () => {

					/*
					let {pdf, ...others} = previewResponse;
					let newState = {
						...others,
						tickerPreviewed: true
					};
					if (this.state.showingPdf === 2)
					newState.pdf1 = pdf;
					else
					newState.pdf2 = pdf;
					this.setState(newState, () => setTimeout(() => this.setState({ isLoading: false, showingPdf: this.state.showingPdf === 1 ? 2 : 1 }), 1000))
					*/
					//TODO
				});
				if (callback)
					callback(response);
			});
	}

	onTemplateChange(template) {
		this.setState({ template });
	}

	downloadPDF(dto, callback, completedCallback) {
		dto = dto || {};
		//dto.template = JSON.stringify(this.props.template);
		dto.data = {};
		this.props.data.compileData(dto.data);

		if (callback) {
			callback(dto);
		} else {
			const ticker = dto.tickerGroup ? dto.tickerGroup : dto.tickers[0];
			const filename = ticker + ".pdf";

			this.props.remote
				.post("/previewv2/" + ticker + "/" + dto.templateName, dto)
				.then(previewResponse => {
					const url = "data:application/pdf;base64, " + previewResponse.pdf;

					// use HTML5 a[download] attribute to specify filename
					const a = document.createElement("a");
					// safari doesn't support this yet
					if (typeof a.download === 'undefined') {
						window.location = url;
					} else {
						a.href = url;
						a.download = filename;
						document.body.appendChild(a);
						a.click();
					}
					if (completedCallback) {
						completedCallback();
					}
				}).catch(error => {
					console.log(error)
					if (completedCallback) {
						completedCallback();
					}
				});
		}
	}


	setSideBarExtRight(content, canClose) {

		if (this.state.sideBarExtRight === content && canClose) {

			this.setState({ commentsSlideIn: false })
			setTimeout(() => { this.setState({ commentsSlideOut: true }) }, 10)

			setTimeout(() => {
				this.setState({ sideBarExtRight: "" })
				this.props.storage.put("research-master-grid", "sideBarExtRightInactive")
			}, 250)
		} else if (this.state.sideBarExtRight !== content) {
			this.setState({ sideBarExtRight: content, commentsSlideIn: true, commentsSlideOut: false })
			this.props.storage.put("research-master-grid", "sideBarExtRightActive")
		}
	}

	// static getDerivedStateFromError(error) {
	// 	// Update state so the next render will show the fallback UI.
	// 	return { saveError: true };
	// }

	// componentDidCatch(error, errorInfo) {
	// 	console.log("Component did catch",error,errorInfo)
	// 	this.setState({
	// 		showanalystChecklist:false
	// 	});
	//   }

	render() {
		const { template } = this.state;

		let output = template && template.outputs && template.outputs.filter(o => o.dflt)
		let outputName = output && output[0] && output[0].key;
		// this.props.organisation && this.props.organisation.peerChecklistComponent && console.log(`./CompanyUserManagement/${this.props.organisation.peerChecklistComponent}`)
		let ComplianceModal;
		try {
			ComplianceModal = React.lazy(() => import(`./CompanyUserManagement/${this.props.organisation.peerChecklistComponent}`));
		} catch (error) {
			console.log(error);
			ComplianceModal = <div>An error occurred.  This may be caused by corruption in your browser.  Please clear any caches and reload the page</div>
		}
		// const ComplianceModal = React.lazy(() => import('./CompanyUserManagement/Hobson/PeerComplianceModal'));
		// const ComplianceModal = React.lazy(() => import('./CompanyUserManagement/Hobson/PeerComplianceModal'));


		return (
			<>
				{/* Checklist Modal */}
				{this.state.showanalystChecklist &&
					<Modal
						alignModal="top"
						header="Responsible Analyst Checklist and Declaration"
						cancelButton={{ onClick: () => this.cancelModal("analystChecklist") }}
						confirmButton={{ onClick: () => this.confirmModal("analystChecklist") }}
						handleClose={() => this.cancelModal("analystChecklist")}
					>
						<Suspense fallback={<div>Loading...</div>}>
							<ComplianceModal />
						</Suspense>

					</Modal>
				}
				{this.state.saveError && <AlertBanner type="danger" icon={<CloudOff />}
				>There was an error saving your changes.  Either you have lost your internet connection or our server is temporarily offline.</AlertBanner>}
				{this.state.loadingLocalData && <Modal header="Content Loading">
					<AlertBlock type="danger">Please wait...</AlertBlock>
				</Modal>}
				{this.state.showuploadFailure && <Modal
					header="Upload Failed"
					confirmButton={{ onClick: () => this.cancelModal("uploadFailure") }}
					handleClose={() => this.cancelModal("uploadFailure")}>
					<AlertBlock type="danger">File uploaded, but was not able to be parsed.  Is the file the correct proforma format?</AlertBlock>
				</Modal>}
				{this.state.showbadProforma && <Modal
					header="Upload Failed"
					confirmButton={{ onClick: () => this.cancelModal("badProforma") }}
					handleClose={() => this.cancelModal("badProforma")}>
					{/* <p className="mb-s">Failed to upload proforma.</p> */}
					{this.modalData.badProforma.tooBig && this.modalData.badProforma.tooBig.length > 0 && this.modalData.badProforma.tooBig.map(file => <AlertBlock header large type="danger" fullWidth>Maximum file size exceeded: {file.name}</AlertBlock>)}
					{this.modalData.badProforma.wrongFileType && this.modalData.badProforma.wrongFileType.length > 0 && this.modalData.badProforma.wrongFileType.map(file => <AlertBlock header large type="danger" fullWidth>File not recognised as a .xlsx: {file.name}</AlertBlock>)}
				</Modal>}
				{this.state.showpublishConfirm && <Modal
					header="Confirm Publish"
					cancelButton={{ onClick: () => this.cancelModal("publishConfirm") }}
					confirmButton={{ onClick: () => this.confirmModal("publishConfirm") }}
					handleClose={() => this.cancelModal("publishConfirm")}>
					<p>Are you sure you wish to publish?</p>
				</Modal>}
				{this.state.showoverrideConfirm && <Modal
					header="Confirm Review Bypass"
					cancelButton={{ onClick: () => this.cancelModal("overrideConfirm") }}
					confirmButton={{ onClick: () => this.confirmModal("overrideConfirm") }}
					handleClose={() => this.cancelModal("overrideConfirm")}>
					<AlertBlock header large type="warning">Note will be published without compliance and/or peer review.  Compliance will be notified.  Are you sure?</AlertBlock>
				</Modal>}
				{this.state.showpublishFailure && <Modal
					header="Publish Failed"
					confirmButton={{ onClick: () => this.confirmModal("publishFailure") }}
					handleClose={() => this.confirmModal("publishFailure")}>
					<AlertBlock className="publish-failure-alert" large type="danger">
						{this.modalData["publishFailure"].message}

						{this.modalData["publishFailure"].data &&
							<ul className="publish-failure-list">
								{this.modalData["publishFailure"].data.map((reason, idx) => {
									return <li className="publish-failure-list-item" key={"failureModalReason-" + idx}>{reason}</li>
								})}
							</ul>
						}
					</AlertBlock>
				</Modal>}
				{this.state.showreviewFailure && <Modal
					header="Review Request Failed"
					confirmButton={{ onClick: () => this.confirmModal("reviewFailure") }}
					handleClose={() => this.confirmModal("reviewFailure")}>
					<p>{(this.modalData["reviewFailure"] && this.modalData["reviewFailure"].message) || "We're sorry, something went wrong.  Please contact 3Skye"}</p>
				</Modal>}
				{this.state.showreviewResent && <Modal
					header="Review Request Resent"
					confirmButton={{ onClick: () => this.confirmModal("reviewResent") }}
					handleClose={() => this.confirmModal("reviewResent")}>
					<p>{(this.modalData["reviewResent"] && this.modalData["reviewResent"].message)}</p>
				</Modal>}
				{this.state.showpublishSuccess && <Modal
					header="Publish Successful"
					confirmButton={{ onClick: () => this.confirmModal("publishSuccess") }}
					handleClose={() => this.confirmModal("publishSuccess")}>
					<p>Note successfully published</p>
				</Modal>}
				{/*<SideBar onPreview={this.onPreview} tickerPreview={this.tickerPreview} errors={this.state.errors} downloadPDF={this.downloadPDF}/>*/}
				<EditorSideBarExtension
					downloadPDF={this.downloadPDF}
					onTemplateChange={this.onTemplateChange}
					collapsable showModal={this.showModal}
					showSaveError={this.showSaveError}
					hideSaveError={this.hideSaveError}
					setCompliance={(compliance, callback) => this.setState({ compliance }, callback && callback)}
					setPeerReview={(peerReview, callback) => this.setState({ peerReview }, callback && callback)}
					compliance={this.state.compliance}
					peerReview={this.state.peerReview}
					setSideBarExtRight={this.setSideBarExtRight}
					optionalPages={this.state.optionalPages}
					setOptionalPages={(optionalPages, callback) => { this.setState({ optionalPages }, callback && callback) }}
					loadingLocalData={this.state.loadingLocalData}
					setLoadingLocalData={(loadingLocalData, callback) => this.setState({ loadingLocalData }, callback && callback)}
					organisation={this.props.organisation}
				/>
				{/* <StandardSideBar onTemplateChange={this.onTemplateChange} collapsable/> */}
				<div className="editor-page-content">
					{this.state.template && this.state.template.pages.map((page, idx) => <Page template={this.state.template} output={outputName} page={page} key={this.state.template.name + idx} />)}
					{this.state.isLoading && <LoadingOverlay variant='light' />}
				</div>
				{/* <SideBarRight setSideBarExtRight={this.setSideBarExtRight} /> */}
				<SideBarRight setSideBarExtRight={this.setSideBarExtRight} sideBarExtRight={this.state.sideBarExtRight} />
				{this.state.sideBarExtRight === "Data" &&
					<DataSidebarExtension
						consensusFields={this.props.data.consensusFields}
						contentData={this.props.data.contentData}
						slideIn={this.state.commentsSlideIn}
						slideOut={this.state.commentsSlideOut}
					/>}
				{this.state.sideBarExtRight === "Comments" &&
					<CommentsSidebarExtension
						slideIn={this.state.commentsSlideIn}
						slideOut={this.state.commentsSlideOut}
						compliance={this.state.compliance}
						peerReview={this.state.peerReview}
						data={this.props.data && (this.props.data.tickerGroup || (this.props.data.tickers && this.props.data.tickers[0] && this.props.data.tickers[0] !== "")) ? {type: this.props.data.templateName, ticker: this.props.data.tickerGroup? this.props.data.tickerGroup : this.props.data.tickers[0]} : null }
					/>}
				{this.state.sideBarExtRight === "Options" &&
					<OptionsSidebarExtension
						loadingLocalData={this.state.loadingLocalData}
						setLoadingLocalData={(loadingLocalData, callback) => this.setState({ loadingLocalData }, callback && callback)}
						optionalPages={this.state.optionalPages}
						setOptionalPages={(optionalPages, callback) => { this.setState({ optionalPages }, callback && callback) }}
						slideIn={this.state.commentsSlideIn}
						slideOut={this.state.commentsSlideOut}
						template={this.state.template}
					/>}
				{this.state.sideBarExtRight === "Versions" &&
					<VersionsSidebarExtension
						slideIn={this.state.versionsSlideIn}
						slideOut={this.state.versionsSlideOut}
						template={this.state.template}
						ticker={this.props.data.ticker}
					/>}
					{this.state.sideBarExtRight === "Model" &&
					<ModelSidebarExtension
						slideIn={this.state.versionsSlideIn}
						slideOut={this.state.versionsSlideOut}
						template={this.state.template}
						ticker={this.props.data.ticker}
					/>}
			</>
		);
	}
}

export default withData(withStorage(withRemote(BuilderPage)));