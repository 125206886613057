import React from 'react';
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import { LogOut } from 'react-feather';
import Divider from '../Divider';
import PopupMenu from '../PopupMenu';
import PopupMenuItem from '../PopupMenuItem';
import ProfileImage from '../ProfileImage';
import "./ProfileMenu.scss";
import {withRemote} from '@threeskye/global'

const ProfileMenu = ({ changeRoute, remote, user }) => {
	return (
		<>
			<PopupMenu padding width="208px">
				<div className="profile-menu-header-container">
					<ProfileImage user={user} src={user && "data:image/jpeg;base64,"+user.photo} />
					<div className="profile-menu-details">
						<div className="profile-header">{user && user.name}</div>
					</div>
				</div>
				<Divider />
				<PopupMenuItem label="Profile" onClick={() => changeRoute(ROUTE_PATHS[PAGES.SETTINGS]+"/profile")}/>
				{/* <PopupMenuItem label="Settings" onClick={() => changeRoute(ROUTE_PATHS[PAGES.SETTINGS]+"/general")}/> */}
				<Divider />
				<PopupMenuItem label="Logout" icon={<LogOut />} onClick={() => remote.logout()}/>
			</PopupMenu>
		</>
	)
}

export default withRemote(ProfileMenu);