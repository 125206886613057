import React, { useState } from 'react';
import { AlertCircle, Tool } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Checkbox from '../../../components/Checkbox';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Table from '../../../components/Table/Table';
import { useRemote } from '../../../Utils/Utils';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';

const TemplatePublishActions = ({ data, addActionToTemplate, removeAction, selected, templateId, setSelectedPublishActions }) => {
	const colWidths = [40 + "%", 55 + "%", "5%"];

	const selectedPublishActionIds = selected.map(a => a.stepId);

	const showParams = (action) => {
		if (!action) return null;
		return (<FlexWrapper gap="xl" direction="column" align="start" disabled>
			{action.params.map((param,idx) => <ParamField key={idx} param={param} action={action} template={templateId} setSelectedPublishActions={setSelectedPublishActions}/>)
		}</FlexWrapper>)
	}

	const dataMap = () => data && data.map((action) => {


		const active = selectedPublishActionIds.includes(action.id);

		if (!active) {
			return <tr key={action.id} >
				<td><Checkbox checked={active} label={action.displayName} onChange={() => addActionToTemplate(action)}/></td>
				<td>{action.description}</td>
				<td></td>
			</tr>
		}

		const selectedInstance = active ? selected.find(a => a.stepId === action.id) : null;
		let className = "cursor-pointer ";
		let invalid = false;
		for (let param of selectedInstance.params) {
			if (param.required) {
				const val = selectedInstance.paramValues[param.name];
				if (!val || (val && val.length === 0)) {
					invalid = true;
					break;
				}
			}
		} 
//		const invalid = (selectedInstance.params.length !== Object.keys(selectedInstance.paramValues).length || Object.values(selectedInstance.paramValues).filter(e => e.length === 0).length > 0);

		return (
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(selectedInstance)}>
				<td><Checkbox checked={active} label={action.displayName} onChange={() =>  removeAction(action)}/></td>
				<td>{action.description} </td>
				<td>{invalid ? <AlertCircle/> : null}</td>
				{/* <td className="table-action-icons">
					<TableActionIcon icon={<Plus />} toolTip="Add this action" onClick={() => addActionToTemplate(action)} />
				</td> */}
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="Publish Actions" icon={<Tool />} />
			</PageGridItem>
			<PageGridItem col="1 / span 6" >
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}

const ParamField = ({param, template, action, setSelectedPublishActions}) => {

	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const remote = useRemote();
	const [editor, setEditor] = useState(value);

	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`,{"value":editor}).then(setSelectedPublishActions);
	}

	

	return (
	<div style={{width: "100%"}}>
		<DetailField variant="light" invalid={editor === "" ? "invalid" : undefined} editMode={true} autoFocus placeholder={param.description} label={param.name} value={editor} onChange={(e) => setEditor(e.target.value)}/>
		<Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button>
	</div>
	)
};

export default TemplatePublishActions;