import React from 'react';
import FlexWrapper from '../../FlexWrapper';
import DateRangeToggle from './DateRangeToggle';

const AnalyticsHeader = ({ children, dateRanges, setTimeFrame, timeFrame }) => {

	const displayTimeFrame = (time) => {
		if (!time || typeof time === "string") {
			return "All Time"
		} else {
			let string = `${time.months ? time.months + " Months" : ""} ${time.days ? time.days + " Days" : ""} ${time.years ? time.years + " Years" : ""}`
			return string
		}
	}

	return (
		<FlexWrapper className="analytics-header mb-l" fullWidth justify="space-between">
			<p className="font-weight-semi-bold">{children}</p>
			{dateRanges && <DateRangeToggle value={displayTimeFrame(timeFrame)} onChange={setTimeFrame} dateRanges={dateRanges} />}
		</FlexWrapper>
	);
}

export default AnalyticsHeader;