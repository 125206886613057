import React, { Component } from "react";
import withTickerList from "../withTickerList";
import SelectSearchField from "./SelectSearchField";
import toValueLabelPair from "../Functions/toValueLabelPair";
import TextFieldLabel from "./TextFieldLabel";
import LoadingIcon from "../LoadingIcon";

class TickerListSearchField extends Component {
	render() {
		const { tickerList, tickerListLoading, onChange, width, minWidth, value, label, onlyTickers, tickerListOverwrite, ...otherProps } = this.props;
		if (tickerListLoading) {
			return <LoadingIcon size={32} />;
		}

		return (
			<div className="ticker-search-wrapper">
				{label && <TextFieldLabel>{label}</TextFieldLabel>}
				<SelectSearchField
					minWidth={minWidth}
					width={width}
					value={toValueLabelPair(value)}
					options={tickerListOverwrite ? tickerListOverwrite : tickerList && tickerList.map((ticker) => {return {value: ticker, label: ticker}})}
					onChange={(obj) => onChange(obj && obj.value)}
					{...otherProps}
				/>
			</div>
		)
	}
}

export default withTickerList(TickerListSearchField);
