import React, { useState, useEffect } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';
import UserCompanyDetails from './UserCompanyDetails';
import UserGeneralDetails from './UserGeneralDetails';
import { useParams, useHistory } from 'react-router-dom';
import useWindowDimensions, { useRemote, useStorage, undoDeleteUser } from '../../../Utils/Utils';
import { withStorage } from '@threeskye/global';
import withRouteChange from "@threeskye/route-change";
import { PAGES, ROUTE_PATHS } from "../../../InternalApiApp";
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import RemoveConfirmModal from '../../../components/Modals/RemoveConfirmModal';
import { toastDanger, toast, UndoToastMessage } from '../../../components/popups/Toast';
import LoadingIcon from '../../../components/LoadingIcon';
import { parseLocationDetails } from '../../../Utils/Utils';
import MiniResearchList from '../../Shared/TablesAndLists/MiniResearchList';
import UserPortalAccessDetails from './UserPortalAccessDetails'
import GenericMiniList from '../../Shared/TablesAndLists/GenericMiniList';
import { distributionListMap, watchlistMap } from '../../Shared/TablesAndLists/ListRowMappings';
import { toastSuccess } from '../../../components/popups/Toast'


const blankCompany = {
	"name": null,
	"locations": "",
	"Distribution List": [],
	"id": null,
	"email": "",
	"phone": "",
	"address": ""
}

const blankUser = {
	"firstName": null,
	"middleNames": null,
	"lastName": null,
	"preferredName": null,
	"role": null,
	"email": null,
	"phone": null,
	"salutation": null,
	"id": null,
	"preferredName": null,
	"role": null,
	"location": null,
	"organisationId": null,
	"keyContact": false
}

const UserPage = ({ changeRoute }) => {
	const [editMode, setEditMode] = useState(false);
	const [user, setUser] = useState(blankUser);
	const [company, setCompany] = useState(blankCompany)
	const [createMode, setCreateMode] = useState(false);
	const [createNewCompanyMode, setCreateNewCompanyMode] = useState(false);
	const [activeLists, setActiveLists] = useState([]);
	const [activeWatchLists, setActiveWatchLists] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [allLists, setAllLists] = useState([])
	const [allOrgs, setAllOrgs] = useState([])
	const [loading, setLoading] = useState(true)
	const [countries, setCountries] = useState([])
	const [phoneNumberValid, setPhoneNumberValid] = useState(true)
	const [tickerList, setTickerList] = useState("Distribution Lists")
	const [tickerMap,] = useState(distributionListMap)
	const [allTickers, setAllTickers] = useState([])
	const [allGroups, setAllGroups] = useState([])
	const [userFirstNameTouched, setUserFirstNameTouched] = useState(false)
	const [userLastNameTouched, setUserLastNameTouched] = useState(false)

	const remote = useRemote()
	const storage = useStorage()
	const { userId } = useParams()
	const history = useHistory()

	const params = new URLSearchParams(window.location.search);
	const createQuery = params.get('create');
	const editQuery = params.get('edit');


	useEffect(() => {
		switch (userId) {
			case "create":
				setUser(blankUser)
				setCreateMode(true)
				Promise.all([
					remote.get(`/crm/organisations`).then(setAllOrgs),
					remote.get(`/crm/lists`).then(setAllLists)
				])
					.then(() => setLoading(false))
				break;
			default:
				getUser()
				if (createQuery) {
					setCreateMode(true)
				}
				if (editQuery) {
					setEditMode(true)
				}
				break;
		}
		remote.get(`/crm/countries`).then(setCountries)
		storage.getOrFetch(`/crm/tickers`).then(setAllTickers)
		storage.getOrFetch(`/crm/groupings/${1}`).then((fetchedGrouping) => setAllGroups(fetchedGrouping.groups))
	}, []);


	useEffect(() => { createMode === true && setEditMode(true) }, [createMode]);

	const getUser = () => {
		Promise.all([
			remote.get(`/crm/contacts/${userId}`).then((data => {
				if (data.phone && data.phone.startsWith("{")) {
					data.phone = JSON.parse(data.phone);
				}
				setUser(parseLocationDetails(data))

				if (data.organisationId) {
					remote.get(`/crm/organisations/${data.organisationId}`)
						.then((companyResponse) => {
							setCompany(parseLocationDetails(companyResponse))
						})
				}
				remote.get(`/crm/contact/${userId}/lists`).then(setActiveLists)
				remote.get(`/crm/contact/${userId}/watchlists`).then(setActiveWatchLists)
			})),
			remote.get(`/crm/lists`).then(setAllLists),
			remote.get(`/crm/organisations`).then(setAllOrgs)
		])
			.then(() => setLoading(false))
	}

	const editUser = () => {
		const userToPush = { ...user };
		if (userToPush.phone && typeof userToPush.phone === 'object') {
			userToPush.phone = JSON.stringify(user.phone);
		}
		if (createNewCompanyMode) {
			remote.post(`/crm/organisations`, { ...company, locations: [company.locations] })
				.then((companyResponse) => {
					remote.put(`/crm/organisations/${companyResponse.data.id}/contacts/${user.id}`)
					remote.put(`/crm/contacts/${userId}`, { ...userToPush, organisationId: companyResponse.data.id })
						.then(() => {
							storage.refresh("/crm/contacts")
							storage.refresh("/crm/organisations")
						})
					setCompany(companyResponse.data)

				}).then(() => {
					setCreateNewCompanyMode(false);
					setEditMode(false)
					toastSuccess("User details updated")
				})
		} else {
			if (company.id !== null) {
				remote.put(`/crm/organisations/${company.id}/contacts/${user.id}`)
					.then((linkingResponse) => {
						remote.put(`/crm/contacts/${userId}`, { ...userToPush, organisationId: linkingResponse.organisationId })
							.then(() => {
								storage.refresh("/crm/contacts")
								storage.refresh("/crm/organisations")
							})
					})
					.then(((response) => {
						setEditMode(false)
						toastSuccess("User details updated")
					}))
			} else {
				remote.put(`/crm/contacts/${userId}`, userToPush)
					.then((() => {
						storage.refresh("/crm/contacts")
						storage.refresh("/crm/organisations")
						setEditMode(false)
						toastSuccess("User details updated")
					}))
			}
		}
	}

	const createUser = () => {
		if (createNewCompanyMode) {
			//create organisation
			remote.post(`/crm/organisations`, { ...company, locations: [company.locations] })
				.then(companyResponse => {
					//create user
					remote.post(`/crm/contacts`, { ...user, organisationId: companyResponse.data.id, phone: JSON.stringify(user.phone) })
						.then((contactResponse) => {
							if (contactResponse.success === false) {
								toastDanger("Unable to create user - " + contactResponse.message);
								return null;
							}
							setUser(contactResponse.data)
							//link user and org
							remote.put(`/crm/organisations/${companyResponse.data.id}/contacts/${contactResponse.data.id}`).then((resp) => {
							})
							//link user to distribution lists
							activeLists.forEach((list) => {
								remote.put(`/crm/lists/${list.id}/members/${contactResponse.data.id}`)
							})
							return contactResponse
						})
						.then(((contactResponse) => {
							if (!contactResponse) {
								return;
							}
							storage.refresh("/crm/contacts")
							storage.refresh("/crm/organisations")
							setCreateMode(false)
							setEditMode(false)
							setCreateNewCompanyMode(false)
							history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + contactResponse.data.id)
							toastSuccess('New user created')
							
						}))
					setCompany(parseLocationDetails(companyResponse.data))
				})
		} else {
			//create user
			remote.post(`/crm/contacts`, { ...user, phone: JSON.stringify(user.phone), organisationId: company.id })
				.then((contactResponse) => {
					if (contactResponse.success === false) {
						toastDanger("Unable to create user - " + contactResponse.message);
						return null;
					}
					setUser(contactResponse.data)
					//link user and org
					company.id && remote.put(`/crm/organisations/${company.id}/contacts/${contactResponse.data.id}`)
					//link user to distribution lists
					activeLists.forEach((list) => {
						remote.put(`/crm/lists/${list.id}/members/${contactResponse.data.id}`)
					})
					return contactResponse
				})
				.then((contactResponse) => {
					if (!contactResponse) {
						return;
					}
					storage.refresh("/crm/contacts")
					storage.refresh("/crm/organisations")
					setEditMode(false)
					setCreateMode(false)
					history.push(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + contactResponse.data.id)
					toastSuccess('New user created')
				})
		}
	}
	const createCompany = () => {
		remote.post(`/crm/organisations`, { ...company, locations: [company.locations] })
			.then((response => {
				setCompany(parseLocationDetails(response.data))
				setCreateNewCompanyMode(false)
				storage.refresh("/crm/organisations")
			}))
	}
	const returnToPage = (deletedUser) => { changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users/user/" + deletedUser.id) }
	const removeUser = (e) => {
		e.stopPropagation();
		remote.delete(`/crm/contacts/${user.id}`)
			.then(() => {
				toast(<UndoToastMessage message="User removed" onClick={() => { undoDeleteUser(user, activeLists, returnToPage) }} />, { autoClose: 10000 })
				changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users")
			})
			.catch(() => {
				toastDanger("User could not be removed")
			})
	}


	const { width } = useWindowDimensions();

	return (
		<Page fixedBanner>
			{/* Modal */}
			{showModal && (
				<RemoveConfirmModal
					label="user"
					handleClose={() => setShowModal(false)}
					cancelButton={{ onClick: () => setShowModal(false) }}
					confirmButton={{ onClick: () => setShowModal(false, toast('User Removed')) }}
				/>
			)}
			{/* Page banner with back button */}
			<PageContentBanner divider>
				<ActionButtonsBanner
					// handleBackOnClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users")}
					hideBackButton
					editMode={editMode}
					createMode={createMode}
					edit={{ onClick: () => setEditMode(true) }}
					duplicate={{ onClick: () => setCreateMode(true) }}
					remove={{ onClick: (e) => removeUser(e) }}
					cancel={{
						onClick: () => {
							setEditMode(false);
							setCreateNewCompanyMode(false)
							if (createMode) {
								changeRoute(ROUTE_PATHS[PAGES.CRM] + "/users")
							}
							else getUser();
						}
					}}
					save={{
						disabled: (user.firstName === null || user.firstName === "") || (user.lastName === null || user.lastName === "") ||
						(user ? (user.phone && user.phone.idd && user.phone.number && user.phone.idd !== "" && user.phone.number !== "" ? !phoneNumberValid : false) : false),
						onClick: () => {
							if (createMode) {
								createUser();
							} else {
								editUser();
							}
						},
					}}
				/>
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				{/* grid container for the user details section */}
				<PageGridItem container col="1 / span 6">
					<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid paddingBottom={"3rem"}>
						{loading
							? <PageGridItem container col="1 / span 6">
								<LoadingIcon size={48} centered />
							</PageGridItem>
							: <>
								{/* General Details */}
								<UserGeneralDetails
									editMode={editMode}
									user={user}
									editUser={(content) => {
										setUser(content);
									}}
									setValid={setPhoneNumberValid}
									countries={countries}
									userFirstNameTouched={userFirstNameTouched}
									setUserFirstNameTouched={setUserFirstNameTouched}
									userLastNameTouched={userLastNameTouched}
									setUserLastNameTouched={setUserLastNameTouched}
								/>
								<PageGridDivider col="1 / span 6" />
								{/* Research received list */}
								<MiniResearchList showAnalyticsIcons listHeader="Research Received" restrictHeight suggestionText="Research sent to user will appear here." data={user.distributions} />
							</>
						}
					</PageContentBodyGrid>
				</PageGridItem>
				{/* grid container for the distribution details section */}
				<PageGridItem container col="7 / span 6">
					<PageContentBodyGrid showScrollbar rowGap="xl" gridColCount="6" subGrid divider={width < 1200 ? "top" : "left"} paddingBottom={"3rem"}>
						{/* Company Details */}
						<UserCompanyDetails
							newUser={createMode}
							createNewCompanyMode={createNewCompanyMode}
							setCreateNewCompanyMode={setCreateNewCompanyMode}
							editMode={editMode}
							company={company}
							allCompanies={allOrgs}
							setCompany={(company) => {
								setCompany(company)
								setUser({ ...user, organisationId: company.id, })
							}}
							blankCompany={blankCompany}
							resetCompany={() => {
								if (user.organisationId) remote.get(`/crm/organisations/${user.organisationId}`)
									.then((companyData) => setCompany(parseLocationDetails(companyData)))
								else setCompany(blankCompany)
								setCreateNewCompanyMode(false)
							}}
							createCompany={createCompany}
						/>
						<PageGridDivider col="1 / span 6" />
						<UserPortalAccessDetails editMode={editMode} userId={userId} user={user} updateUser={setUser} />
						<PageGridDivider col="1 / span 6" />
						{loading
							? <PageGridItem container col="1 / span 6">
								<LoadingIcon size={48} centered />
							</PageGridItem>
							: !createMode && <>
								<GenericMiniList
									addAllButton
									changeRoute={changeRoute}
									editMode={editMode}
									userLists={tickerList === "Distribution Lists" ? activeLists : activeWatchLists}
									allLists={allLists}
									// members
									userId={user.id}
									setData={setActiveLists}
									headerOptions={[{ label: "Distribution Lists", value: "Distribution Lists" }, { label: "Watchlist", value: "Watchlist" }]}
									header={tickerList ? tickerList : "Distribution Lists"}
									onHeaderOptionSelect={(name) => setTickerList(name)}
									dataMap={tickerList === "Distribution Lists" ? distributionListMap : watchlistMap}
									colWidths={tickerList === "Distribution Lists" ? [80 + "%", 19 + "%"] : [100 + "%"]}
									watchList={activeWatchLists}
									noDataMessage={tickerList === "Distribution Lists" ? null : "This user's watchlist is empty."}
									hideAddInput={tickerList !== "Distribution Lists"}
									allTickers={allTickers}
									allGroups={allGroups}
								/>
								{/* <MiniDistributionList userId={user.id} userLists={activeLists} allLists={allLists} setData={setActiveLists} /> */}
							</>
						}
					</PageContentBodyGrid >
				</PageGridItem >
			</PageContentBodyGrid >
		</Page >
	);
}

export default withRouteChange(withStorage(UserPage));