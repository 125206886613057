import React, { useEffect, useState } from 'react';
import { Briefcase, Mail, ChevronUp, ChevronDown } from 'react-feather';
import Button from '../../../components/Buttons/Button';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import Table from '../../../components/Table/Table';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from '@threeskye/route-change'
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import useWindowDimensions, { parseLocationDetails, useRemote, undoDeleteCompany } from '../../../Utils/Utils';
import TableActionIconGroup from '../../../components/Table/TableActionIconGroup';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import { toastDanger, toast, UndoToastMessage } from '../../../components/popups/Toast';


const CompanyLists = ({ changeRoute }) => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
	const [dataDisplaying, setDataDisplaying] = useState([])
	const [loading, setLoading] = useState(true)
	const remote = useRemote();
	const { width } = useWindowDimensions();

	useEffect(() => {
		remote.get("/crm/organisations")
			.then((organisations) => {

				let sortedOrganisations = organisations.sort((userA, userB) => {
					let nameA = userA.name
					let nameB = userB.name
					return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
				})
				return sortedOrganisations
			})
			.then((data) => {
				setData(data)
				setLoading(false)
			})
			.catch(() => setLoading(false));
	}, []);

	// Sort table
	useEffect(() => {
		sortTable(null)
	}, [data, filteredData])

	const sortTable = (column) => {
		if (column === null) {
			setDataDisplaying(hasSearchTerm ? filteredData : data)
			return
		}
		let tableData = hasSearchTerm ? filteredData : data
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		switch (column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
				break;
			case "Headquarters":
				tableData.sort((a, b) => {
					const comp1 = parseLocationDetails(a) 
					const comp2 = parseLocationDetails(b) 
					const loc1 = comp1.locations.location ? comp1.locations.location : "-"
					const loc2 = comp2.locations.location ? comp2.locations.location : "-"
					return ascending ? loc1.localeCompare(loc2) : loc2.localeCompare(loc1)
				})
				break;
			case "DistributionLists":
				tableData.sort((a, b) => ascending ? a.distributionLists - b.distributionLists : b.distributionLists - a.distributionLists)
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	const addCompanyToData = (company) => {
		let companiesToSort = [...data]
		let sortedCompanies = companiesToSort.sort((userA, userB) => {
			let nameA = userA.name
			let nameB = userB.name
			return nameA.toLowerCase() > nameB.toLowerCase() ? 1 : -1
		})
		setData(sortedCompanies)
	}

	const removeCompany = (e, company) => {
		e.stopPropagation();
		remote.get(`/crm/organisations/${company.id}/contacts`)
			.then(companyContacts => {
				remote.delete(`/crm/organisations/${company.id}`)
					.then(() => {
						let newArray = []
						data.forEach((org) => {
							if (org.id !== company.id) {
								newArray.push(org)
							}
						})
						setData(newArray)
						toast(<UndoToastMessage message="Company removed" onClick={() => { undoDeleteCompany(company, companyContacts, addCompanyToData) }} />, { autoClose: 10000 })

					})
					.catch(() => {
						toastDanger("Company could not be removed")
					})
			})
	}

	const colWidths = ["310px", "250px", "150px"]
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Headquarters")} >
					Headquarters
					{sortingColumn && sortingColumn.column === "Headquarters" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Headquarters" ? "colour-text-primary" : ""} size={12} />
					}
				</div>
			)
		},
		""
	]

	const dataMap = (dataToMap) => {
		return dataToMap.map((company, idx) => {
			let parsedCompany = parseLocationDetails(company)
			return (
				<tr className="cursor-pointer" key={idx} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id)}>
					<td><p>{company.name}</p></td>
					<td><p>{parsedCompany.locations && parsedCompany.locations.location ? parsedCompany.locations.location : "-"}</p></td>
					<TableActionIconGroup
						download={{ hide: true }}
						edit={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id + "?edit=true") } }}
						duplicate={{ onClick: (e) => { e.stopPropagation(); changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/" + company.id + "?create=true") } }}
						remove={{ onClick: (e) => removeCompany(e, company) }}
					/>
				</tr>
			)
		})
	}

	return (
		<Page fixedBanner>
			{/* Modal */}
			<PageContentBanner divider gap="m">
				<Button icon={<Briefcase />} onClick={() => changeRoute(ROUTE_PATHS[PAGES.CRM] + "/companies/company/create")}>{width < 900 ? "" : "Create Company"}</Button>
				{/* <SelectSearchField size="small" width="m" placeholder="Search companies" isClearable /> */}
				<SearchTermFilter
					size="small"
					width="l"
					placeholder={`Search companies ${loading ? "" : "(" + data.length + ")"}`}
					isClearable
					dataSet={data}
					setFilteredData={setFilteredData}
					path={["name"]}
					hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
				/>
			</PageContentBanner>
			<PageContentBodyGrid rowGap="xl" showScrollbar>
				<PageGridItem container col="1 / span 12">
					<div>
						<Table
							minWidth={450}
							tableStyle={{maxWidth: 1200}}
							colWidths={colWidths}
							headers={headers}
							dataMap={dataMap(dataDisplaying)}
							dataLoading={loading}
						/>
					</div>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default withRouteChange(CompanyLists);