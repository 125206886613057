import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Edit2, Eye, Send, Trash2 } from 'react-feather';
import TableActionIcon from '../../components/Table/TableActionIcon';
import StatusToken from '../../components/StatusTokens';
import FlexWrapper from '../FlexWrapper';
import Divider from '../../components/Divider';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import ConfirmActionModal from '../../components/Modals/ConfirmActionModal';
import withRouteChange from '@threeskye/route-change';
import preview from './fundfactsheetpreview.png';
import { showConfirmActionModal, useStorage } from '../../Utils/Utils';


const DraftsPeerReviewRow = ({ data, idx, tickers, expandedRows, setExpandedRows, getImage, models, modelsLoading, changeRoute, refreshList }) => {
	const [document, setDocument] = useState({});
	const [showModal, setShowModal] = useState(false);
	const [confirmActionModal, setConfirmActionModal] = useState(null);
	const [me, setMe] = useState({});

	const storage = useStorage();
	const perms = me?.perms || [];

	const actions = [
		{ label: "Confirm Approval", variant: "positive", disabled: !perms.includes("REVIEW_PEER"), action: () => { showConfirmActionModal("approvePeerReview", setConfirmActionModal, data?.type, data?.ticker, refreshList); setShowModal(false) } },
		{ label: "Confirm & Publish", variant: "positive", disabled: !perms.includes("REVIEW_PEER") || !perms.includes("WORKSPACE_PUBLISH"), action: () => { showConfirmActionModal("approvePeerReviewAndPublish", setConfirmActionModal, data?.type, data?.ticker, refreshList); setShowModal(false) } },
		{ label: "Deny Approval", variant: "danger", disabled: !perms.includes("REVIEW_PEER"), action: () => { showConfirmActionModal("rejectPeerReview", setConfirmActionModal, data?.type, data?.ticker, refreshList); setShowModal(false) } }
	]

	useEffect(() => {
		setDocument(data)
		storage.getOrFetch(`/crm/me`).then(setMe)
	}, [data])

	const ticker = tickers && tickers.find(t => t.ric === document.ticker)

	return (
		<>
			{showModal && <DraftNotePreviewModal data={data} handleClose={() => setShowModal(false)} actions={actions} />}
			{confirmActionModal && <ConfirmActionModal {...confirmActionModal} />}
			<tr key={idx} className={expandedRows && expandedRows.includes(document.docId) ? "library-row-selected" : ""}>
				<td>
					{document && document.date &&
						<p style={{ display: "flex", alignItems: "center" }}>
							{format(parseISO(document.date), "do MMM yyyy")}
							<span style={{ display: "inline-block", width: "3px", height: "3px", borderRadius: "5px", backgroundColor: "black", margin: "0px 5px" }} />
							{format(parseISO(document.date), "hh:mm aaa")}
						</p>}
				</td>
				<td>
					<div className="tsi-details">
						<div className="tsi-label">
							{document.ticker}
						</div>
					</div>

				</td>
				<td className="research-title">
					<p>{document && document.template ? document.template : document && document.type ? document.type : ""}</p>
				</td>
				<td>
					<p>{document.author}</p>
				</td>
				<td className="pl-m">
					<StatusToken type={document.compliance == "draft" ? "warning" : "primary"} label={document.compliance ?? "-"} />
				</td>
				{/* Row Actions */}
				<td>
					<FlexWrapper gap="l">
						<TableActionIcon colour="primary" noMargin icon={<Eye />} toolTip="Preview Note" onClick={() => setShowModal(true)} />
						<TableActionIcon
							toolTipDisabled={document.status == "pending"}
							disabled={document.status == "pending"}
							colour="primary"
							noMargin
							icon={<Edit2 />}
							toolTip="Edit Note"
							onClick={() => changeRoute(`/editor?template=${data?.type}&asset=${data?.ticker}`)}
						/>
						<Divider className="mr-m" vertical height={32} />
						<TableActionIcon
							toolTipDisabled={document.status == "pending"}
							disabled={document.status == "pending"}
							colour="positive"
							noMargin
							icon={<Send />}
							toolTip="Publish Note"
							onClick={() => null}
						/>
						<TableActionIcon
							colour="danger"
							noMargin
							icon={<Trash2 />}
							toolTip="Remove from Drafts"
							onClick={() => null}
						/>
					</FlexWrapper>
				</td>
			</tr>
		</>
	);
}

export default withRouteChange(DraftsPeerReviewRow);