import React from 'react';
import SideBarSection from './SideBarSection';
import TextFieldLabel from '../../Input/TextFieldLabel';
import Button from '../../Buttons/Button';

const ReviewButton = ({ review, label, disabled, performReview }) => {
	return (
		<SideBarSection separator>
			<TextFieldLabel label={label} />
			<Button
				noHover={review.approved || review.requested || review.responded}
				variant={review.approved ? "positive" : review.requested ? "warning" : ""}
				disabled={disabled}
				onClick={performReview}
			>
				{review.approved ? "Approved" : review.responded ? "Responded" : review.requested ? "Requested" : "Request Approval"}
			</Button>
		</SideBarSection>
	);
}

export default ReviewButton;