import React, { useState } from 'react';
import AnalyticsCounter from '../../../components/Analytics/AnalyticsCounter';
import FlexWrapper from '../../FlexWrapper';
import { useRemote } from '../../../Utils/Utils';
import { useEffect } from 'react';

const TotalMetrics = (props) => {

	const remote = useRemote();

	const [analytics, setAnalytics] = useState({})

	useEffect(() => {
		remote.get("/analytics/metrics/total").then(setAnalytics);
	}, [])

	return (
		<FlexWrapper fullWidth gap="xxl">
			<AnalyticsCounter label="Total Users" count={analytics.users || 0} />
			<AnalyticsCounter label="Active Users" count={analytics.activeUsers || 0} /> {/*missing data*/}
			<AnalyticsCounter label="Research Published" count={analytics.published || 0} />
			<AnalyticsCounter label="Research Views" count={analytics.views || 0} />
		</FlexWrapper>
   );
}

export default TotalMetrics;