import React, { Component } from 'react';
import './Editor.scss'
import SlateEditor from './SlateEditor';

export default class Editor extends Component {

	constructor(props) {
		super(props);

		let content = props.load || null;
		this.id = props.id || 'id'+Math.floor(Math.random() * Math.floor(999999))	;

		let hasContent = false;
		if (content) {
			try {
				if (Array.isArray(content)) {
					//use it
				} else if (content && content.length > 0 && typeof content === 'string' && content.startsWith("[")) {
					content = JSON.parse(content);
				} else {
					content = [{"type":"text","children":[{"text":""}]}];
				}
			} catch (e) {
				console.log("Caught ",e, content);
			}

		} else {
			content = [{"type":"text","children":[{"text":""}]}];
		}
		
		this.divRef = React.createRef();
		this.parentDivRef = React.createRef();
		
		this.state = {
			raw: content,
			hasContent: hasContent,
			isOverflown: false
		}

		this.onChange = this.onChange.bind(this);
		this.onLoad = this.onLoad.bind(this);
		this.isOverflown = this.isOverflown.bind(this);
	}

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.load !== prevProps.load || this.props.entityMap !== prevProps.entityMap) {
			if (this.props.load !== null) {
				this.setState({raw:this.props.load, hasContent:true});
				// if (this.props.exchangeDataAs === "editorState") {
				// 	this.setState({raw: this.props.load, hasContent: true});
				// } else {
				// 	const raw = {blocks:this.props.load, "entityMap":this.props.entityMap || {}};
				// 	this.setState({raw, hasContent:true});
				// }
			}				
		}
	}

	componentDidMount() {
		if (this.loaded) {
			this.onChange(this.loaded, true);
			delete this.loaded;
		}

		const element = this.parentDivRef.current.getElementsByClassName('public-DraftEditor-content');
		if (this.props.overflow) {
			if (element[0]) {
				element[0].classList.add("overflow-enabled");
			}
		}
	}
	
	onLoad(editorState) {
		this.loaded = editorState;
	}

	onChange(editorState, initial) {
		const raw = editorState;//convertToRaw(editorState.getCurrentContent());
		const content = raw;//.blocks.map(b => b.text).join("");
		const hasContent = this.hasContent(content);
		const overflown = this.isOverflown();
		this.setState({hasContent: hasContent, isOverflown: overflown});

		if (this.props.onChange) {
			if (this.props.exchangeDataAs === "editorState") {
				this.props.onChange(editorState, overflown, initial);
			} else {
				this.props.onChange(raw, overflown, initial);
			}
		}
	}

	hasContent(slateContent) {
		if (slateContent && Array.isArray(slateContent)) {
			if (slateContent.length > 1) {
				return true;
			}
			for (let element in slateContent) {
				if (!element.type) {
					continue;
				}
				if (element.type === 'text' || element.type.startsWith('heading')) {
					const reducer = (accumulator, currentValue) => accumulator + currentValue.text;
					if (element.reduce(reducer,"").length > 0) {
						return true;
					}
				} else if (element.type === 'table-wrapper' || element.type === 'image-wrapper') {
					return true;
				}
			}
		} 
		return false;
		
	}

	styleToCss(styleObj) {
		const parser = this.createParser(/[A-Z]/, match => `-${match.toLowerCase()}`);
		if (!styleObj || typeof styleObj !== 'object' || Array.isArray(styleObj)) {
			throw new TypeError(`expected an argument of type object, but got ${typeof styleObj}`);
		}
		const lines = Object.keys(styleObj).map(property => `${parser(property)}: ${styleObj[property]};`);
		return lines.join('\n');
	}

	createParser(matcher, replacer) {
		const regex = RegExp(matcher, 'g');
		return string => {
			// * throw an error if not a string
			if (typeof string !== 'string') {
				throw new TypeError(`expected an argument of type string, but got ${typeof styleObj}`);
			}
		
			// * if no match between string and matcher
			if (!string.match(regex)) {
				return string;
			}

			// * executes the replacer function for each match
			// ? replacer can take any arguments valid for String.prototype.replace
			return string.replace(regex, replacer);
		};
	}
	
	isOverflown() {
		if (this.props.flowing) {
			return false;
		}
		const element = this.divRef.current;
		const innerElement = element.querySelector('div[data-slate-editor=true]');
		if (this.props.overflow) {
			return;
		} else {
			if (innerElement) {
				if (innerElement.scrollHeight > innerElement.clientHeight) {
					innerElement.classList.add("overflown");
					return true;
				} else {
					innerElement.classList.remove("overflown");
				}
			}
		}
		const padding = 10;
		return element.scrollHeight > (element.clientHeight + padding);


	}

	convertStyleToHyphen(element) {
		if (element === 'tableFooter') return 'table-footer';
		if (element === 'tableHeader') return 'table-header';
		if (element === 'imageHeader') return 'image-header';
		if (element === 'imageFooter') return 'image-footer';
		if (element === 'td') return 'table-cell';
		
		return element;
	}

	render() {

		let className = "editable-text";
		let editStateWrapperClass = "editor-edit-state-wrapper";
		if (this.state.hasContent) {
			className += " filled";
		}
		if (this.state.isOverflown) {
			editStateWrapperClass += " overflown";
		}
		if (this.state.editorClicked) {
			editStateWrapperClass += " clicked";
		}

		/*const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
			return scrollHeight > clientHeight || scrollWidth > clientWidth;
		}*/

		const stylesMap = {
			p: "p[data-slate-node=element]",
			li: "li[data-slate-node=element]",
			h1: "h1",
			h2: "h2",
			h3: "h3",
			td: "div.table-cell",
			table: "table.table",
			th: "th"
		};

		let injectedStyles = Object.keys(this.props.myStyles).reduce((collector, element)=>{
			const target = stylesMap[element];
			const hyphenedElement = this.convertStyleToHyphen(element);
			if (!target || typeof target !== Array) {
				collector += `#${this.id} .threeskye-editor ${target || ("." + hyphenedElement)} {${this.styleToCss(this.props.myStyles[element])}} `;
			} else {
				target.forEach(aTarget=>{
					collector += `#${this.id} .threeskye-editor ${aTarget} {${this.styleToCss(this.props.myStyles[element])}} `;
				});
			}
			return collector; 
		}, "");

		//"Exceptions to the rule"
		if (this.props.myStyles["li"] && this.props.myStyles["li"].indent) {
			injectedStyles += "ul[data-slate-node=element] {padding-left: " + this.props.myStyles["li"].indent + "pt} ";
		}

		const wrapperStyle = {
			width: this.props.width + 'pt',
			height: this.props.height + 'pt'
		}

		return ( 
			<>
			
			<style>
				{injectedStyles}
			</style>
{/* 			<div id={this.id}>	

 */}		<div className={editStateWrapperClass} ref={this.parentDivRef} >
				<div
					ref={this.divRef}
					id={this.id}
					tabIndex="0"
					onBlur={() => this.setState({editorClicked: false})}
					onFocus={() => this.setState({editorClicked: true})}
					className={className}
					style={wrapperStyle}
				>			<SlateEditor readOnly={this.props.readOnly} load={this.state.raw} onChange={this.onChange} addins={this.props.addins || []} setToolbar={this.props.setToolbar} toolbar={this.props.toolbar} colourOptions={this.props.colourOptions} throwToaster={this.props.throwToaster}/>
	
{/* 					<ThreeSkyeEditor 
						options={this.props.options} 
						onChange={this.onChange} 
						load={this.state.raw} 
						onLoad={this.onLoad} 
						stage={this.props.stage || 'templateBuilder'}
						exchangeDataAs="editorState"
					/>
 */}</div>
			</div>
			</>
		);
	}


}	
