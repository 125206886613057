import React, { Component } from 'react';

import './sheets-of-paper.css';
import './sheets-of-paper-a4.css';
import './FlowingPage.scss';
import Editor from './contents/Editor.js';
import { debounce, throttle } from 'throttle-debounce';
import { withData } from './DataController.js';
import { getEditableStyles, getTableStyles } from './components/Shared/EditableUtils.js';
import { sidebysides, simpleInlineStyles } from '@threeskye/editor-component-slate';
import { ReactEditor } from 'slate-react';
import TableRowStyleMenuItem from '@threeskye/editor-component-slate/dist/engine/context/table/TableRowStyleMenuItem';
import TableColumnStyleMenuItem from '@threeskye/editor-component-slate/dist/engine/context/table/TableColumnStyleMenuItem';
import TableStyleMenuItem from '@threeskye/editor-component-slate/dist/engine/context/table/TableStyleMenuItem';
import SBSTableWrapperStyleMenuItem from '@threeskye/editor-component-slate/dist/engine/context/sbs/SBSTableWrapperStyleMenuItem';
import EditorSubMenu from '@threeskye/editor-component-slate/dist/engine/context/EditorSubMenu';
import { OUT_OF_SIDE_BY_SIDE } from "@threeskye/editor-component-slate/dist/engine/context/sbs/SideBySideContextMenuItemVisibility";
import SideBySideStyleMenuItem from "@threeskye/editor-component-slate/dist/engine/context/sbs/SideBySideStyleMenuItem";
import SBSImageWrapperStyleMenuItem from "@threeskye/editor-component-slate/dist/engine/context/sbs/SBSImageWrapperStyleMenuItem";
import withPageBreaks from './withPageBreaks';
import { Node as SlateNode } from 'slate';
import WidthFullSVG from "./components/Icons/WidthFull.js"
import FitWidthSVG from "./components/Icons/FitWidthSVG.js"
import { addListItemOnEnter } from '@threeskye/editor-component-slate/dist/menu/Utils.js'
import { toastSuccess } from './components/popups/Toast.js'

class FlowingPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editorKey: "testkey",
			content: null,
			pages: 1,
			pageBreaks: [],
			manualPageBreaks: [],
			handleRefs: [],
			divRef: React.createRef(),
			editor: null,
			toolbar: { type: "full" }
		}

		this.onChange = this.onChange.bind(this);
		this.save = debounce(1500, this.save.bind(this));

		this.heightChange = debounce(500, this.heightChange.bind(this));//throttle(100, this.heightChange.bind(this));
		this.renderChildren = this.renderChildren.bind(this);

		this.boundaryMouseOver = this.boundaryMouseOver.bind(this);
		this.boundaryMouseDown = this.boundaryMouseDown.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseMoveImpl = throttle(100, this.mouseMoveImpl.bind(this));

		this.setToolbar = this.setToolbar.bind(this);
		this.throwToaster = this.throwToaster.bind(this);

		this.listener = this.listener.bind(this);
		this.handleBoundaryRightClick = this.handleBoundaryRightClick.bind(this);
		this.snapPageBreak = this.snapPageBreak.bind(this);
		this.snapPageBreakInsideText = this.snapPageBreakInsideText.bind(this);
		this.snapInsideTextWithMultipleLeaves = this.snapInsideTextWithMultipleLeaves.bind(this);

		this.editableStyles = getEditableStyles(props.styles, props.flowingConfig.styles, props.flowingConfig.tableStyles, props.data);
		this.tableStyles = getTableStyles(props.styles, props.flowingConfig.rowStyleOptions, props.flowingConfig.columnStyleOptions, props.data);
		this.tableStyleRef = React.createRef();


	}

	componentDidMount() {
		this.props.data.addLocalListener(this.listener);
		this.listener();
	}

	componentWillUnmount() {
		this.props.data.removeLocalListener(this.listener);
	}

	listener() {
		if (!this.props.data.localContentLoaded) {
			return new Promise((resolve) => resolve(false));
		}

		const config = this.props.flowingConfig;
		const dataName = config.dataName;
		const content = this.props.data.getLocalValue(dataName);
		const storedPageBreaks = this.props.data.getLocalValue(dataName + 'PageBreaks');

		const pageBreaks = [];


		const manualPageBreaks = [];
		if (content && content !== null && content !== "") {
			//FIXME editor not handling updating props, so change whole component. BAD! fix in editor.
			const parsed = JSON.parse(content);
			try {
				if (storedPageBreaks) {
					JSON.parse(storedPageBreaks).forEach(pb => {
						if (pb.id) {
							manualPageBreaks.push(pb);
						} else {
							pageBreaks.push(pb);
						}
					})
				}


				if (parsed.pageBreaks) {
					const entityMap = parsed.entityMap;
					parsed.pageBreaks.forEach(pb => {
						if (pb.id) {
							//Sanity check that breaks are actually valid
							if (Object.keys(entityMap).find(key => entityMap[key].data.pageBreakId === pb.pageBreakId)) {
								manualPageBreaks.push(pb);
							} else {
								console.log("Rejecting with no matching entity: " + pb.id);
							}
						} else {
							pageBreaks.push(pb);
						}
					})
				}
			} catch (e) {
				console.log(e);
			}
			this.setState({ content: parsed, editorKey: this.state.editorKey + 1, pageBreaks, manualPageBreaks });
		} else {
			this.setState({ content: null, editorKey: this.state.editorKey + 1, pageBreaks, manualPageBreaks });
		}
		//TODO - trigger the return on setState callback above.  Brain fried can't figure out how right now.
		return new Promise((resolve) => resolve(true));
	}

	onChange(data, isTransient) {

		if (!data) {
			console.log("Not saving because no data.", data, isTransient);
			return;
		}
		this.data = data;
		const safify = b => {
			const { ref, ...safe } = b;
			return safe;
		};

		//TODO - add pagebreak info to data.
		this.save(data);

		if (!isTransient) {
			this.setState({ content: this.data }, () => {
				const element = this.state.divRef.current;
				//This needs to be delayed long enough for the render resultant from above onChange to be actually completed
				//TODO can this be handled with a forceUpdate on the FlowingPage (not used by EditableText)...
				if (element) {
					// setTimeout(()=>{
					let actualHeight = 0;
					const elements = element.querySelectorAll('[data-slate-node=value] > [data-slate-node=element]');

					//fixme this should also check padding top&bottom and margin top
					elements.forEach(el => {
						actualHeight += (el.clientHeight + parseFloat(window.getComputedStyle(el).getPropertyValue('margin-bottom'), 10));
						if (el.nodeName === 'UL' || el.nodeName === 'OL') {
							//bottom margin of 1 li is also applied, but not included in the client height.
							if (el.children.length > 0) {
								actualHeight += parseInt(window.getComputedStyle(el.children[0]).getPropertyValue('margin-bottom'), 10);
							}
						}
					});
					this.setState({ actualHeight }, () => this.heightChange(actualHeight));
				}
			});
		}

	}

	save(data) {
		const dataName = this.props.flowingConfig.dataName;
		this.props.data.updateLocalData(dataName, JSON.stringify(data));
		const allBoundaries = this.state.pageBreaks.concat(this.state.manualPageBreaks);

		setTimeout(function (data, dataName, allBoundaries) {
			data.updateLocalData(dataName + 'PageBreaks', JSON.stringify(allBoundaries, (k, v) => { if (k === 'ref') { return undefined } return v; }));
		}, 1500, this.props.data, dataName, allBoundaries);
	}
	reflowAllEditors(contentState) {

	}

	heightChange(editorHeight) {
		if (!editorHeight || !this.data) {
			return;
		}
		this.height = editorHeight;
		const margins = this.props.flowingConfig.margins;
		const size = this.props.pageSize;

		const baseHeightInPxs = this.getBaseHeightInPixels(margins, size);
		const baseHeightInPts = this.getBaseHeightInPts(margins, size);
		const requiredPages = Math.ceil(editorHeight / baseHeightInPxs);

		let currentHeight = 0;
		let manual = this.state.manualPageBreaks;
		const autos = [];

		//Remove all autos from the editorstate
		const editorState = this.data;
		let content = this.data;//editorState.getCurrentContent();

		//Get dom position
		const outerEl = this.state.divRef.current;
		if (!outerEl) {
			console.warn("Can't get outer editor element!");
			return;
		}
		const blocks = outerEl.querySelectorAll('[data-slate-node=element]');

		const offset = blocks.item(0).getBoundingClientRect().y;

		//move any manuals
		const allPageBreaks = this.state.editor.getAllPageBreaks(this.state.content.length);
		const updateManualBreaks = [];

		//if the mouse is down on a break, we're dragging, don't update anything!
		if (!this.state.mouseDown) {
			for (let breakEntity of allPageBreaks) {
				try {
					const domNode = ReactEditor.toDOMNode(this.state.editor, breakEntity[0]);
					let textNode = domNode.firstChild
					while (textNode.nodeType === 1) {
						textNode = textNode.firstChild
					}
					//let point = ReactEditor.toDOMPoint(this.state.editor, {path: breakEntity[1], offset: blockInsideSlate.offset});
					breakEntity[0].pageBreaks.forEach(storedBreak => {
						const index = manual.findIndex(b => b.pageBreakId === storedBreak.id);
						if (index < 0) {
							console.error("Unable to find a record matching the entity break ", storedBreak);
							this.removePageBreakEntity({ pageBreakId: storedBreak.id });
							return;
						}
						if (storedBreak.offset === 0) {
							manual[index].pos = (domNode.getBoundingClientRect().y - offset) / 1.3333;
							manual[index].at = breakEntity[1];
							updateManualBreaks.push(manual[index]);
						} else {
							//TODO offset
							let dataSlateTextNode = domNode.firstChild;
							let totalCharacterOffset = 0;
							while (dataSlateTextNode && (totalCharacterOffset + dataSlateTextNode.textContent.length < storedBreak.offset)) {
								totalCharacterOffset += dataSlateTextNode.textContent.length;
								dataSlateTextNode = dataSlateTextNode.nextSibling;
								if (dataSlateTextNode == null) {
									//reached the end - we've probably had a split.
									console.error("Probably been a split, can't find offset " + storedBreak.offset, storedBreak)
									//TODO FIXME, actually handle moving it to the next!!
									//too tired to face that right now, just deleting it.
									this.removePageBreakEntity({ pageBreakId: storedBreak.id });
									return;
								}
							}

							let textNode = dataSlateTextNode.firstChild.firstChild
							while (textNode.nodeType === 1) {
								textNode = textNode.firstChild
							}
							const text = SlateNode.string(breakEntity[0])
							let start = 0;
							let end = text.length;
							const range = document.createRange();
							const nodeText = textNode.textContent;
							const rangeOffset = storedBreak.offset - totalCharacterOffset;
							if (rangeOffset + 1 >= nodeText.length) {
								range.setStart(textNode, nodeText.length - 2);
								range.setEnd(textNode, nodeText.length - 1);
							} else {
								range.setStart(textNode, rangeOffset);
								range.setEnd(textNode, rangeOffset + 1);
							}
							manual[index].pos = (range.getBoundingClientRect().y - offset) / 1.3333;
							manual[index].at = breakEntity[1];
							updateManualBreaks.push(manual[index]);
						}
					});
				} catch (e) {
					console.log("break doesn't match!", breakEntity, e);
				}
			}

			this.setState({ manualPageBreaks: updateManualBreaks });
		}	//end mousedown
		this.handlingPageBreaks = true;
		while (currentHeight < editorHeight) {
			let maxPage = currentHeight + baseHeightInPxs;
			let nextManual = manual.find(e => this.ptsToPxls(e.pos) > currentHeight && this.ptsToPxls(e.pos) < maxPage);
			if (nextManual) {
				currentHeight = this.ptsToPxls(nextManual.pos);
			} else {
				const newAuto = {
					pos: this.pxlsToPts(currentHeight) + baseHeightInPts,
					ref: React.createRef(),
					pageBreakId: Math.round(1 + Math.random() * 10000000)
				};

				this.snapPageBreak(newAuto);
				if (currentHeight === this.ptsToPxls(newAuto.pos)) {
					this.snapPageBreak(newAuto, true);
				}

				autos.push(newAuto);
				currentHeight = this.ptsToPxls(newAuto.pos);
			}
		}

		//remove last auto - TODO fix.
		autos.pop();
		this.setState({ pageBreaks: autos, editorHeight, content: this.data, pages: requiredPages || 1 }, () => this.handlingPageBreaks = false/*this.forceUpdate()*/);
		;

	}

	removePageBreakEntity(theBreak) {
		let index = 0;
		for (let block of this.state.content) {
			if (block.pageBreaks && block.pageBreaks.length > 0) {
				for (let y of block.pageBreaks) {
					if (y.id === theBreak.pageBreakId) {
						this.state.editor.removePageBreak(theBreak, [index]);
					}
				}

			}
			if (block.type === 'bulleted-list' || block.type === 'numbered-list') {
				let listIndex = 0;
				for (let child of block.children) {
					if (child.pageBreaks && child.pageBreaks.length > 0) {
						for (let y of child.pageBreaks) {
							if (y.id === theBreak.pageBreakId) {
								this.state.editor.removePageBreak(theBreak, [index, listIndex]);
							}
						}
					}
					listIndex++
				}
			}
			index++;
		}


	}

	//TODO conversion not (necesarily) safe
	ptsToPxls(pts) {
		return pts * 4 / 3;
	}

	//TODO conversion not (necesarily) safe
	pxlsToPts(px) {
		return px * 3 / 4;
	}

	boundaryMouseOver(e, id) {

	}

	boundaryMouseDown(e, id) {
		if (e.button === 2) {
			return;
		}

		const manualPageBreaks = this.state.manualPageBreaks;
		if (id.id) {

		} else {
			id.id = Math.ceil(Math.random() * 100000);
			id.manual = true;
			manualPageBreaks.push(id);
		}
		this.setState({
			mouseDown: {
				startCursor: 1,
				id: id.id
			}, manualPageBreaks
		});
	}

	setToolbar(toolbar) {
		this.setState({ toolbar: toolbar })
	}

	throwToaster() {
		toastSuccess('Link Copied')
	}

	mouseUp(e) {
		if (!this.state.mouseDown) {
			return;
		}

		const mouseDown = this.state.mouseDown;
		const manualPageBreaks = this.state.manualPageBreaks
		//get the actual pos of this one (this isn't updated in the mousedown object)
		const theBreak = manualPageBreaks.find(item => mouseDown.id === item.id);
		if (!theBreak) {
			console.log("Unable to find break ", mouseDown.id);
			return;
		}
		const mouseDownPos = theBreak.pos;
		const allBoundaries = this.state.pageBreaks.concat(this.state.manualPageBreaks);

		const onTopOfAnother = allBoundaries.find(other => other.id !== mouseDown.id && (Math.abs(other.pos - mouseDownPos) < 10));

		this.removePageBreakEntity(theBreak);
		if (onTopOfAnother) {
			//remove this one.
			const index = manualPageBreaks.findIndex(manual => manual.id === mouseDown.id);
			const theBreak = manualPageBreaks.splice(index, 1)[0];
			//And remove the entity
			this.removePageBreakEntity(theBreak);
		} else {
			this.snapPageBreak(theBreak, false, true);

			this.state.editor.addPageBreak(theBreak, theBreak.at);
		}


		this.setState({ mouseDown: null, manualPageBreaks });
		this.heightChange(this.height);
		this.onChange(this.data);

	}

	createPageBreakEntity(block, theBreak, offset) {
		if (this.pageBreakIteration) {
			this.pageBreakIteration += 1;
		} else {
			this.pageBreakIteration = 1;
		}

		theBreak.at = ReactEditor.findPath(this.state.editor, block);
		theBreak.offset = offset;

		return true;
	}

	getBlockElement(blockKey) {
		const outerEl = this.state.divRef.current;
		if (!outerEl) {
			console.error("no ref, cannot locate");
		}

		const blocks = outerEl.querySelectorAll("[data-block]");
		for (let i = 0; i < blocks.length; i++) {
			const block = blocks.item(i);
			let thisKey = block.dataset.offsetKey;
			thisKey = thisKey.substring(0, thisKey.length - 4);
			if (thisKey === blockKey) {
				return block;
			}
		}
		return null;
	}

	snapPageBreakInsideText(content, pos, offset, theBreak, slateNode, blockPosition) {
		//OPTIMISATION ATTEMPT - BINARY SEARCH INSTEAD OF LINEAR
		const text = content.innerText;
		let textNode = content.firstChild.firstChild;
		while (textNode.nodeType === 1) {
			textNode = textNode.firstChild
		}
		let start = 0;
		let end = text.length;

		const range = document.createRange();
		while (end - start > 1) { //Break occurs exactly at end of block will never find a match
			const mid = Math.floor((start + end) / 2);

			//CURRENT VERSION USES AN EMPTY SELECTION TO GET BOUNDS
			//THIS WORKS FINE IN CHROME BUT MAY BREAK IN OTHER BROWSERS.  IF SO, FOLLOWING CODE PROVIDES WORD SELECTION
			// let wordStart = text.lastIndexOf(" ", mid);
			// if (wordStart === -1)
			// 	wordStart = 0;

			// let wordEnd;
			// if (text.substring(wordStart, wordStart + 1) === "&") {
			// 	wordEnd = wordStart + 1;
			// } else {
			// 	wordEnd = text.indexOf(" ", wordStart+1);
			// 	if (wordEnd === -1 || wordEnd >= text.length) {
			// 		wordEnd = text.length-1;
			// 	}
			// }

			// //Create a range around the word we have found
			// range.setStart(textNode, wordStart);
			// range.setEnd(textNode, wordEnd);
			//END
			range.setStart(textNode, mid);
			range.setEnd(textNode, mid);
			const wordStart = mid;
			const wordEnd = mid;
			const wordBounds = range.getBoundingClientRect();
			const top = wordBounds.y - offset;
			if (top === pos || top + wordBounds.height === pos) {
				//Already on a line break
				this.createPageBreakEntity(slateNode, theBreak, this.startOfLine(wordStart, range, textNode) + blockPosition);
				return true;
			} else if (top + wordBounds.height < pos) {
				//Break occurs _after_ the word
				//Move the start of the search to the end of the current word.
				start = wordEnd + 1;
			} else if (top > pos) {
				//Break occurs _before_ the word
				//Move the end to the start of teh current word
				end = wordStart - 1;
			} else if (top < pos && top + wordBounds.height > pos) {
				//Break is on the line, but mid line
				theBreak.pos = top / 1.3333;
				this.createPageBreakEntity(slateNode, theBreak, this.startOfLine(wordStart, range, textNode) + blockPosition);
				return true;
			}
		}
		return false;
	}

	snapInsideTextWithMultipleLeaves(offset, pos, domNode, theBreak) {
		const slateNode = ReactEditor.toSlateNode(this.state.editor, domNode);
		const content = domNode.querySelectorAll("[data-slate-leaf=true]");
		let blockPosition = 0;
		for (let cont of content) {
			const contentBounds = cont.getBoundingClientRect();
			if (contentBounds.y - offset < pos && (contentBounds.y + contentBounds.height) - offset > pos) {
				if (this.snapPageBreakInsideText(cont, pos, offset, theBreak, slateNode, blockPosition)) {
					return true;
				}
			}
			blockPosition += cont.innerText.length;
		}
		return false;
	}

	snapPageBreak(theBreak, forceNextBlock, manual) {
		//		return true;
		//Snap ...
		const outerEl = this.state.divRef.current;
		if (!outerEl) {
			console.error("no ref, cannot locate");
		}

		const pos = theBreak.pos * 1.3333;  //pts to pixels
		const blocks = outerEl.querySelectorAll("[data-slate-editor=true] > [data-slate-node=element]");//[data-slate-node=element]");
		const offset = blocks.item(0).getBoundingClientRect().y;
		for (let j = 0; j < blocks.length; j++) {
			const block = blocks.item(j);
			const bounds = block.getBoundingClientRect();
			if ((bounds.y + bounds.height) - offset > pos) {
				//In or above this block

				if (forceNextBlock) {

					const next = blocks.item(j + 1);
					if (next && next.getBoundingClientRect().height > 900) {
						console.error("Block is too big to fit on page");
						theBreak.pos = (bounds.y - offset) / 1.3333;
						//avoid infinite loop;
						continue;
					}
					theBreak.pos = ((bounds.y + bounds.height) - offset) / 1.3333;
					break;
				}
				//Get the block key

				let content = block.querySelectorAll("[data-slate-leaf=true]");
				const slateNode = ReactEditor.toSlateNode(this.state.editor, block);
				if (bounds.y - offset > pos) {
					//Falls inbetween blocks - push it down to the top of this one
					if (block.nodeName !== "DIV" && (content.length === 0 || content.item(0).nodeName === "BR")) {
						//No actual content (empty paragraph) - push it down to the next one that has content
						continue;
					}
					theBreak.pos = (bounds.y - offset) / 1.3333;
					this.createPageBreakEntity(slateNode, theBreak, 0);
					break;
				}
				if (block.nodeName === "DIV") {
					//Atomic block
					theBreak.pos = (bounds.y - offset) / 1.3333;
					if (!this.createPageBreakEntity(slateNode, theBreak, 0)) {
						//False means that the entity could not be created - ie it already exists
						//which means that the atomic block is taller than the page
						//...so to avoid infinite loop we'll push this break to the next block
						// by just not handling it.  Next block will assume it's in the gap and pick it up
						continue;
					} else {
						break;
					}
				} else {
					let initialOffset = 0;
					if (block.nodeName === "UL" || block.nodeName === "OL") {
						const lis = block.querySelectorAll("[data-slate-node=element]");
						for (let liIterator = 0; liIterator < lis.length; liIterator++) {
							const li = lis.item(liIterator);
							const liBounds = li.getBoundingClientRect();
							if ((liBounds.y + liBounds.height) - offset > pos) {
								//in or above this li
								if (liBounds.y - offset > pos) {
									//Falls inbetween blocks - push it down to the top of this one
									theBreak.pos = (liBounds.y - offset) / 1.3333;
									const liSlateNode = ReactEditor.toSlateNode(this.state.editor, li);
									this.createPageBreakEntity(liSlateNode, theBreak, 0);
									return;
								} else {
									if (this.snapInsideTextWithMultipleLeaves(offset, pos, li, theBreak)) {
										return
									}

								}
							} else {
								initialOffset += li.innerText.length;
							}
						}
						//didn't find it!
						console.error("Didn't find place in an li. dropping through");
						continue;

					}
					if (content.length === 0 || content.item(0).nodeName === "BR") {
						//No actual content (empty paragraph) - push it down to the next one that has content
						continue;
					}
					if (this.snapInsideTextWithMultipleLeaves(offset, pos, block, theBreak)) {
						return
					}
					this.createPageBreakEntity(slateNode, theBreak, 0);
					break;
				}
			}
		}
		return true;
	}

	startOfLine(pos, range, textNode) {
		const y = range.getBoundingClientRect().y;

		//Average line is 100 chars, so we'll start back in multiples of 100 then binary inwards

		//Find a starting point
		let end = pos;
		let start = Math.max(end - 100, 0);
		if (start === 0) {
			return 0;
		}

		if (start > textNode.length) {
			start = textNode.length - 1;
		}
		if (end > textNode.length) {
			end = textNode.length
		}
		range.setStart(textNode, start);
		range.setEnd(textNode, end);
		while (range.getBoundingClientRect().y === y) {
			end = start;
			start = Math.max(end - 100, 0);
		}

		//We now know that break is somewhere between start and end
		while (start < end) {
			const mid = Math.floor((start + end) / 2);
			range.setStart(textNode, mid);
			range.setEnd(textNode, end);
			if (range.getBoundingClientRect().y === y) {
				//console.log(start, mid, end, "Before", range.getBoundingClientRect().y, y);
				//Break is before mid
				end = mid;
			} else {
				//console.log(start, mid, end, "After", range.getBoundingClientRect().y, y);
				//Break is after mid
				//Ensure we don't hit infinite loops due to floor
				if (start === mid) {
					return end;
				}
				start = mid;

			}
		}
		return start;
	}

	mouseMove(e) {
		if (!this.state.mouseDown) {
			return;
		}
		const ref = this.state.divRef;
		const refPts = this.pxlsToPts(e.clientY - ref.current.getBoundingClientRect().top);

		this.mouseMoveImpl(refPts);
		//this.onChange(this.data, true);
	}

	mouseMoveImpl(refPts) {
		if (!this.state.mouseDown) {
			return;
		}
		const mouseDown = this.state.mouseDown;
		mouseDown.pos = this.ptsToPxls(refPts);
		const manualPageBreaks = this.state.manualPageBreaks;
		const editing = manualPageBreaks.find(el => el.id === mouseDown.id);
		editing.pos = refPts;// this.pxlsToPts(pos);

		this.setState({ mouseDown, manualPageBreaks });
	}

	//TODO conversion not (necesarily) safe! But we need to somehow get from points to pixels, is there a safe way?
	getBaseHeightInPixels(margins, size) {
		const baseHeightInPts = size.height - margins.top - margins.bottom;
		const baseHeightInPxs = baseHeightInPts * 4 / 3;
		return baseHeightInPxs;
	}
	getBaseHeightInPts(margins, size) {
		return size.height - margins.top - margins.bottom;
	}

	//given (flow-margins)  (if component.top > flow-margins. bottom) render relative to top of page. (else) render relative to bottom of page.  
	renderChildren(lastBoundary) {
		const margins = this.props.flowingConfig.margins;
		const size = this.props.pageSize;
		const flowBottom = size.height - margins.bottom;
		// const baseHeightInPts = size.height - margins.top - margins.bottom;


		return this.props.children.map((child) => {
			const y = child.props.y;
			if (y > flowBottom) {
				return React.cloneElement(child, {
					y: lastBoundary + y
				});
			} else {
				return child;
			}
		});

	}

	handleBoundaryRightClick(e, boundary) {
		if (!boundary.id) {
			return;
		}
		if (e.type === 'contextmenu') {
			e.preventDefault();
			const manualPageBreaks = this.state.manualPageBreaks;
			const index = manualPageBreaks.findIndex(val => val.id === boundary.id);
			const theBreak = manualPageBreaks.splice(index, 1)[0];
			this.removePageBreakEntity(theBreak);
			this.setState({ manualPageBreaks });
			this.heightChange(this.height);
			this.onChange(this.data);
		}

	}

	render() {
		const margins = this.props.flowingConfig.margins;
		const size = this.props.pageSize;

		const width = size.width - margins.left - margins.right;
		const baseHeight = size.height - margins.top - margins.bottom;
		const allBoundaries = this.state.pageBreaks.concat(this.state.manualPageBreaks);

		const lastBoundary = allBoundaries.reduce((prev, curr) => curr.pos > prev ? curr.pos : prev, 0);

		const height = baseHeight + lastBoundary;//* this.state.pages;
		const pageBoundaries = [];
		//console.log(this.state.pageBreaks, allBoundaries);
		for (let i = 0; i < allBoundaries.length; i++) {
			/*if (allBoundaries[i].pos > this.pxlsToPts(this.state.editorHeight)) {
				console.log("Ignoring a boundary with pos ", allBoundaries[i].pos);
				continue;
			}*/
			pageBoundaries.push(<div ref={allBoundaries[i].ref} key={"boundaryR" + i} data-pb-id={allBoundaries[i].pageBreakId} data-pos={allBoundaries[i].pos} className={"page-boundary" + (allBoundaries[i].id ? " manual" : "")} onMouseDown={(e) => this.boundaryMouseDown(e, allBoundaries[i])} onMouseOver={(e) => this.boundaryMouseOver(e, allBoundaries[i])} style={{
				position: "absolute",
				top: (allBoundaries[i].pos) + "pt",
				left: "-" + margins.left + "pt",
				width: size.width + "pt"
			}}><div className="drag-handle-wrapper left"><div className="draghandle-tooltip">Right click to remove</div><i className="material-icons drag-handle-button faded" onContextMenu={(e) => this.handleBoundaryRightClick(e, allBoundaries[i])}>height</i></div><div className="drag-handle-wrapper right"><div className="draghandle-tooltip">Right click to remove</div><i className="material-icons drag-handle-button rhs faded" onContextMenu={(e) => this.handleBoundaryRightClick(e, allBoundaries[i])}>height</i></div></div>)
		}
		const myStyles = this.editableStyles;
		Object.assign(myStyles, this.tableStyles);
		const { allowFullWidthTable } = this.props.flowingConfig;
		const options = {};

		//		const borderStyle = this.props.flowingConfig.border;

		if (allowFullWidthTable) {
			const marginLeft = this.props.flowingConfig.fullWidthTableMargins.left;
			const marginRight = this.props.flowingConfig.fullWidthTableMargins.right;

			myStyles["page-width"] = {
				"position": "relative",
				"left": (marginLeft - margins.left) + 'pt',
				"width": (size.width - marginLeft - marginRight) + 'pt',
				"background": "white"
			};

			myStyles["full-width"] = {
				"position": "relative",
				"left": (marginLeft - margins.left) + 'pt',
				"width": (size.width - marginLeft - marginRight) + 'pt',
				"background": "white"
			};
			myStyles["table-border"] = {
				"border": "1px solid #004271"
			};
			myStyles["full-width-table-border"] = {
				"position": "relative",
				"left": (marginLeft - margins.left) + 'pt',
				"width": (size.width - marginLeft - marginRight) + 'pt',
				"background": "white",
				"border": "1px solid #004271"
			};
			myStyles["image-border"] = {
				"border-top": "1.25pt solid #C1CBDE",
				"border-bottom": "1.25pt solid #C1CBDE",
				"padding-top": "5pt",
				"margin-bottom": "3pt"
			};

			//FIXME why are these hardcoded? Should be read from template
			myStyles["text-width-table-border"] = {
				"border": "1px solid #004271"
			};
			myStyles["text-width-image-border"] = {
				"border-top": "1.25pt solid #C1CBDE",
				"border-bottom": "1.25pt solid #C1CBDE",
				"padding-top": "5pt",
				"padding-bottom": "5pt"
			};


			["tableStyleOptions"].forEach(key => {
				options[key] = [
					{
						label: "Text Width",
						key: "text-width"
					},
					{
						label: "Page Width",
						key: "full-width"
					},
					{
						label: "Text Width (with Border)",
						key: "text-width-table-border"
					},
					{
						label: "Page Width (with Border)",
						key: "full-width-table-border"
					}
				];
			});

			["imageStyleOptions", "sideBySideStyleOptions"].forEach(key => {
				options[key] = [
					{
						label: "Text Width",
						key: "text-width"
					},
					{
						label: "Page Width",
						key: "full-width"
					},
					{
						label: "Text Width (with Border)",
						key: "text-width-image-border"
					},
					{
						label: "Page Width (with Border)",
						key: "full-width-image-border"
					}
				]
			});
		}

		if (this.props.flowingConfig.rowStyleOptions) {
			options.rowStyleOptions = this.props.flowingConfig.rowStyleOptions;

		}
		if (this.props.flowingConfig.columnStyleOptions) {
			options.columnStyleOptions = this.props.flowingConfig.columnStyleOptions;
		}

		const rowStyles = options.rowStyleOptions.map((option, idx) => <TableRowStyleMenuItem key={idx} styleName={option.key} text={option.label} showPreview={true} />)
		const columStyles = options.columnStyleOptions.map((option, idx) => <TableColumnStyleMenuItem key={idx} styleName={option.key} text={option.label} showPreview={true} />)
		const menuItems = [
			<EditorSubMenu title='Row styles'>
				{rowStyles}
			</EditorSubMenu>,
			<EditorSubMenu title='Column styles'>
				{columStyles}
			</EditorSubMenu>,
			<TableStyleMenuItem styleName="bordered-table" text="With Border" />,
			<SBSTableWrapperStyleMenuItem visibility={OUT_OF_SIDE_BY_SIDE} styleName="text-width" text="Text Width" />,
			<SBSTableWrapperStyleMenuItem visibility={OUT_OF_SIDE_BY_SIDE} styleName="page-width" text="Page Width" />,
			<SideBySideStyleMenuItem styleName="text-width" text="Text Width" />,
			<SideBySideStyleMenuItem styleName="page-width" text="Page Width" />,
			<SideBySideStyleMenuItem styleName="text-width-with-border" text="Text Width with Border" />,
			<SideBySideStyleMenuItem styleName="page-width-with-border" text="Page Width with Border" />
		];

		//		const { setTableColumnStyle } = useTableStyleFunctions();
		//Tables
		const tableOptions = {
			initialFooter: true,
			menuItems: menuItems,
			styleRef: this.tableStyleRef,
			styleInterpreter: style => {
				//console.log("Looking for style ", style)
				if (style === 'page-width') {
					return myStyles["full-width"]
				}
				if (style === 'bordered-table') {
					return myStyles["table-border"]
				}

				if (style === 'page-width') {
					return myStyles["full-width"]
				}
				if (style === 'page-width-with-border') {
					const joined = Object.assign({}, myStyles["full-width"], myStyles["image-border"]);
					return joined;
				}
				if (style === 'text-width-with-border') {
					return myStyles["image-border"]
				}

				for (const { label, key } of options.rowStyleOptions) {
					if (style === key) {
						//console.log("Found it as a row style")
						return myStyles[key];
					}
				}
				for (const { label, key } of options.columnStyleOptions) {
					if (style === key) {
						//console.log("Found it as a column style")
						return myStyles[key];
					}
				}

			}
		};

		const headings = {
			install: editor => {
				return {
					getRenderer: (type, editor) => {
						if (type === "heading-one")
							return ({ attributes, children }) => <h1 {...attributes}>{children}</h1>
						else if (type === "heading-two")
							return ({ attributes, children }) => <h2 {...attributes}>{children}</h2>
						else if (type === "heading-three")
							return ({ attributes, children }) => <h3 {...attributes}>{children}</h3>
						else
							return null;
					},
					editor: editor

				}
			}
		}

		const lists = {
			install: editor => {
				return {
					getRenderer: (type, editor) => {
						if (type === "list-item")
							return ({ attributes, children }) => {
								const updatechildren = children.map((child) => {
									if (child.props.text && child.props.text.url !== undefined) {
										const url = child.props.text.url
										const href = (url.includes('http://') || url.includes('https://')) ? url : 'http://' + url
										const colour = child.props.text.color
										const decoration = child.props.text.underline ? 'underline' : 'none'
										const text = child.props.text.text
										const linkStyles = { fontWeight: 600, cursor: 'pointer', color: colour || '#A9754E', textDecoration: decoration }
										return (
											<a target='_blank' href={href} style={linkStyles} onClick={(e) => this.setToolbar({ type: 'link-tooltip', text: text, href: href })} {...attributes}>
												{child}
											</a>
										)
									} else return child
								})
								return <li {...attributes}>{updatechildren}</li>
							}
							else if (type === "numbered-list")
							return ({ attributes, children }) => <ol {...attributes}>{children}</ol>
						else if (type === "bulleted-list")
							return ({ attributes, children }) => <ul {...attributes}>{children}</ul>
						else
							return null;
					},
					onKeyDown: (event) => {
						if (event.key === 'Enter') {
							const selection = editor.selection
							const path = selection.anchor.path
							const rootNode = editor.children[path[0]]

							if (rootNode.type === 'bulleted-list' || rootNode.type === 'numbered-list') {
								const isLink = path.length === 3 && rootNode.children[path[1]].children[path[2]].url !== undefined
								if (isLink) {
									const anchor = selection.anchor.offset
									const focus = selection.focus.offset
									const spaceAtTheStartOrTheEnd = anchor === focus && (anchor === 0 || anchor === rootNode.children[path[1]].children[path[2]].text.length)

									if (spaceAtTheStartOrTheEnd) {
										event.preventDefault()
										addListItemOnEnter(editor, path, anchor === 0)
									}
								}
							}
						}
					},
					editor: editor

				}
			}
		}

		const editorRef = {
			install: editor => {
				this.setState({ editor });
				return {
					editor: editor
				}
			}
		};

		const pageBreaksAddin = {
			install: withPageBreaks
		}

		const imageOptions = {
			menuItems: [
				<SBSImageWrapperStyleMenuItem visibility={OUT_OF_SIDE_BY_SIDE} styleName={`text-width`} text="Text Width" icon={<FitWidthSVG />} showTooltip/>,
				<SBSImageWrapperStyleMenuItem visibility={OUT_OF_SIDE_BY_SIDE} styleName={`page-width`} text="Page Width" icon={<WidthFullSVG />} showTooltip/>,
				<SideBySideStyleMenuItem styleName="text-width" text="Text Width" icon={<FitWidthSVG />} nodeType="image-wrapper" showTooltip/>,
				<SideBySideStyleMenuItem styleName="page-width" text="Page Width" icon={<WidthFullSVG />} nodeType="image-wrapper" showTooltip/>
			],
			styleInterpreter: style => {
				if (style === 'page-width') {
					return myStyles["full-width"]
				}
				if (style === 'page-width-with-border') {
					const joined = Object.assign({}, myStyles["full-width"], myStyles["image-border"]);
					return joined;
				}
				if (style === 'text-width-with-border') {
					return myStyles["image-border"]
				}
				return null;
			},
			contextMenu: {
				// this will break visually if not both true, 
				// TODO - update 3skye-slate to handle both horiz and vert, iconMenuItems and checkedMenuItems
				horizontal: true, 
				iconButton: true
			},
			contextMenuOrder: [
				0,
				1,
				2,
				3,
				"divider",
				"border",
				"divider",
				"header",
				"footer",
				"divider",
				"createSBS",
				"breakSBS",
				"divider",
				"delete"

			]
		};

		const sbsOptions = {
			styleInterpreter: style => {
				if (style === 'page-width') {
					return myStyles["full-width"]
				}
				if (style === 'page-width-with-border') {
					const joined = Object.assign({}, myStyles["full-width"], myStyles["image-border"]);
					return joined;
				}
				if (style === 'text-width-with-border') {
					return myStyles["image-border"]
				}
			}
		}

		const sbs = sidebysides(tableOptions, imageOptions, sbsOptions);
		const addins = simpleInlineStyles().concat([headings, lists, sbs, editorRef, pageBreaksAddin]);
		const styles = this.props.styles;
		const foundStyle = styles["brand-colour-one"] || styles["company-header"] || styles["page-header"] || styles["logo"] || null
		const colour1 = foundStyle && foundStyle.color? foundStyle.color : null
		const colourOptions = colour1 ? [colour1, '#2536f0'] : ['#2536f0']

		return <div className="page flowing-page" onMouseUp={this.mouseUp} onMouseMove={this.mouseMove} style={{ minHeight: this.props.pageSize.height + "pt" }}>
			{this.renderChildren(lastBoundary)}
			<div ref={this.state.divRef} style={{ "position": "relative", "left": margins.left + "pt", "top": margins.top + "pt", "width": width + "pt", "paddingBottom": (margins.top + margins.bottom) + "pt" }}>
				<Editor
					key={this.state.editorKey}
					readOnly={!this.props.data.localContentLoaded}
					load={this.state.content}
					flowing={true}
					width={width}
					height={height}
					styles={this.props.flowingConfig.styles}
					myStyles={myStyles}
					onChange={this.onChange}
					heightChange={this.heightChange}
					allowFullWidthTable={allowFullWidthTable}
					options={options}
					entityMap={this.state.entityMap}
					exchangeDataAs="editorState"
					addins={addins}
					toolbar={this.state.toolbar}
					setToolbar={this.setToolbar}
					colourOptions={colourOptions}
					throwToaster={this.throwToaster}
				/>
				{pageBoundaries}
			</div>
		</div>

	}

}

export default withData(FlowingPage);