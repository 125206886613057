import React, { useEffect } from "react";
import Modal from "./Modal";
import "./DraftNotePreviewModal.scss";
import FlexWrapper from "../../layouts/FlexWrapper";
import StatusToken from "../StatusTokens";
import Divider from "../Divider";
import Button from "../Buttons/Button";
import PDFViewer from "../PDFViewer";
import ErrorBoundary from "../ErrorBoundary";
import { mockComments } from "../../Utils/DummyData";
import CommentsSection from "../Comments/CommentsSection";

const DraftNotePreviewModal = ({ handleClose, data, compliance, actions }) => {

	useEffect(() => {
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			handleClose();
		}
	};

	const handleOverlayClick = (e) => {
		// Check if the clicked element or any of its ancestors have the classes 'floating-sidebar' or 'preview-wrapper'
		if (!hasAncestorWithClass(e.target, "floating-sidebar") && !hasAncestorWithClass(e.target, "preview-wrapper")) {
			// Trigger the handleClose function if the clicked element or its ancestors don't have the specified classes
			handleClose();
		}
	};

	// Helper function to check if the element or any of its ancestors have a specific class
	const hasAncestorWithClass = (element, className) => {
		let currentElement = element;
		while (currentElement) {
			if (currentElement.classList.contains(className)) {
				return true;
			}
			currentElement = currentElement.parentElement;
		}
		return false;
	};

	const errorFallback = (
		<div style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: "4rem"
		}}
		>
			<div style={{display:"flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
				<p>An error occurred while loading this note. </p>
				<a
					href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In"
					target="_blank"
					className="text-link colour-text-primary"
				>
					Contact Us
				</a>
			</div>
		</div>
	)

	return (
		<Modal handleClose={handleClose} className="draft-note-preview-modal" overlayClick={handleOverlayClick}>
			{/* Preview section */}
			<div className="preview-wrapper">
				<ErrorBoundary fallback={errorFallback}>
					{data && <PDFViewer enableKeyboardNavigation ticker={data?.ticker} template={data?.template? data.template: data?.type} id={data?.docId} scale={1} fullHeight fullWidth /> || null}
				</ErrorBoundary>
			</div>
			{/* Floating Sidebar */}
			<FlexWrapper className="floating-sidebar" direction="column" align="flex-start" gap="m">
				<FlexWrapper gap="s">
					<h4>Preview</h4>
					<StatusToken type={data?.status == "draft" ? "warning" : ""}>Draft</StatusToken>
				</FlexWrapper>
				<Divider noMargin />
				{actions.map((action, idx) => (
					<Button 
					key={idx} 
					variant={action.variant}
					onClick={action.action}
					fullWidth 
					disabled={action.disabled}
					 >{action.label}</Button>
				))}
				<CommentsSection ticker={data && data.ticker} templateName={data && data.template} compliance={compliance} />
			</FlexWrapper>
		</Modal>
	);
};

export default DraftNotePreviewModal;
