import React from 'react';
import DropDown from '../../DropDown';

const TickerSelector = ({template, selectedTickerGroup, setState, selectionGroups, onUpdateTickerGroup }) => {
	return (
		<>
			<DropDown clickable fullWidth label={template.ticker.selectionName} value={selectedTickerGroup && selectedTickerGroup.groupName}>
				{selectionGroups.map((option, index) => (
					<li key={index} onClick={() => setState({ selectedTickerGroup: option }, onUpdateTickerGroup)}>
						{option.groupName}
					</li>
				))}
			</DropDown>
		</>
	);
}

export default TickerSelector;