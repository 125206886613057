import React from 'react';
import SideBarSection from './SideBarSection';
import DropDown from '../../DropDown';

const TemplateSelector = ({templates, selectedTemplate, setState, onUpdateTemplate, isSelection}) => {
	return (
		<SideBarSection>
			<DropDown label="Template" clickable fullWidth value={selectedTemplate ? selectedTemplate : "Select Template"} placeholder={selectedTemplate ? false : true}>
				{templates.map((template, index) => (
					<div key={index} onClick={() => setState({ selectedTemplate: template }, () => onUpdateTemplate(isSelection))}>
						{template}
					</div>
				))}
			</DropDown>
		</SideBarSection>
	);
}

export default TemplateSelector;