import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import Tab from '../../../components/Menus/Tab';
import StatusToken from '../../../components/StatusTokens';
import { useRemote } from '../../../Utils/Utils';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';

const CompanyMgmt = (props) => {

	const [company, setCompany] = useState(null);
	const [edit, setEdit] = useState("");
	const [view, setView] = useState("");
	const [uploading, setUploading] = useState([]);
	const htmlUploadInputRef = useRef(null)
	const remote = useRemote();

	const setTemplatesEnabled = (template) => {
		switch (template) {
			case "peer":
				setCompany({ ...company, peerReviewAvailable: !company.peerReviewAvailable })
				break;
			case "compliance":
				setCompany({ ...company, complianceReviewAvailable: !company.complianceReviewAvailable })
				break;

			default:
				break;
		}
	}

	const getTokenState = (template) => {
		switch (template) {
			case "peer":
				return company.peerReviewAvailable ? "positive" : ""
			case "compliance":
				return company.complianceReviewAvailable ? "positive" : ""
			default:
				break;
		}

	}
	const getTokenMessage = (template) => {
		switch (template) {
			case "peer":
				return company.peerReviewAvailable ? "Hide Peer Templates" : "Show Peer Templates"
			case "compliance":
				return company.complianceReviewAvailable ? "Hide Compliance Templates" : "Show Compliance Templates"
			default:
				break;
		}
	}

	const getURL = () => {
		switch (view) {
			case company.complianceReviewEmailTemplate:
				return "/client/review/compliance/template"
			case company.complianceReviewApprovalEmailTemplate:
				return "/client/review/compliance/approval/template"
			case company.peerReviewEmailTemplate:
				return "/client/review/peer/template"
			case company.peerReviewApprovalEmailTemplate:
				return "/client/review/peer/approval/template"
			default:
				break;
		}
	}

	const handleFileUpload = (e) => {
		let files = e.target.files;
		setUploading(true);
		const tooBig = [];
		const wrongFileType = [];
		let anyUploaded = false;
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let name = file.name;

			anyUploaded = true;
			let xhr = new XMLHttpRequest();
			xhr.open('post', getURL(), true);
			xhr.addEventListener('load', e => {
				if (xhr.status >= 200 && xhr.status < 300) {
					// onUploadComplete(e, asset);
					toastSuccess("Upload Complete");
				} else {
					console.log(xhr);
				}
			});
			xhr.upload.addEventListener('progress', e => {
				if (e.lengthComputable) {
					let complete = (e.loaded / e.total * 100 | 0);
					if (complete === 100) {
						//TODO onscreen reporting
						console.log("Processing ...");
					} else {
						console.log("Finished");
					}
				}
			});

			xhr.setRequestHeader("Content-Type", file.type);
			xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
			xhr.setRequestHeader("X-3Skye-Session", remote.createTokenHeader().headers["X-3Skye-Session"]);
			xhr.send(file);
		}

		if (tooBig.length > 0 || wrongFileType.length > 0) {
			toastDanger(`Upload Failed ${tooBig.length > 0 ? "file too large" : ""}${tooBig.length > 0 && wrongFileType.length > 0 ? ", " : ""}${wrongFileType.length > 0 ? "wrong file type" : ""}`)
			if (!anyUploaded) {
				setUploading(false)
			}
		}
	}


	useEffect(() => {
		remote.get("/crm/company").then(setCompany);
	}, [])

	return <Page fixedBanner>
		{/* Page banner with back button */}
		<PageContentBanner divider>
			<ActionButtonsBanner
				//@Colin uncomment the line below for uploading and replace URLS in getURLs - line 57
				// customButton={<Button onClick={() => htmlUploadInputRef.current.click()}>Upload Template</Button>}
			/>
		</PageContentBanner>
		{/* Page content */}
		<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
			{/* grid container for the template details section */}
			<PageGridItem container col="1 / span 6">
				{company && <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
					{/* General Details */}
					{company && (
						<>
							<StatusToken onClick={() => setTemplatesEnabled("compliance")} type={getTokenState("compliance")}>{getTokenMessage("compliance")}</StatusToken>
							<StatusToken onClick={() => setTemplatesEnabled("peer")} type={getTokenState("peer")}>{getTokenMessage("peer")}</StatusToken>
						</>
					)
					}
					<PageGridDivider col="1 / span 6" />
					<Tab disabled={!company || !company.complianceReviewAvailable} onClick={() => setView(company.complianceReviewEmailTemplate)} active={view === company.complianceReviewEmailTemplate} text="Compliance Template" />
					<Tab disabled={!company || !company.complianceReviewAvailable} onClick={() => setView(company.complianceReviewApprovalEmailTemplate)} active={view === company.complianceReviewApprovalEmailTemplate} text="Compliance approval template" />
					<Tab disabled={!company || !company.peerReviewAvailable} onClick={() => setView(company.peerReviewEmailTemplate)} active={view === company.peerReviewEmailTemplate} text="Peer template" />
					<Tab disabled={!company || !company.peerReviewAvailable} onClick={() => setView(company.peerReviewApprovalEmailTemplate)} active={view === company.peerReviewApprovalEmailTemplate} text="Peer approval template" />
					{/* Publishing actions */}
					<PageGridDivider col="1 / span 6" />
					{/* Custom Publishing actions */}
					<iframe width="680" height="1024" srcdoc={view} />
					<input ref={htmlUploadInputRef} onChange={handleFileUpload} type="file" style={{ display: "none" }} />
				</PageContentBodyGrid>}
			</PageGridItem>
		</PageContentBodyGrid >
	</Page >

}

export default CompanyMgmt;