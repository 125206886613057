import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import { useRemote } from '../../../Utils/Utils';
import { format, sub } from 'date-fns';
import AnalyticsHeader from './AnalyticsHeader';

const TopSearches = ({ dateRanges }) => {

	const remote = useRemote();
	const [timeFrame, setTimeFrame] = useState(dateRanges[0]);
	const [stats, setStats] = useState([]);
	const end = format(new Date(), 'yyyy-MM-dd');

	useEffect(() => {
		let time = { months: 6 }
		if (timeFrame) time = timeFrame

		let date = typeof timeFrame === "string" ? timeFrame : format(sub(new Date(), time), "yyyy-MM-dd")

		remote.get(`/analytics/aggregates/searches?from=${date}&to=${end}`).then(setStats);
	}, [timeFrame]);


	const data = stats.slice(0, 5);

	const colWidths = ["92%", "60px"];

	const dataMap = () => data && data.map((search, idx) => {
		return (
			<tr key={idx} /*className="cursor-pointer"*/>
				<td>
					{search.searchTerm}
				</td>
				<td className="table-action-icons transition-fade-in-slow" style={{ textAlign: "left" }}>
					<IconCounter icon={<Search />} tooltip={"Searched"} dataAvailable count={search.count} pointer="default"/>
				</td>
			</tr>
		);
	});

	return (
		<>
			<AnalyticsHeader dateRanges timeFrame={timeFrame} setTimeFrame={setTimeFrame}>Search Terms</AnalyticsHeader>
			<Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
		</>
	);
}

export default TopSearches;