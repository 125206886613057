import React from 'react';
import Modal from './Modal';
import RadioButton from '../RadioButton';

const ConfirmOptionModal = ({ action, handleClose, confirmButton, cancelButton, header, message, options }) => {
	return (
		<Modal
			header={header ? header : "Confirm"}
			handleClose={handleClose}
			cancelButton={{ ...cancelButton }}
			confirmButton={{ label: "Confirm", ...confirmButton }}
		>
			{message ?? `Are you sure you want to ${action}?`}
			<div className='mt-l'>
				{options.map((option, idx) => {
					return (
						<div key={idx} className='mb-s'>
							<RadioButton disabled={option.disabled} label={option.label} checked={option.checked} onClick={option.onClick} />
						</div>
					)
				})}
			</div>
		</Modal>
	);
}
export default ConfirmOptionModal;