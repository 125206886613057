import React, { useEffect, useState } from "react";
import withRouteChange from '@threeskye/route-change';
import { useStorage } from "../../../Utils/Utils";
import { format } from "date-fns";
import "./TopBannerExtension.scss";


const TopBannerExtension = (props) => {
    const { changeRoute, header, icon, links, location, headerOnly } = props
    // TopBanner states
    const storage = useStorage()
    const [subTab, setSubTab] = useState(null)
    const [alternaviveName, setAlternaviveName] = useState(null)
    // Analytics states
    const [publication, setPublication] = useState(null)
    const [tickers, setTickers] = useState([])
    const [group, setGroup] = useState(null);
    const [groupingName, setGroupingName] = useState(null)
    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([])
    const [authors, setAuthors] = useState([])
    // CRM states
    const [contacts, setContacts] = useState([])
    const [organisations, setOrganisations] = useState([])
    const [lists, setLists] = useState([])
    // Library states
    const [doc, setDoc] = useState(null)


    useEffect(() => {
        if (header === "Analytics") {
            const pathArray = location.pathname.split("/")
            const researchId = (pathArray.length === 6 || pathArray.length === 7) ? +pathArray[pathArray.length - 1] : null

            if (researchId && !isNaN(researchId)) {
                const path = pathArray.includes("group") ?
                    `/analytics/group/${+pathArray[3]}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}` : pathArray.includes("tickers") ?
                        `/analytics/ticker/${+pathArray[3]}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}` :
                        `/analytics/users/${+pathArray[4]}`

                storage.getOrFetch(path).then((fetchedPublications) => {
                    if (fetchedPublications) {
                        let foundPublication;
                        if (pathArray.includes("users")) {
                            if (fetchedPublications.events) foundPublication = fetchedPublications.events && fetchedPublications.events.find(n => n && n.note && n.note.publicationId === researchId)
                            else  foundPublication = fetchedPublications.find(n => n.note.publicationId === researchId)
                        } else {
                            foundPublication = fetchedPublications.find(p => p.noteId === researchId || (p.note && p.note.publicationId === researchId))
                        }
                        if (foundPublication && pathArray.includes("users")) setPublication(foundPublication.note.title) 
                        else if (!foundPublication && pathArray.includes("users"))setPublication("Unknown Title") 
                        else if (foundPublication) setPublication(foundPublication.title) 
                    }
                })
            } else setPublication(null)

            if (users.length === 0) storage.getOrFetch("/analytics/users").then(setUsers)
            if (tickers.length === 0) storage.getOrFetch("/crm/tickers").then(setTickers)
            if (companies.length === 0) storage.getOrFetch("/analytics/companies").then(setCompanies)
            if (authors.length === 0) storage.getOrFetch("/analytics/aggregates/authors").then(setAuthors)
        }

        if (header === "CRM") {
            if (contacts.length === 0) storage.getOrFetch("/crm/contacts").then(setContacts)
            if (organisations.length === 0) storage.getOrFetch("/crm/organisations").then(setOrganisations)
            if (lists.length === 0) storage.getOrFetch("/crm/lists").then(setLists)
        }

    }, [header, location, storage])


    useEffect(() => {
        storage.watch("/crm/contacts", setContacts)
        storage.watch("/crm/organisations", setOrganisations)
        storage.watch("/crm/lists", setLists)
        return () => {
            storage.unwatch("/crm/contacts")
            storage.unwatch("/crm/organisations")
            storage.unwatch("/crm/lists")
        }
    }, [])

    useEffect(() => {
        const pathname = location.pathname
        const pathArray = pathname.split("/")

        if (header === "Analytics") {
            const tickerOrGroupId = +pathArray[3]
            const userOrCompanyOrAuthorId = !isNaN(+pathArray[4]) ? +pathArray[4] : pathArray[4] && pathArray[4].includes("@") ? pathArray[4] : null

            if (tickerOrGroupId && !isNaN(tickerOrGroupId)) setAlternaviveName(tickerOrGroupId)
            else if (userOrCompanyOrAuthorId !== null) setAlternaviveName(userOrCompanyOrAuthorId)
            else setAlternaviveName(null)

            if (pathname.includes("tickers") && subTab !== "Tickers") {
                if (subTab !== "Tickers") setSubTab("Tickers")
            } else if (pathname.includes("group")) {
                if (tickerOrGroupId) {
                    storage.getOrFetch("/crm/groupings/-1/" + tickerOrGroupId).then(g => {
                        setGroup(g);
                        setGroupingName(g.groupingDetails.groupName);
                    })
                }
                if (subTab !== "Groups") setSubTab("Groups")
            } else if (pathname.includes("users")) {
                if (subTab !== "Users") setSubTab("Users")
            } else if (pathname.includes("companies")) {
                if (subTab !== "Companies") setSubTab("Companies")
            } else if (pathname.includes("dashboard")) {
                if (subTab !== "Dashboard") setSubTab("Dashboard")
            } else if (pathname.includes("authors")) {
                if (subTab !== "Authors") setSubTab("Authors")
            }

        } else if (header === "CRM") {
            const pathId = +pathArray[4]
            if (!isNaN(pathId)) setAlternaviveName(pathId)
            else setAlternaviveName(null)

            if (pathname.includes("users")) {
                if (subTab !== "Users") setSubTab("Users")
            } else if (pathname.includes("companies")) {
                if (subTab !== "Companies") setSubTab("Companies")
            } else if (pathname.includes("distribution")) {
                if (subTab !== "Distribution Lists") setSubTab("Distribution Lists")
            } else if (pathname.includes("supressions")) {
                if (subTab !== "Suppressed Emails") setSubTab("Suppressed Emails")
            }

        } else if (header === "Library") {
            const pathId = +pathArray[3]
            if (!isNaN(pathId)) setAlternaviveName(pathId)
            else setAlternaviveName(null)

            if (pathname.includes("delivery")) {
                storage.getOrFetch(`/documents/document/${pathArray[3]}/details`).then((fetchedDoc) => {
                    setDoc(fetchedDoc)
                    setAlternaviveName(pathId)
                    setSubTab("Library")
                }).catch(() => setSubTab("Library"), setAlternaviveName(pathId))
            }

        } else setAlternaviveName(null)
    }, [location, publication])

    useEffect(() => {
        const pathname = location.pathname
        const selectedLink = pathname === "/" ? { name: "Library" } :
            pathname.includes("group") ? links.find(tab => tab && tab.name === "Groups") :
                pathname.includes("suppressions") ? links.find(tab => tab && tab.name === "Suppressed Emails") :
                    links.find(tab => tab && tab.name && pathname.includes(tab.name.toLowerCase()))

        if (selectedLink && selectedLink.name) setSubTab(selectedLink.name)

    }, [links])


    function selectTab(link) {
        setSubTab(link.name)
        setAlternaviveName(null)
        setPublication(null)
        changeRoute(link.path)
    }

    function getTickerDisplaying(link, id, header) {
        // Analytics
        if (tickers && link === "Tickers") {
            const ticker = tickers.find(t => t.id === id)
            if (ticker) return ticker.ric || ticker.name
            else return ""

        } else if (link === "Groups") {
            if (group) return groupingName + " — " + group.groupName
            else return ""

        } else if (users && link === "Users" && header === "Analytics") {
            const user = users.find(u => u.contact.id === id)
            if (user) return user.contact.firstName + " " + user.contact.lastName
            else return ""

        } else if (companies && link === "Companies" && header === "Analytics") {
            const company = companies.find(c => c.organisationId === id)
            if (company) return company.organisationName
            else return ""

        } else if (authors && link === "Authors" && header === "Analytics") {
            const author = authors.find(a => a.email === id)
            if (author) return author.name
            else return ""

            // CRM
        } else if (contacts && link === "Users" && header === "CRM") {
            const contact = contacts.find(c => c.id === id)
            if (contact) return contact.firstName + " " + contact.lastName
            else return ""

        } else if (organisations && link === "Companies" && header === "CRM") {
            const organisation = organisations.find(o => o.id === id)
            if (organisation) return organisation.name
            else return ""

        } else if (lists && link === "Distribution Lists") {
            const list = lists.find(l => l.listId === id)
            if (list) return list.name
            else return ""

            // Library
        } else if (header === "Library" && doc) {
            return (doc.summary && doc.summary.title) || (doc.portal && doc.portal.title) || (doc.distribution && doc.distribution.title) || ""

        } else return ""
    }

    function isTheCorrectName(name) {
        return name === subTab
    }


    return (
        <div className="top-banner-extension-outter">
            <div className="top-banner-extension">
                <div className={`top-banner-ext-header ${headerOnly ? "no-tabs" : ""}`}>
                    {icon}
                    <h3 onClick={() => headerOnly ? selectTab(links[0]) : null} style={{ cursor: headerOnly ? "pointer" : "default" }}>{header}</h3>
                    {alternaviveName && headerOnly ? <>
                        <h3 className={`top-banner-ext-link`}>/</h3>
                        <h3 className={`top-banner-ext-link selected`} style={{ cursor: "default" }}>{getTickerDisplaying(null, alternaviveName, header)}</h3>
                    </> : ""}
                </div>
                {!headerOnly && <div className="top-banner-ext-links">
                    {links.map((link) => {
                        return alternaviveName && isTheCorrectName(link.name) ?
                            < div key={header + "-" + link.name} style={{ display: "flex", gap: "0.25rem" }}>
                                <h3 className={`top-banner-ext-link`} onClick={() => selectTab(link)}>{link.name}</h3>
                                <h3 className={`top-banner-ext-link`}>/</h3>
                                <h3 className={`top-banner-ext-link ${!publication ? "selected" : ""}`} onClick={() => { changeRoute(link.name === "Library" ? null : link.name === "Users" ? link.path + "/user/" + alternaviveName : link.path + "/" + alternaviveName); setPublication(null) }}>
                                    {getTickerDisplaying(link.name, alternaviveName, header)}
                                </h3>
                                {publication && <h3 className={`top-banner-ext-link`}>/</h3>}
                                {publication && <h3 className={`top-banner-ext-link selected truncate`}>{publication}</h3>}
                            </div> : <h3 key={header + "-" + link.name} className={`top-banner-ext-link ${subTab === link.name ? "selected" : ""}`} onClick={() => selectTab(link)}>{link.name}</h3>
                    })}
                </div>}
            </div>
        </div >
    )
}

export default withRouteChange(TopBannerExtension)