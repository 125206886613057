import React from 'react';
import { Briefcase } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { listOfKiwiAndAussieCities } from '../../../Utils/Utils'

const CompanyGeneralDetails = ({ editMode, company, editCompany, contacts, validateName, nameValid, companyNameTouched, setCompanyNameTouched, companyLocationTouched, setCompanyLocationTouched, validLocation, setValidLocation }) => {
	return (
		<React.Fragment>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header="General Details" icon={<Briefcase />} />
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					bold
					editMode={editMode}
					autoFocus
					label={editMode ? "Name*" : "Name"}
					value={company.name && company.name}
					onChange={(e) => {
						if (!setCompanyNameTouched) setCompanyNameTouched(true)
						validateName(e.target.value)
						editCompany({ ...company, name: e.target.value })
					}}
					invalid={companyNameTouched && (!nameValid || company.name === "") ? true : false}
					helperText={companyNameTouched && !nameValid ? "Company name already in use" : companyNameTouched && (company.name === "" || !company.name) ? "Company name is required" : ""}
				// invalid={true}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					label={editMode ? "Website" : "Website"}
					value={company.website && company.website}
					onChange={(e) => editCompany({ ...company, website: e.target.value })}
				/>
			</PageGridItem>
			<PageGridItem col="1 / span 3">
				<DetailField
					editMode={editMode}
					label={editMode ? "Phone" : "Phone"}
					value={company.phone && company.phone}
					onChange={(e) => editCompany({ ...company, phone: e.target.value })}
				/>
			</PageGridItem>
			<PageGridItem col="4 / span 3">
				<DetailField
					editMode={editMode}
					label={editMode ? "Headquarters*" : "Headquarters"}
					value={company.locations && company.locations.location}
					onChange={(value) => {
						setCompanyLocationTouched(true)
						if (value && value.length === 0) setValidLocation(false)
						else setValidLocation(true)
						editCompany({ ...company, locations: { value: value, label: value, location: value } })
					}}
					onClick={() => setCompanyLocationTouched(true)}
					invalid={companyLocationTouched && (!validLocation || !company.locations.location) ? true : false}
					helperText={companyLocationTouched && (!validLocation || !company.locations.location) ? "Company headquarters is required" : ""}
					dropDown={true}
					dropDownOptions={listOfKiwiAndAussieCities}
				/>
			</PageGridItem>
			{editMode ? (
				<PageGridItem col="1 / span 6"><div style={{ marginBottom: 3 }} /></PageGridItem>
			) : (
				<>
					<PageGridItem col="1 / span 3">
						<DetailField
							editMode={editMode}
							label="Users"
							value={contacts && contacts.length}
						/>
					</PageGridItem>
					{/* <PageGridItem col="4 / span 3">
						<DetailField
							editMode={editMode}
							label="Distribution Lists"
							value={company.lists && company.lists}
						/>
					</PageGridItem> */}
					{/* <PageGridItem col="1 / span 3">
						<DetailField
							editMode={editMode}
							label="Tickers Owned"
							value={company.tickersOwned && company.tickersOwned}
						/>
					</PageGridItem> */}
				</>
			)}
		</React.Fragment>
	);
}

export default CompanyGeneralDetails;