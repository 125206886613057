import React, { useEffect, useState } from 'react';
import { Clock } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import FlexWrapper from '../../FlexWrapper';
import ProfileImage from '../../../components/ProfileImage';
import { getInitials, useRemote } from '../../../Utils/Utils';
import { format, sub } from 'date-fns';
import AnalyticsHeader from './AnalyticsHeader';
import withRouteChange from "@threeskye/route-change"


const TopUsersTable = ({dateRanges, changeRoute}) => {

	const remote = useRemote();
	const [timeFrame, setTimeFrame] = useState(dateRanges[0]);
	const [stats, setStats] = useState([]);
	const end = format(new Date(), 'yyyy-MM-dd')

	useEffect(() => {
		let time = { months: 6 }
		if (timeFrame) time = timeFrame
		
		let date = typeof timeFrame === "string" ? timeFrame : format(sub(new Date(), time), "yyyy-MM-dd")

		remote.get(`/analytics/aggregates/sessions?from=${date}&to=${end}`).then((stats) => {
			let sortedStats = stats.sort((a, b) => b.minutes - a.minutes);
			setStats(sortedStats)
		});
	}, [timeFrame]);


	const data = stats.slice(0, 5);

	const colWidths = ["90%", "100px"];

	const dataMap = () => data && data.map((contact, idx) => {
		const quotient = Math.floor(contact.minutes / 60);
		const remainder = contact.minutes % 60;
		const time = quotient + "h " + remainder + "m";
		const firstName = contact.firstName;
		const lastName = contact.lastName;

		return (
			<tr key={idx} className="cursor-pointer" onClick={() => changeRoute(`/analytics/users/user/${contact.contactId}`) }>
				<td>
					<FlexWrapper gap="s">
						<ProfileImage size={24} initials={getInitials(firstName, lastName)} />
						<p>{contact.firstName} {contact.lastName}</p>
					</FlexWrapper>
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					<FlexWrapper gap="xxl" justify="start">
						<IconCounter icon={<Clock />} tooltip="Session Duration" dataAvailable count={time} />
					</FlexWrapper>
				</td>
			</tr>
		);
	});

	return (
		<>
			<AnalyticsHeader dateRanges timeFrame={timeFrame} setTimeFrame={setTimeFrame}>Most Active Users</AnalyticsHeader>
			<Table rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
		</>
	);
}

export default withRouteChange(TopUsersTable);