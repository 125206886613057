import React, { Component, useRef } from 'react';

import Button from "./Button";
import { CSVLink } from 'react-csv';
import { withRemote } from '@threeskye/global'

const CSVButton = ({ headers, data, filename, children, auditType, audit, classNames, ...rest }) => {

	const linkRef = useRef();

	// const generateCSV = () => {


	// 	const headerString = headers.map((field) => field.label).join(",") + "\n";
	// 	const fieldsToDisplay = headers.map((field) => field.key);
	// 	const dataString = data.map((row) => fieldsToDisplay.map((field) => row[field]).join(",")).join("\n");


	// 	return headerString + dataString;
	// }

	// const b64EncodeUnicode = (str) => {
	// 	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// 	// then we convert the percent encodings into raw bytes which
	// 	// can be fed into btoa.
	// 	return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
	// 		function toSolidBytes(match, p1) {
	// 			return String.fromCharCode('0x' + p1);
	// 		}));
	// }

	// const createExportAudingLog = () => {
	// 	/**
	// 	 * csv genartion
	// 	*/
	// 	const generatedCSV = generateCSV();
	// 	const base64 = b64EncodeUnicode(generatedCSV);

	// 	/**
	// 	 * sending
	// 	*/
	// 	remote.post("/modules/crm/export/audit-log/", { file: { base64, filename: filename }, records: data ? data.length : -1, type: this.props?.auditType })
	// 		.then(() => {

	// 		});
	// }

	// if (classNames) {
	// 	classes = classNames;
	// }
	return <React.Fragment>
		<Button classNames={classNames} onClick={() => {
			linkRef.current.link.click();
		}}>
			{children}
		</Button>
		<CSVLink ref={linkRef} headers={headers} data={data} {...rest} style={{ display: "none" }} />
	</React.Fragment>;
}

export default withRemote(CSVButton);