// Documentation ----> https://popper.js.org/
import React, { useEffect, useRef, useState } from 'react';
import reactDom from 'react-dom';
import { usePopper } from 'react-popper';
import './Popup.scss';

const Popup = (props) => {
	const { delay, disabled, className, offsetY, offsetX, triggerElement, popupElement, popupElementClass, hoverOnly, onClick, absolute, popupOuterWidth, placement, display, children, zIndex, relative, outerStyle, clickAway } = props;

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const [mouseLeaveTimer, setMouseLeaveTimer] = useState(null)
	const [mouseEnterTimer, setMouseEnterTimer] = useState(null)
	const [popupVisible, setPopupVisible] = useState(false);
	const wrapperElementRef = useRef()

	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: placement ? placement : "bottom-start",
		strategy: absolute ? "" : "fixed",
		modifiers: [{ name: "offset", options: { offset: [offsetX ? offsetX : 0, offsetY ? offsetY : 4] } }],
	});

	useEffect(() => {
		if (clickAway) {
			function handleClickOutside(event) {
				if (wrapperElementRef.current && !wrapperElementRef.current.contains(event.target)) {
					setMouseLeaveTimer(setTimeout(() => {
						setPopupVisible(false)
					}, 100))
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [wrapperElementRef]);

	const handleMouseEnter = () => {
		if (disabled) {
			return;
		} else if (hoverOnly) {
			clearTimeout(mouseLeaveTimer)
			if (delay) {
				setMouseEnterTimer(setTimeout(() => {
					setPopupVisible(true)
				}, delay))
			} else {
				setPopupVisible(true)
			}
		}
	}

	const handleMouseLeave = () => {
		clearTimeout(mouseEnterTimer)
		if (disabled) {
			return;
		} else if (hoverOnly) {
			setMouseLeaveTimer(setTimeout(() => {
				setPopupVisible(false)
			}, 100))
		}
	}


	const popUpChild = (
		<div
			className={`popup-element-outer${popupElementClass ? " " + popupElementClass : ""}`}
			onClick={() => setPopupVisible(!popupVisible)}
			ref={setPopperElement}
			style={{ ...styles.popper, width: popupOuterWidth, zIndex: zIndex }}
			{...attributes.popper}
		>
			{React.cloneElement(popupElement, { setPopupVisible })}
		</div>
	);


	return (
		<div
			style={{ ...outerStyle, display: display ? display : "" }}
			className={`popup-outer fade-down${className ? " " + className : ""}${relative ? " popup-relative" : ""}`}
			onMouseEnter={() => handleMouseEnter()}
			onMouseLeave={() => handleMouseLeave()}
			ref={wrapperElementRef}
		>
			<div
				onClick={
					hoverOnly
						? null
						: hoverOnly && onClick
							? onClick
							: () => {
								setPopupVisible(!popupVisible);
								onClick && onClick();
							}
				}
				ref={setReferenceElement}
			>
				{triggerElement ? triggerElement : children}
			</div>
			{(popupVisible && absolute) ? popUpChild : popupVisible && reactDom.createPortal(popUpChild, document.querySelector("#tooltip-portal"))}
		</div>
	);
};

export default Popup;