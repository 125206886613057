import React, { useEffect, useState, useRef } from "react";
import Tooltip from "../../components/popups/Tooltip";
import './TruncatedString.scss'

const TruncatedString = (props) => {
	const { string } = props
	const [isOverflown, setIsOverflown] = useState(false);
	const textElementRef = useRef();

	const compareSize = () => {
		const element = textElementRef.current;
		if (element) {
			const parent = element.offsetParent
			const compare = element.offsetWidth + 20 > parent.offsetWidth;
			setIsOverflown(compare);
		}
	};

	useEffect(() => {
		compareSize()
		textElementRef.current && textElementRef.current.addEventListener('mouseover', compareSize());
		window.addEventListener('mouseover', compareSize)

		return () => {
			textElementRef.current && textElementRef.current.removeEventListener('mouseover', compareSize());
			window.removeEventListener('mouseover', compareSize);
		}
	}, [string, isOverflown, textElementRef]);


	return (
		<Tooltip placement="top-start" offsetY={8} label={string} disabled={!isOverflown} truncate>
			<span className="truncated-string" ref={textElementRef}>{string}</span>
		</Tooltip>
	)
}

export default TruncatedString