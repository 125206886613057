import React, { Component } from 'react';
import { AlertCircle } from 'react-feather';


export default class AbsoluteComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			hasError: false
		}
	}
	
	componentDidCatch(error, info) {
		console.log(error)
		this.setState(state => ({ ...state, hasError: true }))
	}

	
	render() {

		//dying on spread operator inside Eikon, so changed to not use.
		const otherStyles = this.props.style || {};
		otherStyles.position = "absolute";
		otherStyles.left = this.props.x + 'pt';
		//otherStyles.zIndex = 1000;

		if (this.props.relativeBottom) {
			otherStyles.bottom = this.props.bottom;
		} else {
			otherStyles.top = this.props.y + 'pt';
		}

		return <div style={otherStyles}>{this.state.hasError ? <Error/> : this.props.content}</div>
	}
}

const Error = () => <><AlertCircle color={"#E66780"}/>Critical error in component</>
