import React from 'react';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log(error)
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		console.error("Error caught by boundary", error, info);
		if (this.props.onCatch) {
			this.props.onCatch(error, info)
		}
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;