import React, { useState, useEffect, useRef } from 'react';
import PageContentToggle from '../../../components/Menus/PageContentToggle'
import SearchObjectFilter from '../../../components/Input/SearchObjectFilter'
import { useStorage, useRemote, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import withRouteChange from "@threeskye/route-change"
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import { Eye, Mail, Clock, BookOpen, User, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { format, parseISO } from 'date-fns';
import LoadingIcon from '../../Shared/LoadingIcon';
import Tooltip from '../../../components/popups/Tooltip';
import TruncatedString from '../../Shared/TruncatedString'


const AnalyticsTickerPage = ({ changeRoute, datatita }) => {
    const storage = useStorage();
    const remote = useRemote();

    // states
    const [currentView, setCurrentView] = useState("research");
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [filteredResearchData, setFilteredResearchData] = useState([]);
    const [filteredWatchingData, setFilteredWatchingData] = useState([]);
    const [filteredResearchList, setFilteredResearchList] = useState([])
    const [filteredWatchingList, setFilteredWatchingList] = useState([])
    const [currentFilter, setCurrentFilter] = useState("");
    const [loading, setLoading] = useState(true);
    const [usersList, setUsersList] = useState([])

    const [type, setType] = useState(null)
    const [ticker, setTicker] = useState(null);
    const [group, setGroup] = useState(null);
    const [groupingName, setGroupingName] = useState(null)

    const [published, setPublished] = useState([]);

    const [sortingColumn, setSortingColumn] = useState({ column: "Time/Date", ascending: false })
    const [researchDataDisplaying, setResearchDataDisplaying] = useState([])
    const [watchingDataDisplaying, setWatchingDataDisplaying] = useState([])


    useEffect(() => {
        const params = datatita.match.params;

        if (params.tickerId) {
            remote.get(`/analytics/ticker/${datatita.match.params.tickerId}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}`).then(setPublished).then(() => setLoading(false))

            storage.getOrFetch("/crm/tickers").then((fetchedTickers) => {
                const foundTicker = fetchedTickers.find(t => params.tickerId && t.id === +params.tickerId);
                if (foundTicker) setTicker(foundTicker);
                setType("ticker")
            }).catch(() => setType("ticker"))

            remote.get(`/analytics/aggregates/users-by-watchlisted-ticker/${datatita.match.params.tickerId}`).then((fetchedUsers) => {
                setUsersList(fetchedUsers)
            })
        }

        if (params.groupId) {
            remote.get("/crm/groupings/-1/" + datatita.match.params.groupId).then(g => {
                setGroup(g);
                setGroupingName(g.groupingDetails.groupName);
                setType("group")
            }).catch(() => setType("group"))

            remote.get(`/analytics/group/${datatita.match.params.groupId}/notes?from=2000-01-01&to=${format(new Date(), 'yyyy-MM-dd')}`).then(setPublished).then(() => setLoading(false))
            remote.get(`/analytics/aggregates/users-by-watchlisted-group/${datatita.match.params.groupId}`).then((fetchedUsers) => {
                setUsersList(fetchedUsers)
            })
        }
    }, [])


    // research filters
    function filterOnclick(value) {
        return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
    }

    useEffect(() => {
        if (currentView === "research") {
            let newList = published
            if (currentFilter === "pdf") newList = published.filter(p => p.emailPdfDownloads || p.portalPdfDownloads)
            if (currentFilter === "model") newList = published.filter(p => p.dataDownloads)
            if (currentFilter === "read") newList = published.filter(p => p.emailsRead)
            if (currentFilter === "portal") newList = published.filter(p => p.portalViews)
            setFilteredResearchList(newList)

        } else {
            let newList = usersList
            if (currentFilter === "pdf") newList = usersList.filter(p => p.emailPdfDownloads || p.portalPdfDownloads)
            if (currentFilter === "model") newList = usersList.filter(p => p.dataDownloads)
            if (currentFilter === "read") newList = usersList.filter(p => p.emailsRead)
            if (currentFilter === "portal") newList = usersList.filter(p => p.portalViews)
            setFilteredWatchingList(newList)
        }
    }, [currentView, currentFilter, published, usersList])


    // Sorting
    useEffect(() => {
        sortTable(null)
    }, [currentView, filteredResearchList, filteredWatchingList, filteredResearchData, filteredWatchingData, hasSearchTerm])

    const sortTable = (column) => {
        if (column === null) {
            if (currentView === "research") setResearchDataDisplaying(hasSearchTerm ? filteredResearchData : filteredResearchList)
            if (currentView === "watching") setWatchingDataDisplaying(hasSearchTerm ? filteredWatchingData : filteredWatchingList)
            return
        }

        let tableData = currentView === "research" && hasSearchTerm ?
            filteredResearchData : currentView === "research" && !hasSearchTerm ?
                filteredResearchList : currentView === "watching" && hasSearchTerm ?
                    filteredWatchingData : filteredWatchingList

        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case "Time/Date":
                tableData.sort((a, b) => ascending ? a.publishedDate.localeCompare(b.publishedDate) : b.publishedDate.localeCompare(a.publishedDate))
                break;
            case "Title":
                tableData.sort((a, b) => ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title))
                break;
            case "Author":
                tableData.sort((a, b) => ascending ? a.analystName.localeCompare(b.analystName) : b.analystName.localeCompare(a.analystName))
                break;
            case "Minutes":
                tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
                break;
            case "Read":
                tableData.sort((a, b) => ascending ? a.emailsRead - b.emailsRead : b.emailsRead - a.emailsRead)
                break;
            case "PortalViews":
                tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
                break;
            case "PDFDownloads":
                tableData.sort((a, b) => ascending ? (a.portalPdfDownloads + a.emailPdfDownloads) - (b.portalPdfDownloads + b.emailPdfDownloads) : (b.portalPdfDownloads + b.emailPdfDownloads) - (a.portalPdfDownloads + a.emailPdfDownloads))
                break;
            case "ModelDownloads":
                tableData.sort((a, b) => ascending ? a.dataDownloads - b.dataDownloads : b.dataDownloads - a.dataDownloads)
                break;
            case "Name":
                tableData.sort((a, b) => ascending ? a.contact.firstName.localeCompare(b.contact.firstName) : b.contact.firstName.localeCompare(a.contact.firstName))
                break;
            case "Email":
                tableData.sort((a, b) => ascending ? a.contact.email.localeCompare(b.contact.email) : b.contact.email.localeCompare(a.contact.email))
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        currentView === "research" ? setResearchDataDisplaying(tableData) : setWatchingDataDisplaying(tableData)
    }


    // Ticker/Group metrics
    const watching = usersList ? usersList.length : "-"
    const research = published ? published.length : "-"
    const pdf = /*type === "ticker" ? data && data.pdfDownloads :*/ published ? published.reduce((a, b) => a + b.emailPdfDownloads + b.portalPdfDownloads, 0) : "-"
    const model = /*type === "ticker" ? data && data.modelDownloads :*/ published ? published.reduce((a, b) => a + b.dataDownloads, 0) : "-"
    const email = published ? published.reduce((a, b) => a + b.emailsRead, 0) : "-"
    const portal = /*type === "ticker" ? data && data.portalViews :*/ published ? published.reduce((a, b) => a + b.portalViews, 0) : "-"

    // research table data 
    const researchHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Time/Date")} >
                    Time/Date
                    {sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Title")} >
                    Title
                    {sortingColumn && sortingColumn.column === "Title" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Title" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Author")} >
                    Author
                    {sortingColumn && sortingColumn.column === "Author" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Author" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <Tooltip label="Minutes">
                    <div className='sorting-header' onClick={() => sortTable("Minutes")}>
                        <Clock className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Read">
                    <div className='sorting-header' onClick={() => sortTable("Read")}>
                        <Mail className="colour-text-purple" size={16} />
                        {sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Portal Views">
                    <div className='sorting-header' onClick={() => sortTable("PortalViews")}>
                        <Eye className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="PDF Downloads">
                    <div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
                        <PdfIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Model Downloads">
                    <div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
                        <XlsIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        }
    ]

    const researchColWidths = ["150px", "280px", "230px", "70px", "70px", "70px", "70px", "70px"]


    const researchDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { title, analystName } = datum
        const pdf = datum.portalPdfDownloads + datum.emailPdfDownloads
        const model = datum.dataDownloads
        const email = datum.emailsRead
        const portal = datum.portalViews
        const publishedDate = format(parseISO(datum.publishedDate), 'dd/MM/yyyy');
        const typeId = datatita.match.params.tickerId || datatita.match.params.groupId
        const researchId = datum.noteId
        const minutes = datum.minutes
        const typeSelected = type === "ticker" ? "tickers" : type

        return (
            // TODO: format minutes to hours and days
            <tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/" + typeSelected + "/" + typeId + "/research/" + researchId)}>
                <td>{publishedDate}</td>
                <td style={{ paddingRight: "1.5rem" }}><TruncatedString string={title} /></td>
                <td>{analystName || datum.author}</td>
                <td className="text-align-center">{minutesIntoReadableFormat(minutes)}</td>
                <td><div className="text-align-center icon-counter-count">{email}</div></td>
                <td><div className="text-align-center icon-counter-count">{portal}</div></td>
                <td><div className="text-align-center icon-counter-count">{pdf}</div></td>
                <td><div className="text-align-center icon-counter-count">{model}</div></td>
            </tr>
        );
    });


    // users watching table data
    const watchingHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Name")} >
                    Name
                    {sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Email")} >
                    Email
                    {sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <Tooltip label="Minutes">
                    <div className='sorting-header' onClick={() => sortTable("Minutes")}>
                        <Clock className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Read">
                    <div className='sorting-header' onClick={() => sortTable("Read")}>
                        <Mail className="colour-text-purple" size={16} />
                        {sortingColumn && sortingColumn.column === "Read" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Read" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Portal Views">
                    <div className='sorting-header' onClick={() => sortTable("PortalViews")}>
                        <Eye className="colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="PDF Downloads">
                    <div className='sorting-header' onClick={() => sortTable("PDFDownloads")}>
                        <PdfIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "PDFDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PDFDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Model Downloads">
                    <div className='sorting-header' onClick={() => sortTable("ModelDownloads")}>
                        <XlsIcon noHover className="cursor-pointer" size={16} />
                        {sortingColumn && sortingColumn.column === "ModelDownloads" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "ModelDownloads" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        }
    ]

    const watchingColWidths = ["280px", "280px", "70px", "70px", "70px", "70px", "70px"]

    const usersDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { contact, emailsRead, portalViews, emailPdfDownloads, portalPdfDownloads, dataDownloads, minutes } = datum
        // TODO: format minutes to hours and days
        return (
            <tr key={idx} className="cursor-pointer" onClick={() => changeRoute(ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/" + datum.contact.id)}>
                <td>{contact.firstName} {contact.lastName}</td>
                <td>{contact.email}</td>
                <td><div className="text-align-center icon-counter-count">{minutesIntoReadableFormat(minutes)}</div></td>
                <td><div className="text-align-center icon-counter-count">{emailsRead}</div></td>
                <td><div className="text-align-center icon-counter-count">{portalViews}</div></td>
                <td><div className="text-align-center icon-counter-count">{emailPdfDownloads + portalPdfDownloads}</div></td>
                <td><div className="text-align-center icon-counter-count">{dataDownloads}</div></td>
            </tr>
        );
    });

    function changeTab(string) {
        setCurrentFilter("")
        if (string === "research") {
            setCurrentView("research")
            setSortingColumn({ column: "Time/Date", ascending: false })
        }
        if (string === "watching") {
            setCurrentView("watching")
            setSortingColumn({ column: "Name", ascending: true })
        }

    }

    if (type === 'ticker' && !ticker) {
        return <p>No ticker</p>
    }
    if (type === 'group' && !group && !groupingName) {
        return <p>No group</p>
    }

    if (!type) {
        return (
            <Page noBanner showScrollbar>
                <PageContentBodyGrid showScrollbar>
                    <PageGridItem colSpan="12">
                        <LoadingIcon centered />
                    </PageGridItem>
                </PageContentBodyGrid>
            </Page>
        )
    }


    return (
        <Page noBanner showScrollbar>
            {/* model */}
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="xl">
                        <FlexWrapper gap="xs" direction="column" align="flex-start">
                            {type === "ticker" ?
                                <TickerSectorItem label={ticker.ric} image={`/api/public/images/ticker/${ticker.id}.png`} imageSize={40} country={ticker.country} filename={ticker.ric} /> :
                                <TickerSectorItem label={<>{groupingName} &mdash; {group.groupName}</>} image={`/api/public/images/group/${group.id}.png`} imageSize={40} />
                            }
                        </FlexWrapper>
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "research"}
                            inactive={currentFilter !== "" && currentFilter !== "research"}
                            label="Research"
                            status="research"
                            icon={<BookOpen color='#0D8EFD' />}
                            count={research}
                            style={{ marginTop: 3, cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => null}
                            active={currentFilter === "watching"}
                            inactive={currentFilter !== "" && currentFilter !== "watching"}
                            label="Watching"
                            status="watching"
                            icon={<User color='#00805E' />}
                            count={watching}
                            style={{ cursor: "default" }}
                        />
                        <AnalyticsMetricFilter
                            onClick={() => filterOnclick("read")}
                            active={currentFilter === "read"}
                            inactive={currentFilter !== "" && currentFilter !== "read"}
                            label="Email Views"
                            status="read"
                            icon={<Mail />}
                            count={email}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Portal Views"
                            icon={<Eye />}
                            count={portal}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="PDF Downloads"
                            icon={<PdfIcon size='16' noHover />}
                            count={pdf}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <AnalyticsMetricFilter
                            label="Model Downloads"
                            icon={<XlsIcon size='16' noHover />}
                            count={model}
                            style={{ marginTop: 3 }}
                            pointer="default"
                        />
                        <Tooltip label='Unique views'>
                            <AnalyticsMetricFilter
                                onClick={() => filterOnclick("portal")}
                                active={currentFilter === "portal"}
                                inactive={currentFilter !== "" && currentFilter !== "portal"}
                                label="Portal Views"
                                icon={<Eye />}
                                count={portal}
                                style={{ cursor: "pointer" }}
                            />
                        </Tooltip>
                        <Tooltip label='Unique downloads'>
                            <AnalyticsMetricFilter
                                onClick={() => filterOnclick("pdf")}
                                active={currentFilter === "pdf"}
                                inactive={currentFilter !== "" && currentFilter !== "pdf"}
                                label="PDF Downloads"
                                icon={<PdfIcon noHover />}
                                count={pdf}
                                style={{ cursor: "pointer" }}
                            />
                        </Tooltip>
                        <Tooltip label='Unique downloads'>
                            <AnalyticsMetricFilter
                                onClick={() => filterOnclick("model")}
                                active={currentFilter === "model"}
                                inactive={currentFilter !== "" && currentFilter !== "model"}
                                label="Model Downloads"
                                icon={<XlsIcon noHover />}
                                count={model}
                                style={{ cursor: "pointer" }}
                            />
                        </Tooltip>
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="m">
                        <PageContentToggle labels={[
                            { label: "Research", active: currentView === "research", onClick: () => changeTab("research") /*setCurrentView("research") */ },
                            { label: "Watching", active: currentView === "watching", onClick: () => changeTab("watching") /*setCurrentView("watching")*/ },
                        ]} />
                        <Divider vertical height={24} />
                        <SearchObjectFilter
                            size="small"
                            width="l"
                            placeholder={currentView === "research" ? `Search research ${loading ? "" : "(" + published.length + ")"}` : `Search research ${loading ? "" : "(" + usersList.length + ")"}`}
                            isClearable
                            dataSet={currentView === "research" ? filteredResearchList : filteredWatchingList}
                            setFilteredData={currentView === "research" ? setFilteredResearchData : setFilteredWatchingData}
                            path={currentView === "research" ? ["author", "title"] : ["firstName", "lastName", "email"]}
                            subProperty={currentView === "research" ? null : "contact"}
                            hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                        />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={currentView === "research" ? researchColWidths : watchingColWidths}
                        headers={currentView === "research" ? researchHeaders : watchingHeaders}
                        dataMap={currentView === "research" ? researchDataMap(researchDataDisplaying) : usersDataMap(watchingDataDisplaying)}
                        noResults={{ message: "No results found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(AnalyticsTickerPage);