import React, { useState } from "react";
import Page from "../Shared/Page/Page";
import DraftsLibraryPageContentBanner from "./DraftsLibraryPageContentBanner";
import RequestsLibraryPageBody from "./RequestsLibraryPageBody";

const RequestsLibrary = () => {
	const [filters, setFilters] = useState({ ticker: null, from: null, to: null });

	return (
		<Page fixedBanner id="drafts-library-page">
			<DraftsLibraryPageContentBanner filters={filters} setFilters={setFilters}/>
			<RequestsLibraryPageBody filters={filters} />
		</Page>
	);
}

export default RequestsLibrary;
