import React from 'react';
import "./ProfileImage.scss";

const ProfileImage = (props) => {
	const { src, altTag, focused, noHover, user, initials } = props;
	const getInitials = () => {
		const arr = user && user.name.split(" ") || []
		let initials = arr.map(word => word.charAt(0))
		return initials.join("")
	}
	return (
		<div className={`profile-image-container${focused ? " profile-image-focused" : ""}${!user || !user.photo  ? " profile-initials" : ""}${noHover ? " profile-image-no-hover" : ""}`}>
			{user && user.photo ? <img src={src} alt={altTag ? altTag : "profile-image"} /> : initials ? initials : <div className="profile-image-text">{getInitials()}</div>}
		</div>
	)
}

export default ProfileImage;